import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Cache from '~source/core/services/cache';
import { Page, TakeOverMessage } from '~source/view/components';
import $ from './SessionEnd.scss';

const SessionEnd: React.FunctionComponent<RouteComponentProps> = ({ history }) => {
    const { t } = useTranslation();

    const eventDetail = Cache.getEventDetail();

    Cache.resetOrderButKeepCustomerData();

    const goBack = () => {
        const link = `/ticket/${eventDetail && eventDetail.id}`;

        if (!eventDetail || link === window.location.pathname) {
            window.location.reload();
        }

        history.push(link);
    };

    return (
        <Page step={1} mustHideTravelDetails>
            <div className={$.wrap}>
                <TakeOverMessage
                    callToActionLabel={t('sessionend_bookthisevent')}
                    description={t('sessionend_hasendeddescription')}
                    eventName={eventDetail && eventDetail.name}
                    imageAlt={t('sessionend_sessionhasended')}
                    imageSrc="/images/sessionend.png"
                    onCallToActionClick={goBack}
                    subTitle={t('sessionend_bookingalmostcomplete')}
                    title={t('sessionend_bookingalmostcomplete')}
                />
            </div>
        </Page>
    );
};

export default withRouter(SessionEnd);
