import * as React from 'react';
import $ from './ErrorMessage.scss';

interface Props {
    text: string;
    missingFields?: string[];
}

export default function ErrorMessage(props: Props) {
    const { text, missingFields } = props;

    return (
        <section className={$.wrap}>
            <p className={$.message}>{text}</p>
            {missingFields && (
                <ul className={$.missingFields}>
                    {missingFields.map(field => <li className={$.field} key={field}>{field}</li>)}
                </ul>
            )}
        </section>
    );
}
