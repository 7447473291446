import { get, set, remove } from 'local-storage';
import moment from 'moment-timezone';
import { Config } from '../models/Config';
import { Availability } from '../models/Availability';
import { EventDetail } from '../models/EventDetail';
import { Order } from '../models/Order';
import { Ticket } from '../models/Ticket';
import { Accommodation } from '../models/Accommodation';
import { Traveler, Booker } from '../models/Traveler';

export function resetOrder() {
    remove('order');
    remove('children');
    remove('adults');
    remove('roomLayout');
    remove('outDate');
    remove('inDate');
    remove('availability');
    remove('ticket');
    remove('accommodation');
    remove('accommodations');
    remove('breakfast');
    remove('bookingcode');
    remove('rooms');
    remove('config');
    remove('booker');
}

export function resetEventDetail() {
    remove('eventDetail');
}

export function resetOrderButKeepCustomerData() {
    remove('order');
    remove('ticket');
    remove('availability');
    remove('accommodation');
    remove('accommodations');
    remove('breakfast');
    remove('bookingcode');
    remove('config');
}

export function setConfig(config: Config) {
    return set('config', config);
}

export function getConfig() {
    return get<Config>('config');
}

export function getAvailability() {
    return get<Availability>('availability');
}

export function setAvailability(availability: Availability) {
    return set('availability', availability);
}

export function getEventDetail() {
    return get<EventDetail>('eventDetail');
}

export function setEventDetail(eventDetail: EventDetail) {
    set('eventDetail', eventDetail);
}

export function getOrder() {
    return get<Order>('order');
}

export function setOrder(order: Order) {
    set('order', order);
}

export function getOccupancy() {
    const traveler = new Traveler({ type: 'adult' });
    // Default in API is 2 adults
    const adults = get<Traveler[]>('adults') || [traveler, traveler];
    const children = get<Traveler[]>('children') || [];
    const rooms = get<number>('rooms') || 1;
    const roomLayout = get<number[][]>('roomLayout');
    return { adults, children, rooms, roomLayout };
}

export function setChildren(children: Traveler[]) {
    set('children', children);
}

export function setAdults(adults: Traveler[]) {
    set('adults', adults);
}

export function setRooms(rooms: number) {
    set('rooms', rooms);
}

export function setRoomLayout(roomLayout?: number[][]) {
    if (roomLayout == null) {
        remove('roomLayout');
        return;
    }
    set('roomLayout', roomLayout);
}

export function getInOutDate() {
    const outDate = get<number>('outDate') || 0;
    const inDate = get<number>('inDate') || 0;
    return { outDate, inDate };
}

export function getInOutDateFormats() {
    const { outDate, inDate } = getInOutDate();
    const eventDetail = getEventDetail();

    if (eventDetail == null) {
        return { startDate: null, endDate: null };
    }

    const outDateBase = eventDetail.dateMinimumStart;
    const inDateBase = eventDetail.dateMinimumEnd;

    const startDate = moment(outDateBase).add(-outDate, 'days');
    const endDate = moment(inDateBase).add(inDate, 'days');

    return { startDate, endDate };
}

export function setInDate(inDate: number) {
    set('inDate', inDate);
}

export function setOutDate(outDate: number) {
    set('outDate', outDate);
}

export function getTicket() {
    return get<Ticket>('ticket');
}

export function setTicket(ticket: Ticket) {
    set('ticket', ticket);
}

export function setAccommodations(accommodations: Accommodation[]) {
    set('accommodations', accommodations);
}

export function getAccommodations() {
    return get<Accommodation[]>('accommodations');
}

export function setAccommodation(accommodation: Accommodation) {
    set('accommodation', accommodation);
}

export function getAccommodation() {
    return get<Accommodation>('accommodation');
}

export function setBreakfast(breakfast: boolean) {
    set('breakfast', breakfast);
}

export function getBreakfast() {
    return get<boolean>('breakfast');
}

export function setBookerDetails(details: Booker) {
    set('booker', details);
}

export function getBookerDetails() {
    return get<Booker>('booker') || {};
}

export function setBooking(code: string) {
    set('bookingcode', code);
}

export function getBooking() {
    const bookingcode = get<string>('bookingcode');
    return bookingcode;
}
