import * as React from 'react';
import classnames from 'classnames';
import $ from './Price.scss';

interface Props {
    price: number | string;
    green?: boolean;
}

export default function Price(props: Props) {
    const { price, green } = props;
    const priceClass = classnames([
        $.priceNumber,
        green ? $.priceNumberGreen : $.priceNumberBlue,
    ]);

    return (
        <p className={$.price}>
            + <span className={priceClass}>{price}</span> <small>p.p.</small>
        </p>
    );
}
