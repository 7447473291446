import * as React from 'react';
import moment from 'moment-timezone';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './TravelDetail.scss';
import { Button, CloseButton, ConfirmLabel } from '..';
import { EventDetail } from '~source/core/models/EventDetail';
import { Accommodation } from '~source/core/models/Accommodation';
import { Availability } from '~source/core/models/Availability';
import TravelDetailList from '../TravelDetailList';
import * as Cache from '~source/core/services/cache';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    accommodation?: Accommodation;
    onClick: () => void;
    openHotelDetail: (accommodation: Accommodation) => void;
    step: number;
    showPricing: boolean;
}

interface State {
    availability?: Availability;
}

// eslint-disable-next-line react/prefer-stateless-function
class TravelDetail extends React.Component<Props, State> {
    public constructor(props) {
        super(props);

        this.handleSetup = this.handleSetup.bind(this);
    }

    public componentDidMount() {
        // Cache.getAvailability().then(availability => {
        //     this.setState({ availability });
        // });
    }

    public handleSetup() {
        const { onClick } = this.props;
        onClick();
    }

    public render() {
        const { eventDetail, t, openHotelDetail, step, showPricing } = this.props;
        const ticket = Cache.getTicket();
        const { adults, children } = Cache.getOccupancy();
        const accommodation = Cache.getAccommodation();
        const date = moment(eventDetail.dateTime).format('DD/MM/YYYY, H:mm');

        return (
            <div className={$.wrap}>
                <button type="button" className={$.background} onClick={this.handleSetup} />
                <div className={$.roomLayout}>
                    <h1 className={$.header}>
                        {eventDetail.title}
                    </h1>
                    <div className={$.topRow}>
                        <div>
                            <ul className={$.options}>
                                <li>
                                    <div>{t('traveldetail_match')}</div>
                                    <div>
                                        <span>{date}</span>
                                        <ConfirmLabel isConfirmed={eventDetail.dateConfirmed} withTooltip />
                                    </div>
                                </li>
                                <li>
                                    <div>{t('traveldetail_venue')}</div>
                                    <div>{eventDetail.venue.name}</div>
                                </li>
                                {ticket && (
                                    <li>
                                        <div>{t('traveldetail_seats')}</div>
                                        <div>{ticket.name}</div>
                                    </li>
                                )}
                                <li>
                                    <div>{t('traveldetail_persons')}</div>
                                    <div>
                                        {t('traveldetail_adults', { adults: adults.length })}<br />
                                        {children.length > 0 && (
                                            children.length === 1 ? `1 ${t('child')}` : `${children.length} ${t('children')}`
                                        )}
                                    </div>
                                </li>
                                {accommodation && (
                                    <li>
                                        <div>{t('traveldetail_hotel')}</div>
                                        <div>
                                            {accommodation.name}
                                            <br />
                                            <button type="button" className={$.detailsButton} onClick={() => openHotelDetail(accommodation)}>{t('hotel_details_text_only')}</button>
                                        </div>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <div>
                            <TravelDetailList eventDetail={eventDetail} step={step} showPricing={showPricing} />
                        </div>
                    </div>
                    <div className={$.closeButtonWrap}>
                        <Button onClick={this.handleSetup} text={t('traveldetail_close')} />
                    </div>
                    <CloseButton onClick={this.handleSetup} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(TravelDetail);
