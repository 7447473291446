import * as React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Help } from '..';
import $ from './StepIndicator.scss';

interface Props extends WithTranslation {
    logo: {
        url: string;
        title: string;
    };
    phoneNumber: string;
    whatsappNumber: string;
    active: number;
    faq?: any;
    help?: any;
    whyEquipo?: any;
    eventId?: string;
}

const StepIndicator = (props: Props) => {
    const { logo, phoneNumber, whatsappNumber, active, faq, help, whyEquipo, t, eventId } = props;
    const [faqOpen, setFaqOpen] = React.useState(false);
    const steps = [
        {
            step: 1,
            name: t('stepindicator_createtrip'),
            to: `ticket/${eventId}`,
        },
        {
            step: 2,
            name: t('stepindicator_details'),
            to: 'details',
        },
        {
            step: 3,
            name: t('stepindicator_payment'),
            to: 'payment',
        },
    ];

    return (
        <div className={$.container}>
            <header className={$.base}>
                <img src={logo.url} alt={logo.title} title={logo.title} className={$.logo} />
                <div className={$.columnLeft}>
                    <ul className={$.stepsList}>
                        {steps.map(step => (
                            <React.Fragment key={step.step}>
                                <li
                                    className={classnames([
                                        $.stepItem,
                                        active === step.step && $.stepItemActive,
                                    ])}
                                >
                                    {active > step.step && (
                                        <Link to={step.to}>
                                            <span className={$.step}>
                                                <img
                                                    src="images/icon-checkmark-green.svg"
                                                    alt=""
                                                    className={$.checkmark}
                                                />
                                            </span>
                                            {step.name}
                                        </Link>
                                    )}
                                    {(!eventId || active <= step.step) && (
                                        <span>
                                            <span className={$.step}>{step.step}.</span> {step.name}
                                        </span>
                                    )}
                                </li>
                                {step.step < 3 && (
                                    <li className={$.stepItem}>
                                        <img src="/images/icon-arrow-right.svg" alt=">" />
                                    </li>
                                )}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
                <div className={$.columnRight}>
                    <section className={$.helpDesktop}>
                        <p className={$.text}>{t('stepindicator_questionsorhelp')}</p>
                        <p className={$.text}>
                            {t('general_call')} <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>{' '}
                            {t('general_or')}{' '}
                            <a href={`https://wa.me/${whatsappNumber}`} className={$.whatsapp}>
                                {t('supportblock_whatsapp')}
                            </a>
                        </p>
                    </section>
                    <button
                        className={$.helpTablet}
                        type="button"
                        onClick={() => {
                            setFaqOpen(true);
                        }}
                    >
                        {t('stepindicator_questionsorhelp')}
                    </button>
                    <button
                        className={$.helpMobile}
                        type="button"
                        onClick={() => {
                            setFaqOpen(true);
                        }}
                    >
                        {t('stepindicator_help')}
                    </button>
                    {faqOpen && (
                        <Help
                            {...help}
                            faq={faq}
                            phoneNumber={phoneNumber}
                            whyEquipo={whyEquipo}
                            closeModal={() => setFaqOpen(false)}
                        />
                    )}
                </div>
            </header>
        </div>
    );
};

export default withTranslation()(StepIndicator);
