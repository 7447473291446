import * as React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Page, PreviousStepBanner, Button } from '~source/view/components';
import $ from './Flight.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {}

const Flight = (props: Props) => {
    const { t } = props;
    return (
        <Page step={1}>
            <div className={$.container}>
                <div>
                    <PreviousStepBanner />
                </div>
                <div>
                    <PreviousStepBanner />
                </div>
                <div />
                <div />
                <div className={$.button}>
                    <Link to="/details">
                        <Button text={t('flight_todetails')} />
                    </Link>
                </div>
            </div>
        </Page>
    );
};

export default withTranslation()(Flight);
