/* eslint-disable @typescript-eslint/camelcase */
import { Body, fetchUrl } from './fetchUrl';
import { Order, OrderEntity } from '~source/core/models/Order';
import { Availability, AvailabilityEntity } from '~source/core/models/Availability';
import { BookResult, BookResultEntity } from '~source/core/models/BookResult';
import * as Cache from '~source/core/services/cache';

export async function startOrder(
    eventId: string,
    occupancy: Body[],
    dateStart: string,
    dateEnd: string
) {
    return fetchUrl<OrderEntity>({
        endpoint: '/v1/orders',
        method: 'POST',
        body: {
            event_id: eventId,
            date_start: dateStart,
            date_end: dateEnd,
            occupancy,
        },
        getSecret: true,
    }).then(orderEntity => new Order(orderEntity));
}

export async function updateOrder(
    orderId: string,
    occupancy: Body[],
    dateStart: string,
    dateEnd: string
) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}/preferences`,
        method: 'PUT',
        body: {
            date_start: dateStart,
            date_end: dateEnd,
            occupancy,
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function setOrderTickets(orderId: string, ticketId: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}`,
        method: 'PUT',
        body: {
            tickets: [
                {
                    id: ticketId,
                },
            ],
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function setOrderCoupon(orderId: string, coupon: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}`,
        method: 'PUT',
        body: {
            coupons: [
                {
                    code: coupon,
                },
            ],
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function setOrderAccommodation(orderId: string, accommodation: Body) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}`,
        method: 'PUT',
        body: {
            accommodation: [accommodation],
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function setOrderTravelers(orderId: string, customer: Body, travellers: Body[]) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}`,
        method: 'PUT',
        body: {
            customer,
            travellers,
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function getAvailability(orderId: string, forceFresh = 0) {
    return fetchUrl<AvailabilityEntity>({
        endpoint: `/v1/orders/${orderId}/availability`,
        method: 'POST',
        body: {
            forceFresh,
        },
        secret: Cache.getOrder().secret,
    }).then(availabilityEntity => new Availability(availabilityEntity));
}

export async function validateOrder(orderId: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/orders/${orderId}/validate`,
        method: 'POST',
        body: {
            forceFresh: 0,
        },
        secret: Cache.getOrder().secret,
    }).then(orderEntity => new Order(orderEntity));
}

export async function book(orderId: string) {
    return fetchUrl<BookResultEntity>({
        endpoint: `/v1/orders/${orderId}/book`,
        method: 'POST',
        body: {},
        secret: Cache.getOrder().secret,
    }).then(bookResult => new BookResult(bookResult));
}

export async function payment(orderId: string, gateway: string, callback: string) {
    return fetchUrl<OrderEntity>({
        endpoint: `/v1/payments/pay/${orderId}?gateway=${gateway}&callback=${callback}`,
        method: 'GET',
        body: {},
    }).then(orderEntity => new Order(orderEntity));
}
