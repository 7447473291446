import * as React from 'react';
import classnames from 'classnames';
import { RouteProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { shallowEqualArrays, shallowEqualObjects } from 'shallow-equal';
import moment from 'moment-timezone';
import {
    Button,
    ConfirmLabel,
    ErrorMessage,
    FormCheckbox,
    FormDateSelect,
    FormSelect,
    NextStepBanner,
    Page,
    Popover,
    RoomLayout,
    SeatPicker,
    Spinner,
    TeaserBanner,
    EventImageTitle,
} from '~source/view/components';
import { Traveler } from '~source/core/models/Traveler';
import { createLayoutFromCompany } from '~source/view/components/RoomLayout/RoomLayout';
import $ from './Ticket.scss';
import * as events from '~source/core/services/events';
import { EventDetail } from '~source/core/models/EventDetail';
import { Availability } from '~source/core/models/Availability';
import { Body } from '~source/core/services/fetchUrl';
import * as Cache from '~source/core/services/cache';
import * as Order from '~source/core/services/order';
import { fetchConfig } from '~source/core/services/config';
import { checkIfChild, seperateDate, getReversedDate, getElementCoordinates, getValuesFromObject, addValueToArray, removeValueFromArray, addMultipleValuesToArray } from '~source/utils/utils';
import MobileContext from '~source/view/context/MobileContext';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {}

interface State {
    adults: Traveler[];
    availability?: Availability;
    children: Traveler[];
    errors: string[];
    outDate: number;
    inDate: number;
    eventDetail?: EventDetail | null;
    eventId?: string;
    loading: boolean;
    isSubmitting: boolean;
    orderUpToDate: boolean;
    rooms: number;
    roomLayout: number[][];
    selectRooms: boolean;
    ticket?: Ticket;
    areBirthdaysValid: boolean;
}

function checkIfChildrenAreValid(children, eventDate) {
    if (children.length === 0) return true;
    if (children.some(child => !child.birthday)) return false;

    return children.every(child => {
        const [day, month, year] = child.birthday.split('-');
        return checkIfChild(day, month, year, eventDate);
    });
}

function checkIfNestedArrIsEqual(arr, cacheArr, nestedObj = false) {
    if (arr === null && cacheArr === null) return true;
    if (arr !== null && cacheArr === null) return false;
    if (arr.length !== cacheArr.length) return false;

    return !arr
        .map((nested, i) => {
            if (nestedObj) {
                return shallowEqualObjects(nested, cacheArr[i]);
            }
            return shallowEqualArrays(nested, cacheArr[i]);
        })
        .includes(false);
}

function createChildren(count, startIndex) {
    const children: Traveler[] = [];
    for (let i = startIndex; i < count + startIndex; i += 1) {
        children.push(new Traveler({ type: 'traveller' }));
    }
    return children;
}

function createAdults(count) {
    const adults: Traveler[] = [];
    for (let i = 0; i < count; i += 1) {
        adults.push(new Traveler({ type: 'traveller' }));
    }
    return adults;
}

class Ticket extends React.Component<Props & RouteProps, State> {
    private static createDateArray(start: Date, end: Date) {
        const dates: string[] = [];
        let date = start;

        while (date <= end) {
            const format = moment(date).format(' dddd D MMMM YYYY');
            dates.push(format);
            date = new Date(date);
            date.setDate(date.getDate() + 1);
        }

        return dates;
    }

    private static handleChange(event: React.ChangeEvent) {
        if (event.target.id === 'flight') {
            // eslint-disable-next-line no-alert
            alert('Functionality not available');
        }
    }

    public maxOccupancy = 8;
    // eslint-disable-next-line lines-between-class-members
    public confirmButtonRef = React.createRef<HTMLDivElement>();

    public constructor(props: Props & RouteProps) {
        super(props);

        const eventDetail = Cache.getEventDetail();
        const { adults, children, rooms, roomLayout: cacheRoomLayout } = Cache.getOccupancy();
        const { outDate, inDate } = Cache.getInOutDate();

        let { eventId } = props.match.params;
        if (!eventId && eventDetail) {
            eventId = eventDetail.id;
        }

        // if the user already started a session with a different event
        // or the user already booked this event, reset the cache
        if ((eventId && eventDetail && eventId !== eventDetail.id) || Cache.getBooking()) {
            Cache.resetOrder();
            Cache.resetEventDetail();
        }

        const areBirthdaysValid = checkIfChildrenAreValid(children, eventDetail ? eventDetail.dateTime : null);
        const roomLayout = !cacheRoomLayout
            ? createLayoutFromCompany({
                  adults: adults.length,
                  childrenCount: children.length,
                  rooms,
              })
            : cacheRoomLayout;

        this.state = {
            adults,
            children,
            errors: [],
            eventId,
            // We don't use the constant eventDetail here since it produces a flash of old event data when switching event
            eventDetail: Cache.getEventDetail(),
            outDate,
            inDate,
            loading: false,
            isSubmitting: false,
            orderUpToDate: true,
            rooms,
            roomLayout,
            selectRooms: false,
            areBirthdaysValid,
        };
        this.handleCompany = this.handleCompany.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.handleRooms = this.handleRooms.bind(this);
        this.handleSetup = this.handleSetup.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleInjourney = this.handleInjourney.bind(this);
        this.handleOutjourney = this.handleOutjourney.bind(this);
        this.handleTickets = this.handleTickets.bind(this);
    }

    public async componentDidMount() {
        this.setState({ loading: true });

        await this.fetchConfig().then(() => {
            const { locale } = Cache.getConfig();
            moment.locale(locale);
        });
        await this.fetchEvent();
        await this.refreshOrderWithPreferencesUpdate(false);

        this.setState({ loading: false });
    }

    // eslint-disable-next-line react/sort-comp
    private confirmTicket = async e => {
        e.preventDefault();
        const { history, t: translate } = this.props;
        const { orderUpToDate, areBirthdaysValid } = this.state;

        const order = Cache.getOrder();
        const cacheTicket = Cache.getTicket();

        if (!order || !cacheTicket) return;

        if (orderUpToDate) {
            await Order.setOrderTickets(order.id, cacheTicket.id)
                .then(() => {
                    this.setState({
                        errors: [],
                    });
                    history.push('/hotel');
                })
                .catch(err => {
                    const { status, data } = err.response;

                    if (status === 422) {
                        const { errors } = this.state;
                        const backendErrors = getValuesFromObject(data.errors);

                        this.setState({ errors: addMultipleValuesToArray(backendErrors, errors) });
                        window.scrollTo(0, 0);
                    }
                });

            return;
        }
        const { errors } = this.state;

        if (areBirthdaysValid) {
            this.setState({
                errors: addValueToArray(errors, translate('ticket_preferencesnotconfirmed')),
            });
        } else {
            this.setState({
                errors: addValueToArray(errors, translate('ticket_childrenagenotvalid')),
            });
        }

        const isMobile = this.context;
        const confirmButton = this.confirmButtonRef && this.confirmButtonRef.current;
        const confirmButtonPositon = getElementCoordinates(confirmButton);
        // Scroll to preferences
        window.scrollTo(0, isMobile ? confirmButtonPositon.y - 300 : 0);
    };

    private getDate(inDate: boolean) {
        const { eventDetail } = this.state;

        if (eventDetail == null) {
            return '';
        }

        const { inDate: inAdd, outDate: outAdd } = Cache.getInOutDate();

        if (!inDate) {
            const day = moment(eventDetail.dateMinimumStart).add(-outAdd, 'days');

            return day.format('YYYY-MM-DD');
        }

        const day = moment(eventDetail.dateMinimumEnd).add(inAdd, 'days');
        return day.format('YYYY-MM-DD');
    }

    // eslint-disable-next-line class-methods-use-this
    private setTicket(id: string) {
        const eventDetail = Cache.getEventDetail();
        const availability = Cache.getAvailability();

        const ticket = availability.tickets.find(x => x.id === id);

        if (ticket != null) {
            const eventDetailTicket = eventDetail.tickets.find(
                x => x.categoryId === ticket.categoryId
            );
            if (eventDetailTicket != null) {
                ticket.seatplanImage = eventDetailTicket.seatplanImage;
            }
            Cache.setTicket(ticket);
        }
    }

    private fetchConfig = async () => {
        if (!Cache.getConfig()) {
            const config = await fetchConfig();
            Cache.setConfig(config);
        }
    };

    private refreshOrderWithPreferencesUpdate = async (updatePreferences: boolean) => {
        this.setState({ isSubmitting: true });
        await this.createOrderWithPreferencesUpdate(updatePreferences);
        await this.getAvailability();
        this.setState({ isSubmitting: false, orderUpToDate: true });
    };

    private createOrderWithPreferencesUpdate = async (updatePreferences: boolean) => {
        const { eventId } = this.state;
        const { rooms, adults, children, roomLayout } = Cache.getOccupancy();

        if (!eventId) {
            return;
        }

        const order: Body[] = [];

        if (rooms === 1) {
            const childrenArray: string[] = children.map(child =>
                getReversedDate(child.birthday)
            );
            order.push({ adults: adults.length, children: childrenArray });
        } else {
            for (let i = 0; i < roomLayout.length; i += 1) {
                const childrenArray: string[] = [];
                for (let j = 0; j < roomLayout[i][1]; j += 1) {
                    childrenArray.push(getReversedDate(children[i].birthday));
                }
                order.push({ adults: roomLayout[i][0], children: childrenArray });
            }
        }

        let oldOrder = Cache.getOrder();
        if (oldOrder !== null && updatePreferences) {
            await Order.updateOrder(
                oldOrder.id,
                order,
                this.getDate(false),
                this.getDate(true)
            ).then(result => {
                Cache.setOrder(result);
            }).catch(err => {
                const { status, data } = err.response;

                if (status === 422) {
                    // errors is an object with keys for every traveller that has invalid information. Each prop has an array with errors
                    // that applies to that traveller. Create an error for every one of those errors
                    const { errors } = this.state;
                    const backendErrors = getValuesFromObject(data.errors);

                    this.setState({ errors: addMultipleValuesToArray(backendErrors, errors) });
                } else if (status === 404) {
                    Cache.resetOrder();
                }
            });
        }
        oldOrder = Cache.getOrder();
        if (oldOrder === null) {
            const baseOrder = await Order.startOrder(
                eventId,
                order,
                this.getDate(false),
                this.getDate(true)
            );
            Cache.setOrder(baseOrder);
        }
    };

    private getAccommodations = async () => {
        const { eventId } = this.state;
        if (!eventId) return;

        const accommodations = await events.fetchEventAccomodations(eventId);

        Cache.setAccommodations(accommodations);
    };

    private getAvailability = async () => {
        const { errors } = this.state;
        const { t: translate } = this.props;
        const order = Cache.getOrder();
        this.setState({ errors: removeValueFromArray(translate('ticket_error_no_accommodations'), errors) });

        if (!order) return;

        try {
            let forceFresh = 0;
            // force refresh of the availability call when the availability is expired
            if (Cache.getAvailability() && moment().unix() >= Cache.getAvailability().expiry) {
                forceFresh = 1;
            }
            const availability = await Order.getAvailability(order.id, forceFresh);
            Cache.setAvailability(availability);

            const eventDetail = Cache.getEventDetail();
            const cacheTicket = Cache.getTicket();

            if (cacheTicket !== null && eventDetail !== null) {
                this.setTicket(cacheTicket.id);
            } else {
                this.setTicket(availability.baseAccomodationPackage.ticketId);
            }

            this.setState({ availability });

            this.getAccommodations();
        } catch (e) {
            this.setState({ errors: addValueToArray(errors, translate('ticket_error_no_accommodations')) });
        }
    };

    private fetchEvent = async () => {
        let { eventId, eventDetail } = this.state;

        if (!eventId && eventDetail) eventId = eventDetail.id;
        if (!eventId) return;

        try {
            // event key that can be used '0005e600-1098-481b-ab4e-33b5f5c15e0c'
            eventDetail = await events.fetchEventDetail(eventId);

            this.setState({ eventDetail });
            Cache.setEventDetail(eventDetail);
            // eslint-disable-next-line no-empty
        } catch (err) {
            const { history } = this.props;
            // TODO: Route to different pages for different errors, e.g.: 500, 401 etc.
            history.push('/404');
        } finally {
            // this.setState({ pending: false });
        }
    };

    public checkIfOrderIsUpToDate() {
        const { children, rooms, adults, roomLayout, inDate, outDate } = this.state;
        const {
            children: cacheChildren,
            rooms: cacheRooms,
            roomLayout: cacheRoomLayout,
            adults: cacheAdults,
        } = Cache.getOccupancy();
        const { outDate: cacheOutDate, inDate: cacheInDate } = Cache.getInOutDate();
        const areChildrenEqual = checkIfNestedArrIsEqual(children, cacheChildren, true);
        const isRoomLayoutEqual = checkIfNestedArrIsEqual(roomLayout, cacheRoomLayout);

        return (
            rooms === cacheRooms &&
            adults.length === cacheAdults.length &&
            outDate === cacheOutDate &&
            inDate === cacheInDate &&
            areChildrenEqual &&
            isRoomLayoutEqual
        );
    }

    // eslint-disable-next-line class-methods-use-this
    public changeChildrenAges = (index, day, month, year) => {
        const { children, eventDetail } = this.state;
        if (!eventDetail) return;

        const newChildren = children[index]
            ? [
                  ...children.slice(0, index),
                  new Traveler({ index, birthday: `${day}-${month}-${year}` }),
                  ...children.slice(index + 1, children.length),
              ]
            : [...children, new Traveler({ index, birthday: `${day}-${month}-${year}` })];
        const areBirthdaysValid = checkIfChildrenAreValid(newChildren, eventDetail.dateTime);

        this.setState(
            {
                children: newChildren,
                areBirthdaysValid,
                errors: [],
            },
            () => {
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate });
            }
        );
    };

    // eslint-disable-next-line class-methods-use-this
    private childrenAges() {
        const { children, errors, eventDetail } = this.state;
        if (!eventDetail) return null;

        const { t: translate } = this.props;
        const result: JSX.Element[] = [];

        for (let i = 0; i < children.length; i += 1) {
            const { day, month, year } = seperateDate(children[i].birthday);
            const isInvalid = errors.some(err => err === translate('ticket_childrenagenotvalid'));

            result.push(
                <div className={$.childrenAges} key={`age${i}`}>
                    <FormDateSelect
                        onChange={this.changeChildrenAges}
                        day={day}
                        eventDate={eventDetail.dateTime}
                        month={month}
                        year={year}
                        id={i}
                        isChild
                        required
                        showErrorMessage={isInvalid}
                    />
                </div>
            );
        }
        return result;
    }

    public async handleInjourney(value: string) {
        this.handleInOutjourney(value, undefined);
    }

    public async handleOutjourney(value: string) {
        this.handleInOutjourney(undefined, value);
    }

    public async handleInOutjourney(inJourney?: string, outJourney?: string) {
        const { eventDetail } = this.state;

        if (!eventDetail) {
            return;
        }

        if (inJourney) {
            this.setState({ inDate: +inJourney }, () => {
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate, errors: [] });
            });
        }

        if (outJourney) {
            const dates: string[] = Ticket.createDateArray(
                eventDetail.dateBookableStart,
                eventDetail.dateMinimumStart
            );

            this.setState({ outDate: dates.length - 1 - +outJourney }, () => {
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate, errors: [] });
            });
        }
    }

    public async handleCompany(value: string) {
        const { children } = this.state;
        const adults = createAdults(Number(value) + 1);
        const rooms = 1;
        const roomLayout = createLayoutFromCompany({
            adults: adults.length,
            childrenCount: children.length,
            rooms,
        });

        await this.setState(
            {
                adults,
                rooms,
                roomLayout,
                errors: [],
                selectRooms: true
            },
            () => {
                Cache.setRoomLayout(roomLayout);
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate });
            }
        );
    }

    public async handleChildren(value: string) {
        const { children, eventDetail } = this.state;
        if (!eventDetail) return;
        let childrenClone = [...children];
        const childrenCount = children.length;
        const childrenCountNew = Number(value);
        const diffCount = childrenCountNew - childrenCount;

        if (diffCount < 0) {
            childrenClone.splice(childrenCountNew, Math.abs(diffCount));
        }

        if (diffCount > 0) {
            childrenClone = [...childrenClone, ...createChildren(diffCount, childrenCount)];
        }

        const areBirthdaysValid = checkIfChildrenAreValid(childrenClone, eventDetail.dateTime);

        this.setState(
            {
                areBirthdaysValid,
                children: childrenClone,
                errors: [],
                selectRooms: true,
            },
            () => {
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate });
            }
        );
    }

    public handleRooms(value: string) {
        const rooms = parseInt(value, 10) + 1;

        this.setState({ rooms, selectRooms: true }, () => {
            const orderUpToDate = this.checkIfOrderIsUpToDate();
            this.setState({
                orderUpToDate,
                errors: [],
            });
        });
    }

    public async handleConfirm() {
        const { children, rooms, adults, roomLayout, inDate, outDate } = this.state;

        Cache.setChildren(children);
        Cache.setRooms(rooms);
        Cache.setRoomLayout(roomLayout);
        Cache.setAdults(adults);
        Cache.setInDate(inDate);
        Cache.setOutDate(outDate);

        this.refreshOrderWithPreferencesUpdate(true).then(() =>
            this.setState({ orderUpToDate: true })
        );
    }

    public async handleSetup(rooms: number, adults: number, layout: number[][]) {
        await this.setState(
            { rooms, adults: createAdults(adults), selectRooms: false, roomLayout: layout },
            () => {
                const orderUpToDate = this.checkIfOrderIsUpToDate();
                this.setState({ orderUpToDate });
            }
        );
    }

    // eslint-disable-next-line class-methods-use-this
    public async handleTickets(ticketId: string) {
        const oldOrder = Cache.getOrder();
        const eventDetail = Cache.getEventDetail();
        const availability = Cache.getAvailability();
        if (oldOrder !== null && eventDetail !== null && availability !== null) {
            this.setTicket(ticketId);
            // Update the view
            this.setState({});
        }
    }

    public render() {
        const { t: translate } = this.props;
        const {
            adults,
            children,
            availability,
            errors,
            eventDetail,
            inDate,
            outDate,
            loading,
            isSubmitting,
            orderUpToDate,
            rooms,
            selectRooms,
            areBirthdaysValid,
        } = this.state;

        if (!eventDetail) {
            if (loading) {
                return (
                    <Page step={1}>
                        <section className={$.spinnerWrap}>
                            <Spinner color="#222222" size={25} />
                        </section>
                    </Page>
                );
            }
            return <Page step={1}>{translate('ticket_eventnotfound')}</Page>;
        }
        let maxChildren = this.maxOccupancy - adults.length;
        let maxAdults = this.maxOccupancy - children.length;
        const maxRooms = adults.length;

        if (adults.length > maxAdults) {
            maxAdults = adults.length;
        }

        if (children.length > maxChildren) {
            maxChildren = children.length;
        }

        const dates: string[] = Ticket.createDateArray(
            eventDetail.dateBookableStart,
            eventDetail.dateMinimumStart
        );

        const endDates: string[] = Ticket.createDateArray(
            eventDetail.dateMinimumEnd,
            eventDetail.dateBookableEnd
        );

        const adultOptions: string[] = [translate('traveldetail_adults_1')];
        for (let i = 2; i <= maxAdults; i += 1) {
            adultOptions.push(translate('traveldetail_adults', { adults: i }));
        }

        const childrenOptions: string[] = [translate('traveldetail_children', { children: 0 })];
        for (let i = 1; i <= maxChildren; i += 1) {
            if (i === 1) {
                childrenOptions.push(translate('traveldetail_children_1'));
            }
            childrenOptions.push(translate('traveldetail_children', { children: i + 1 }));
        }

        const roomOptions: string[] = [translate('traveldetail_rooms_1')];
        for (let i = 2; i <= maxRooms; i += 1) {
            roomOptions.push(translate('traveldetail_rooms', { rooms: i }));
        }

        const date = moment(eventDetail.dateTime).format('D MMMM YYYY, H:mm');
        const description = translate(eventDetail.dateConfirmed ? 'event_confirmed_text' : 'event_not_confirmed_text');
        const setTickets = (availability && availability.tickets) || [];
        const isMobile = this.context;

        return (
            <React.Fragment>
                <Page
                    step={1}
                    eventDetail={eventDetail}
                    availability={availability}
                    showPricing={orderUpToDate}
                    showEventImageAndTitle
                >
                    <div className={$.row}>
                        {/* <EventImageTitle title={eventDetail.title} mobileOnly /> */}
                        <h1 className={$.eventName}>{eventDetail.title}</h1>
                        <div className={$.eventInfo}>
                            {date}
                            <ConfirmLabel
                                isConfirmed={eventDetail.dateConfirmed}
                                className={$.confirmedLabel}
                            />
                            <Popover text={description} arrow placement="bottom">
                                <span className={$.infoImage} />
                            </Popover>
                            <div className={$.location}>
                                <span className={$.venue}>{eventDetail.venue.name}</span>
                                <span className={$.bull}>&bull;</span>
                                <span className={$.league}>{eventDetail.serie.name}</span>
                            </div>
                        </div>
                    </div>
                    <div className={$.row}>
                        <FormCheckbox
                            title={translate('ticket_hoteltickets')}
                            subTitle={translate('ticket_pricefrom', {
                                price: eventDetail.baseAccomodation.price,
                            })}
                            name="flighttickets"
                            id="tickets"
                            checked
                            onChange={Ticket.handleChange}
                        />
                        {/* Disabled for now. API for flight tickets isn't ready yet */}
                        {/* <FormCheckbox
                            title={t('ticket_flighthoteltickets')}
                            subTitle={t('ticket_alsoavailable')}
                            name="flighttickets"
                            id="flight"
                            checked={false}
                            onChange={Ticket.handleChange}
                        /> */}
                    </div>
                    <div className={$.row}>
                        <div className={$.options}>
                            <h2 className={$.header}>
                                {translate('ticket_outjourney')}
                                <Popover
                                    text={translate('ticket_flight_popover')}
                                    arrow
                                    placement={isMobile ? 'bottom' : 'right'}
                                >
                                    <img
                                        className={$.popoverImg}
                                        src="/images/icon-info.svg"
                                        alt="info"
                                    />
                                </Popover>
                            </h2>
                            <FormSelect
                                options={dates}
                                selected={dates.length - 1 - outDate}
                                onChange={this.handleOutjourney}
                                wide
                            />
                        </div>
                        <div className={$.options}>
                            <h2 className={$.header}>
                                {translate('ticket_returnjourney')}
                                <Popover
                                    text={translate('ticket_return_flight_popover')}
                                    arrow
                                    placement={isMobile ? 'bottom' : 'right'}
                                >
                                    <img
                                        className={$.popoverImg}
                                        src="/images/icon-info.svg"
                                        alt="info"
                                    />
                                </Popover>
                            </h2>
                            <FormSelect
                                options={endDates}
                                selected={inDate}
                                onChange={this.handleInjourney}
                                wide
                            />
                        </div>
                    </div>
                    <div className={$.row}>
                        <div className={classnames([$.options, $.optionsMobile])}>
                            <h2 className={$.header}>{translate('ticket_company')}</h2>
                            <FormSelect
                                options={adultOptions}
                                onChange={this.handleCompany}
                                selected={adults.length - 1}
                            />
                            <FormSelect
                                options={childrenOptions}
                                onChange={this.handleChildren}
                                selected={children.length}
                            />
                            <FormSelect
                                options={roomOptions}
                                onChange={this.handleRooms}
                                selected={rooms - 1}
                            />
                        </div>
                        <div className={classnames([$.options, $.optionsTablet])}>
                            <h2 className={$.header}>{translate('ticket_travelcompany')}</h2>
                            <FormSelect
                                options={adultOptions}
                                onChange={this.handleCompany}
                                selected={adults.length - 1}
                            />
                        </div>
                        <div className={classnames([$.options, $.optionsTablet])}>
                            <h2 className={$.header}>{translate('ticket_children')}</h2>
                            <FormSelect
                                options={childrenOptions}
                                onChange={this.handleChildren}
                                selected={children.length}
                            />
                        </div>
                        <div className={classnames([$.options, $.optionsTablet])}>
                            <h2 className={$.header}>{translate('ticket_rooms')}</h2>
                            <FormSelect
                                options={roomOptions}
                                onChange={this.handleRooms}
                                selected={rooms - 1}
                            />
                        </div>
                    </div>
                    {children.length > 0 && (
                        <div className={$.row}>
                            <h2 className={$.header}>{translate('ticket_childrenage')}</h2>
                            {this.childrenAges()}
                        </div>
                    )}
                    <div className={$.row}>
                        <div className={$.confirmErrorWrap} ref={this.confirmButtonRef}>
                            <Button
                                text={
                                    orderUpToDate
                                        ? translate('ticket_preferencesconfirmed')
                                        : translate('ticket_confirmpreferences')
                                }
                                onClick={this.handleConfirm}
                                disabled={!areBirthdaysValid || orderUpToDate}
                                icon={
                                    orderUpToDate && !isSubmitting
                                        ? '/images/icon-checkmark-grey.svg'
                                        : undefined
                                }
                                isLoading={isSubmitting}
                                className={$.confirmButton}
                                leftAligned
                            />
                            {errors.length > 0 && errors.map(error => (
                                <div className={$.errorWrap}>
                                    <ErrorMessage
                                        text={error}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Disable for now. API does not provide the data for the teaser yet */}
                    {/* <div className={$.row}>
                        <TeaserBanner title={t('ticket_teasermatch')} />
                    </div> */}
                    <hr className={$.divider} />
                    <NextStepBanner text={translate('ticket_seats')} />
                    <div className={$.row}>
                        <SeatPicker
                            venue={eventDetail.venue}
                            tickets={setTickets}
                            occupancy={adults}
                            onSelection={this.handleTickets}
                        />
                    </div>
                    <div className={$.button}>
                        <Button
                            text={translate('ticket_nextstep')}
                            icon="/images/icon-arrow-down.svg"
                            onClick={this.confirmTicket}
                            disabled={!areBirthdaysValid || !orderUpToDate || errors.length > 0}
                        />
                    </div>
                    <div className={$.banner}>
                        <NextStepBanner text={translate('ticket_pickhotel')} />
                    </div>
                </Page>
                {selectRooms && (
                    <RoomLayout
                        onClick={this.handleSetup}
                        adults={adults.length}
                        childCount={children.length}
                        rooms={rooms}
                    />
                )}
            </React.Fragment>
        );
    }
}

Ticket.contextType = MobileContext;

export default withTranslation()(Ticket);
