import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Cache from '~source/core/services/cache';
import $ from './Thanks.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {}

const Thanks: React.FunctionComponent<Props> = ({ t }) => {
    const bookerDetails = Cache.getBookerDetails();
    const booking = Cache.getBooking();
    const eventDetail = Cache.getEventDetail();

    if (!bookerDetails || !eventDetail) return null;

    const { firstName, email } = bookerDetails;

    return (
        <div className={$.container}>
            <h1 className={$.title}>
                {firstName}, {t('thanks_title')}
            </h1>
            <p className={$.description}>
                {t('thanks_email')} {email}
            </p>
            <p className={$.description}>
                {t('thanks_booking')} {booking}
            </p>
            <p className={$.description}>
                <span className={$.span}>
                    {t('thanks_questions')} {t('thanks_call-us-at')}
                </span>
                <a href={`tel:${t('support_phone_number')}`} className={$.phone}>
                    {t('support_phone_number')}
                </a>
                <span className={$.span}>{t('thanks_or-use')}</span>
                <a
                    href={`https://wa.me/${t('support_whatsapp_number')}`}
                    className={$.whatsappLink}
                >
                    Whatsapp
                </a>
            </p>
            {/* Not necessary for MMVP */}
            {/* <h2>{t('thanks_share')}</h2> */}
            {/* <Social /> */}
        </div>
    );
};

export default withTranslation()(Thanks);
