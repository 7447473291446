import * as React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle, InfoWindow } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { Accommodation } from '~source/core/models/Accommodation';
import { EventDetail } from '~source/core/models/EventDetail';
import * as Cache from '~source/core/services/cache';
import { Button, Stars } from '..';
import $ from './Map.scss';

const EventsTravelMap = compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyBvnZ3IjekahSLZ6Hdy2m7wQ47N3BvjH68&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: '100%' }} />,
        mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap,
)(props => <GoogleMap {...props}>{props.children}</GoogleMap>);

interface Props {
    accomodations: Accommodation[];
    eventDetail: EventDetail;
    breakfast?: boolean;
    height: string;
    selectAccommodation?: (accommodation: Accommodation) => void;
    selectedAccommodation?: Accommodation;
}

interface State {
    openAcccommodation?: Accommodation;
}

class Map extends React.Component<Props, State> {
    public static defaultProps = {
        height: '33.125rem',
    };

    public constructor(props) {
        super(props);
        this.state = {};
        this.openMarker = this.openMarker.bind(this);
        this.closeMarker = this.closeMarker.bind(this);
        this.selectAccommodation = this.selectAccommodation.bind(this);
    }

    public componentDidUpdate(prevProps) {
        const { selectedAccommodation } = this.props;

        if (prevProps.selectedAccommodation !== selectedAccommodation) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ openAcccommodation: selectedAccommodation });
        }
    }

    public openMarker(accommodation: Accommodation) {
        const { selectAccommodation } = this.props;
        if (selectAccommodation != null) {
            this.setState({ openAcccommodation: accommodation });
        }
    }

    public closeMarker() {
        this.setState({ openAcccommodation: undefined });
    }

    public selectAccommodation(accommodation: Accommodation) {
        const { selectAccommodation } = this.props;
        if (selectAccommodation != null) {
            this.closeMarker();
            selectAccommodation(accommodation);
        }
    }

    public render() {
        const { accomodations, eventDetail, breakfast, height } = this.props;
        const { openAcccommodation } = this.state;

        const currentAccommodation = Cache.getAccommodation();

        const { latitude, longitude } = eventDetail.venue;
        const { latitude: cityLatitude, longitude: cityLongitude } = eventDetail.venue.city;

        const containerElement = <div style={{ height }} />;

        return (
            <EventsTravelMap defaultZoom={12} defaultCenter={{ lat: latitude, lng: longitude }} options={{ disableDefaultUI: true }} containerElement={containerElement}>
                <Marker position={{ lat: latitude, lng: longitude }} icon="/images/eventmarker.png" />
                {accomodations.map((accommodation) => {
                    let price = '';
                    if (accommodation.availability != null) {
                        price = breakfast
                        ? `${accommodation.availability.cheapestBreakfast.supplementPP}`
                        : `${accommodation.availability.cheapest.supplementPP}`;
                    }

                    return (
                        <Marker
                            position={{ lat: accommodation.latitude, lng: accommodation.longitude }}
                            icon={currentAccommodation != null && currentAccommodation.id === accommodation.id ? '/images/hotelselected.png' : '/images/hotelmarker.png'}
                            key={accommodation.id}
                            onClick={() => this.openMarker(accommodation)}
                        >
                            {openAcccommodation != null && openAcccommodation.id === accommodation.id && (
                            <InfoWindow onCloseClick={this.closeMarker}>
                                <div className={$.infoContent}>
                                    <p className={$.title}>{accommodation.name}</p>
                                    <p className={$.price}>
                                        + <span className={accommodation.recommended ? $.green : $.blue}>&euro;{price}</span> <small>p.p.</small>
                                    </p>
                                    <div className={$.clear} />
                                    <div className={$.stars}>
                                        <Stars stars={accommodation.stars} />
                                    </div>
                                    <div className={$.button}>
                                        <Button
                                            white
                                            text="Selecteer"
                                            onClick={() => this.selectAccommodation(accommodation)}
                                            className={$.buttonSelect}
                                            leftAligned
                                        />
                                    </div>
                                </div>
                            </InfoWindow>
                            )}
                        </Marker>
                    );
                })}
                <Circle
                    defaultCenter={{ lat: cityLatitude, lng: cityLongitude }}
                    defaultRadius={2000}
                    options={{
                        fillColor: '#0CA2F5',
                        fillOpacity: 0.1,
                        strokeWeight: 2,
                        strokeColor: '#0CA2F5',
                        strokeOpacity: 0.5,
                    }}
                />
            </EventsTravelMap>
        );
    }
}

export default Map;
