import * as React from 'react';
import classnames from 'classnames';
import { Price } from '..';
import $ from './FormCheckbox.scss';

interface Props {
    title: string;
    subTitle?: string;
    name: string;
    id: string;
    onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
    checked: boolean;
    price?: number | string;
    priceLabel?: string;
    white?: boolean;
    icon?: string;
    fullWidth?: boolean;
    isPayment?: boolean;
}

const FormCheckbox = (props: Props) => {
    const { icon, title, id, name, subTitle, onChange, checked, price, priceLabel, white, fullWidth, isPayment } = props;
    const formRowStyles = classnames([
        $.formrow,
        fullWidth && $.formrowFullWidth,
        isPayment && $.formrowMarginTop,
    ]);
    const labelStyles = classnames([
        $.checklabel,
        subTitle && $.subtitlelabel,
        white && $.white,
        isPayment && $.fullHeight,
    ]);

    return (
        <div className={formRowStyles}>
            <input
                className={$.checkbox}
                type="radio"
                name={name}
                id={id}
                value={title}
                onChange={onChange}
                checked={checked}
            />
            <label className={labelStyles} htmlFor={id}>
                {icon && (
                    <img className={$.icon} src={icon} alt={title} />
                )}
                <p className={$.title}>{title}</p>
                {price !== undefined && (
                    <Price price={price} green={(price === 0 || price === '0')} />
                )}
                {subTitle && (
                    <small className={$.subTitle}>{subTitle}</small>
                )}
                {priceLabel && <small className={$.priceLabel}>{priceLabel}</small>}
            </label>
        </div>
    );
};

export default FormCheckbox;
