import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './Footer.scss';
import { PayIcons } from '..';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {}

const Footer = (props: Props) => {
    const { t } = props;
    return (
        <div className={$.container}>
            <footer className={$.base}>
                <div className={$.left}>
                    <span className={$.columnTitle}>
                        {t('footer_safebooking')}
                    </span>
                    <div className={$.logos}>
                        <img src="/images/logos/sgr.png" alt="SGR" />
                    </div>
                </div>
                <div className={$.right}>
                    <span className={$.columnTitle}>
                        {t('footer_safepayment')}
                    </span>
                    <PayIcons />
                </div>
            </footer>
        </div>
    );
};

export default withTranslation()(Footer);
