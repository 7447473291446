import moment from 'moment-timezone';
import { Team, TeamEntity } from './Team';
import { VenueEntity, Venue } from './Venue';
import { TicketEntity, Ticket } from './Ticket';
import { BaseAccommodationEntity, BaseAccommodation } from './BaseAccommodation';
import { SerieEntity, Serie } from './Serie';

/* eslint-disable lines-between-class-members */
export interface EventDetailEntity {
    id: string;
    name: string;
    type: string;
    datetime: string;
    date_confirmed: boolean;
    date_bookable_start: string;
    date_bookable_end: string;
    date_minimum_start: string;
    date_minimum_end: string;
    venue: VenueEntity;
    tickets: TicketEntity[];
    base_price_ticket_accommodation: BaseAccommodationEntity;
    series: SerieEntity;
    team_away?: TeamEntity;
    team_home?: TeamEntity;
}

export class EventDetail {
    public readonly id: string;
    public readonly title: string;
    public readonly name: string;
    public readonly type: string;
    public readonly dateConfirmed: boolean;
    public readonly dateBookableStart: Date;
    public readonly dateBookableEnd: Date;
    public readonly dateMinimumStart: Date;
    public readonly dateMinimumEnd: Date;
    public readonly venue: Venue;
    public readonly dateTime: Date;
    public readonly tickets: Ticket[];
    public readonly baseAccomodation: BaseAccommodation;
    public readonly serie: Serie;
    public readonly teamHome?: Team;
    public readonly teamAway?: Team;

    public constructor(eventData: EventDetailEntity) {
        this.id = eventData.id;
        this.name = eventData.name;
        this.title = eventData.team_home && eventData.team_away
        ? `${eventData.team_home.name} - ${eventData.team_away.name}`
        : eventData.name;
        this.type = eventData.type;
        this.dateConfirmed = eventData.date_confirmed || false;
        this.dateBookableStart = new Date(eventData.date_bookable_start);
        this.dateBookableEnd = new Date(eventData.date_bookable_end);
        this.dateMinimumStart = new Date(eventData.date_minimum_start);
        this.dateMinimumEnd = new Date(eventData.date_minimum_end);
        this.dateTime = moment(eventData.datetime).format('YYYY-MM-DD HH:mm');
        this.venue = new Venue(eventData.venue);
        this.tickets = eventData.tickets.map(ticket => new Ticket(ticket));
        this.baseAccomodation = new BaseAccommodation(eventData.base_price_ticket_accommodation);
        this.serie = new Serie(eventData.series);
        this.teamHome = eventData.team_home && new Team(eventData.team_home);
        this.teamAway = eventData.team_away && new Team(eventData.team_away);
    }
}
