import * as React from 'react';
import classnames from 'classnames';
import { Html } from '..';
import $ from './FaqItem.scss';

interface Props {
    question: string;
    answer: string;
    active: string;
    setActive: (item: string) => void;
}

const FaqItem = (props: Props) => {
    const { question, answer, active, setActive } = props;
    const elementRef = React.createRef<HTMLDivElement>();
    const thisItemActive = active === question;

    return (
        <li className={$.wrap}>
            <button
                onClick={() => setActive(question)}
                type="button"
                className={$.question}
                data-testid="button"
            >
                <img
                    src="/images/icon-dropdown.svg"
                    alt=""
                    title=""
                    className={classnames([$.arrow, thisItemActive && $.arrowReverse])}
                />
                {question}
            </button>
            <section
                className={$.answerWrap}
                ref={elementRef}
                style={{ height: thisItemActive ? '100%' : '0px' }}
                data-testid="answer-wrap"
            >
                <Html className={$.answer}>{answer.split('/n').join('<br />')}</Html>
            </section>
        </li>
    );
};

export default FaqItem;
