import * as React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './PreviousStepBanner.scss';
import IconLabel from '../IconLabel';
import { IconLabelType } from '../IconLabel/IconLabel';
import * as Cache from '../../../core/services/cache';
import { getTripDate } from '~source/utils/utils';

interface Props extends WithTranslation {}

const PreviousStepBanner = (props: Props) => {
    const { t } = props;
    const ticket = Cache.getTicket();
    const eventDetail = Cache.getEventDetail();
    const { startDate, endDate } = Cache.getInOutDateFormats();
    const { adults, children, rooms } = Cache.getOccupancy();

    const adultsLabel = adults.length === 1 ? t('adult') : t('adults');
    const childrenLabel = children.length === 1 ? t('child') : t('children');
    const roomsLabel = rooms === 1 ? t('room') : t('rooms');

    const adultsText = `${adults.length} ${t(adultsLabel)}`;
    const childrenText = `${children.length} ${t(childrenLabel)}`;
    const roomsText = `${rooms} ${t(roomsLabel)}`;

    return (
        <div className={$.container} data-testid="previousStepBanner">
            <p>
                <IconLabel type={IconLabelType.GreenHeader}>{t('state_traveldetails')}</IconLabel>
                <small>
                    <Link to={`/ticket/${eventDetail.id}`}>
                        <IconLabel type={IconLabelType.Edit}>{t('state_edit')}</IconLabel>
                    </Link>
                </small>
            </p>
            <ul>
                <li>
                    <p>{t('state_traveldates')}</p>
                    <p>
                        {getTripDate(startDate, endDate)}
                    </p>
                </li>
                <li>
                    <p>{t('ticket_travelcompany')}</p>
                    <p>
                        {adultsText}, {childrenText}, {roomsText}
                    </p>
                </li>
                {ticket && (
                    <li>
                        <p>{t('state_seats')}</p>
                        <p>{ticket.name}</p>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default withTranslation()(PreviousStepBanner);
