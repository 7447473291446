import * as React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './ProgressCard.scss';
import { ConfirmLabel } from '~source/view/components';
import { EventDetail } from '~source/core/models/EventDetail';
import { Accommodation } from '~source/core/models/Accommodation';
import { Availability } from '~source/core/models/Availability';
import * as Cache from '~source/core/services/cache';
import { EventImageTitle } from '..';
import TravelDetailList from '../TravelDetailList';
import { getTripDate } from '~source/utils/utils';

interface Props extends WithTranslation {
    eventDetail?: EventDetail;
    travelDetailOpen: boolean;
    loading: boolean;
    toggleDetail: () => void;
    openHotelDetail: (accommodation: Accommodation) => void;
    availability?: Availability;
    showBooking: boolean;
    step: number;
}

class ProgressCard extends React.Component<Props> {
    public constructor(props) {
        super(props);
    }

    public renderToggleDetailButton = () => {
        const { toggleDetail, travelDetailOpen, t } = this.props;

        return (
            <button
                className={classnames($.blue, travelDetailOpen && $.isTravelDetailOpen)}
                type="button"
                onClick={toggleDetail}
            >
                <span className={$.blue}>{t('progresscard_traveldetails')}</span>
            </button>
        );
    }

    public renderYourJourneyHeader() {
        const { t } = this.props;

        return (
            <h2 className={$.bookingTitle}>
                <span>{t('progresscard_your_journey')}</span>
            </h2>
        );
    }

    public renderDateHeader() {
        const { startDate, endDate } = Cache.getInOutDateFormats();

        return (
            <div className={$.headerWrap}>
                {startDate && endDate && getTripDate(startDate, endDate)}
                {this.renderToggleDetailButton()}
            </div>
        );
    }

    public render() {
        const { t, eventDetail, travelDetailOpen, openHotelDetail, step } = this.props;
        const availability = Cache.getAvailability();
        const { children, adults } = Cache.getOccupancy();
        const accommodation = Cache.getAccommodation();
        const imageLabel = t('progresscard_label'); // this should be dynamically set through the api.

        if (!eventDetail) {
            return <div />;
        }

        const date = moment(eventDetail.dateTime).format('DD/MM/YYYY, H:mm');
        const ticket = null;

        return (
            <section className={$.progressCard} data-testid="progressCard">
                <EventImageTitle title={eventDetail.title} roundedBorders />
                <div className={$.content}>
                    <div className={$.detailWrap}>
                        {((!travelDetailOpen && step < 2) || travelDetailOpen) && this.renderDateHeader()}
                        {(travelDetailOpen || step >= 2) && eventDetail && (
                            <div className={$.booking}>
                                {step >= 2 && this.renderYourJourneyHeader()}
                                <ul className={$.options}>
                                    <li>
                                        <div>{t('traveldetail_match')}</div>
                                        <div>
                                            {date}
                                            <ConfirmLabel
                                                isConfirmed={eventDetail.dateConfirmed}
                                                className={$.confirmedLabel}
                                                withTooltip
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div>{t('traveldetail_venue')}</div>
                                        <div>{eventDetail.venue.name}</div>
                                    </li>
                                    {ticket && (
                                        <li>
                                            <div>{t('traveldetail_seats')}</div>
                                            <div>{ticket}</div>
                                        </li>
                                    )}
                                    <li>
                                        <div>{t('traveldetail_persons')}</div>
                                        <div>
                                            {t('traveldetail_adults', { adults: adults.length })}
                                            <br />
                                            {children.length > 0
                                                && t('traveldetail_children', {
                                                    children: children.length,
                                                })}
                                        </div>
                                    </li>
                                    {accommodation && (
                                        <li>
                                            <div>{t('traveldetail_hotel')}</div>
                                            <div>
                                                {accommodation.name}
                                                <br />
                                                <button
                                                    type="button"
                                                    className={$.detailsButton}
                                                    onClick={() => openHotelDetail(accommodation)}
                                                >
                                                    {t('hotel_details_text_only')}
                                                </button>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                    <TravelDetailList
                        {...this.props}
                        availability={availability}
                        eventDetail={eventDetail}
                        extraIndent
                    />
                </div>
            </section>
        );
    }
}

export default withTranslation()(ProgressCard);
