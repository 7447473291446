import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './HotelList.scss';
import HotelItem from '../HotelItem';
import { Accommodation } from '../../../core/models/Accommodation';

interface Props extends WithTranslation {
    accommodations: Accommodation[];
    selectedAccommodation?: Accommodation;
    breakfast: boolean;
    onChange: (accomodation: Accommodation) => void;
    openDetail: (accommodation: Accommodation) => void;
    openQuickView?: (Accommodation: Accommodation) => void;
    wide?: boolean;
}

interface State {
    currentPage: number;
}

class HotelList extends React.Component<Props, State> {
    public hotelsShownPerPage = 4;

    public constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
        };
    }

    public showMoreHotels = () => {
        const { currentPage } = this.state;
        const newCurrentPage = currentPage + 1;

        this.setState({
            currentPage: newCurrentPage,
        });
    }

    public render() {
        const { t, breakfast, openDetail, onChange, accommodations: propsAccommodations, selectedAccommodation, openQuickView, wide } = this.props;
        const { currentPage } = this.state;
        const pages = Math.ceil(propsAccommodations.length / this.hotelsShownPerPage);
        const accommodations = propsAccommodations.slice(0, (this.hotelsShownPerPage * currentPage));

        return (
            <div className={$.container}>
                {accommodations.map(accommodation => (
                    <HotelItem
                        accommodation={accommodation}
                        selected={selectedAccommodation != null && accommodation.id === selectedAccommodation.id}
                        breakfast={breakfast}
                        key={accommodation.id}
                        onChange={() => onChange(accommodation)}
                        openDetail={openDetail}
                        onClickFunction={openQuickView}
                        wide={wide}
                    />
                ))}
                {currentPage < pages && (
                    <button type="button" className={$.showMoreButton} onClick={this.showMoreHotels}>{t('show_more_hotels')}</button>
                )}
            </div>
        );
    }
}

export default withTranslation()(HotelList);
