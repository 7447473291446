/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Page,
    DetailsTraveler,
    Button,
    IconLabel,
    ErrorMessage,
    Checkbox,
} from '~source/view/components';
import $ from './Details.scss';
import { IconLabelType } from '~source/view/components/IconLabel/IconLabel';
import { EventDetail } from '~source/core/models/EventDetail';
import * as Cache from '~source/core/services/cache';
import { setOrderTravelers, validateOrder } from '~source/core/services/order';
import { Traveler, Booker } from '~source/core/models/Traveler';
import { getValuesFromObject, flat } from '~source/utils/utils';

interface State {
    eventDetail?: EventDetail | null;
    bookOther: boolean;
    travelerDetails: Traveler[];
    bookerDetails: Booker;
    missingFields: string[][];
    generalErrors: string[];
    isValidating: boolean;
}

interface Props extends WithTranslation {
    history: any;
}

const requiredTravelerProperties = [
    'firstName',
    'lastName',
    'gender',
    'birthday',
    'countryCode',
];
const requiredBookerProperties = [
    'city',
    'email',
    'firstName',
    'lastName',
    'gender',
    'birthday',
    'phoneNumber',
    'countryCode',
    'addressLine1',
];

export function getFormTranslation(backendProp) {
    const translations = {
        city: 'details_city',
        email: 'details_email',
        firstName: 'details_firstnameshort',
        lastName: 'details_lastnameshort',
        gender: 'details_gender',
        phoneNumber: 'details_phone',
        countryCode: 'details_nationality',
        nationality: 'details_nationality',
        addressLine1: 'details_address-1',
        birthday: 'details_birthdate',
    };

    return translations[backendProp];
}

export function changeBackEndPropToFrontEndProp(backendProp) {
    const translations = {
        city: 'city',
        email: 'email',
        first_name: 'firstName',
        last_name: 'lastName',
        gender: 'gender',
        phone_number: 'phoneNumber',
        country_code: 'countryCode',
        nationality: 'countryCode',
        address_line_1: 'addressLine1',
        birth_date: 'birthday',
    };

    return translations[backendProp];
}

function getInvalidFields(travellers) {
    const invalidFields: string[][] = [];
    for (let index = 0; index < travellers.length; index += 1) {
        const { details, required } = travellers[index];
        const unfilledFields = required.filter(field => !details[field]);

        invalidFields.push(unfilledFields);
    }
    return invalidFields;
}

function getTravelerDetails() {
    const { adults, children } = Cache.getOccupancy();
    return adults.concat(children);
}

function createBookerAndSetCache(travelDetails) {
    const booker = new Booker({ ...travelDetails, type: 'booker' });
    Cache.setBookerDetails(booker);
    return booker;
}

function formatTravellers(travelDetails) {
    return travelDetails.map(traveller => {
        const newTraveller = new Traveler({ ...traveller, type: 'traveller' });
        return newTraveller.toBackendFormat(traveller);
    });
}

function formatBooker(booker) {
    const newBooker = new Booker({ ...booker, type: 'traveller' });
    return newBooker.toBackendFormat(booker);
}

class Details extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            eventDetail: Cache.getEventDetail(),
            bookOther: false,
            travelerDetails: getTravelerDetails(),
            bookerDetails: Cache.getBookerDetails(),
            missingFields: [],
            generalErrors: [],
            isValidating: false,
        };
        this.activateOther = this.activateOther.bind(this);
    }

    public async setTravelerDetails(
        number: number,
        travelDetails: { [id: string]: string | undefined }
    ) {
        const { bookerDetails, travelerDetails, bookOther } = this.state;
        let bookerDetailsMutatable = bookerDetails;
        const travelerDetailsMutatable = [...travelerDetails];

        if (number === -1) {
            bookerDetailsMutatable = createBookerAndSetCache(travelDetails);
        } else if (!bookOther && number === 0) {
            const traveller = new Traveler({ ...travelDetails, type: 'traveller' });
            const { adults } = Cache.getOccupancy();

            adults[0] = traveller;
            Cache.setAdults(adults);

            bookerDetailsMutatable = createBookerAndSetCache(travelDetails);
            travelerDetailsMutatable[number] = traveller;
        } else {
            const traveller = new Traveler({ ...travelDetails, type: 'traveller' });
            const { children, adults } = Cache.getOccupancy();

            // if it's a child
            if (number >= adults.length) {
                const index = number - adults.length;
                children[index] = traveller;
                Cache.setChildren(children);
            } else {
                adults[number] = traveller;
                Cache.setAdults(adults);
            }
            travelerDetailsMutatable[number] = traveller;
        }

        this.setState({
            bookerDetails: bookerDetailsMutatable,
            travelerDetails: travelerDetailsMutatable,
            generalErrors: [],
        });
    }

    // Filter duplicates for error message at the top of the page
    public filterInvalidFields = (arr: string[]) => arr
            .filter((field, i) => arr.indexOf(field) === i);

    public translateInvalidFields = (arr: string[]) => {
        const { t: translate } = this.props;
        return arr.map(field =>
            translate(getFormTranslation(field))
        );
    };

    public isFormValid = async () => {
        const { bookerDetails, travelerDetails } = this.state;

        const invalidFields = getInvalidFields([
            {
                details: bookerDetails,
                required: requiredBookerProperties,
            },
            ...travelerDetails.map(traveler => ({
                details: traveler,
                required: requiredTravelerProperties,
            })),
        ]);

        this.setState({ missingFields: invalidFields });

        return flat(invalidFields).length === 0;
    };

    public handleFormSubmit = async e => {
        e.preventDefault();

        const isFormValid = await this.isFormValid();

        if (isFormValid) {
            this.setState({ isValidating: true });
            const { history } = this.props;
            const { bookerDetails, travelerDetails } = this.state;
            const booker = formatBooker(bookerDetails);
            const travellers = formatTravellers(travelerDetails);
            const order = Cache.getOrder();

            await setOrderTravelers(order.id, booker, travellers)
                .then(async () => {
                    await validateOrder(order.id);
                    return history.push('/payment');
                })
                .catch(err => {
                    const { status, data } = err.response;
                    this.setState({ isValidating: false });

                    if (status === 422) {
                        const errorKeys = Object.keys(data.errors);
                        const backendGeneralErrors = errorKeys
                            .filter(error => !error.includes('customer') && !error.includes('travellers'))
                            .reduce((errors, key) => {
                                const updatedErrors = { ...errors };
                                updatedErrors[key] = data.errors[key];
                                return updatedErrors;
                            }, {});

                        const generalErrors = getValuesFromObject(backendGeneralErrors);

                        this.setMissingFields(errorKeys);
                        this.setState({ generalErrors });
                        window.scrollTo(0, 0);
                    }
                });
        }

        return window.scrollTo(0, 0);
    };

    public formatTravellerBackEndProps = travellers => {
        const { travelerDetails } = this.state;
        const formattedTravellerFields: string[][] = [];

        // invalid fields will be returned in this format:
        // travellers.0[field-name], filter travellers.0 from the object key so we can translate it.
        for (let i = 0; i < travelerDetails.length; i += 1) {
            let fields: string[] = travellers.filter(field => field.includes(`travellers.${i}`));
            fields = fields.map(field => {
                field.replace(/(travellers.[0-9].)/g, '');
                return changeBackEndPropToFrontEndProp(field);
            });
            formattedTravellerFields.push(fields);
        }

        return formattedTravellerFields;
    }

    public setMissingFields = fields => {
        const missingFields = (): string[][] => {
            const customer = fields.filter((field: string) => field.includes('customer'));
            const travellers = fields.filter((field: string) => field.includes('travellers'));
            const formattedTravellers = this.formatTravellerBackEndProps(travellers);
            // invalid fields will be returned in this format:
            // customer.0[field-name], filter customer.0 from the object key so we can translate it.
            const formattedCustomer = customer.map(field => changeBackEndPropToFrontEndProp(field.replace(/(customer.)/g, '')));

            return [formattedCustomer, ...formattedTravellers];
        };

        this.setState({ missingFields: missingFields() });
    };

    public activateOther() {
        const { bookOther } = this.state;
        this.setState({ bookOther: !bookOther });
    }

    public render() {
        const { t: translate, history } = this.props;
        const { eventDetail, bookOther, missingFields, isValidating, generalErrors } = this.state;
        const mutableMissingFields = [...missingFields];
        const isMissingFields = flat(mutableMissingFields).length > 0;

        if (!eventDetail) {
            return <Redirect to="/404" />;
        }

        // missingFields[0] are the missing fields from the booker details, missingFields[1] and up are adults and children details
        // If bookOther is false, missingFields[0] and missingFields[1] are the same and we can delete missingFields[1]
        if (!bookOther && isMissingFields) {
            mutableMissingFields.splice(1, 1);
        }
        const filteredMissingFields = mutableMissingFields && this.filterInvalidFields(flat(mutableMissingFields));
        const translatedMissingFields = filteredMissingFields && this.translateInvalidFields(filteredMissingFields);

        const { adults, children } = Cache.getOccupancy();
        const data: JSX.Element[] = [];

        if (bookOther) {
            const missingBookerFields = isMissingFields ? mutableMissingFields[0] : [];
            data.push(
                <DetailsTraveler
                    booker
                    eventDate={eventDetail.dateTime}
                    extended
                    cache={Cache.getBookerDetails() ? Cache.getBookerDetails() : adults[0]}
                    key={-1}
                    number={-1}
                    callback={e => this.setTravelerDetails(-1, e)}
                    missingFields={missingBookerFields}
                />
            );
        }

        for (let j = 0; j < adults.length; j += 1) {
            const missingAdultFields = isMissingFields ? mutableMissingFields[bookOther ? j + 1 : j] : [];
            data.push(
                <DetailsTraveler
                    booker={false}
                    eventDate={eventDetail.dateTime}
                    extended={!bookOther && j === 0}
                    cache={j === 0 ? Cache.getBookerDetails() : adults[j]}
                    key={j}
                    number={j}
                    callback={e => this.setTravelerDetails(j, e)}
                    missingFields={missingAdultFields}
                />
            );
        }

        if (children.length !== 0) {
            for (let j = 0; j < children.length; j += 1) {
                const missingChildFields = isMissingFields ? mutableMissingFields[bookOther ? adults.length + j + 1 : adults.length + j] : [];
                data.push(
                    <DetailsTraveler
                        booker={false}
                        eventDate={eventDetail.dateTime}
                        extended={false}
                        cache={children[j]}
                        key={adults.length + j}
                        number={adults.length + j}
                        callback={e => this.setTravelerDetails(adults.length + j, e)}
                        missingFields={missingChildFields}
                    />
                );
            }
        }

        return (
            <Page step={2} eventDetail={eventDetail} history={history}>
                <div className={$.back}>
                    <Link to="/hotel">
                        <IconLabel type={IconLabelType.Back}>{translate('hotel_change')}</IconLabel>
                    </Link>
                </div>
                <form className={$.container} onSubmit={this.handleFormSubmit} noValidate>
                    <div className={$.headerWrap}>
                        <h1 className={$.header}>{translate('details_enterdata')}</h1>
                        {generalErrors.length > 0 && generalErrors.map(generalError => (
                            <div className={$.error} key={generalError}>
                                <ErrorMessage text={generalError} />
                            </div>
                        ))}
                        {translatedMissingFields.length > 0 && (
                            <div className={$.error}>
                                <ErrorMessage
                                    text={translate('details_info-missing')}
                                    missingFields={translatedMissingFields}
                                />
                            </div>
                        )}
                        <div className={$.bookOther}>
                            <Checkbox
                                name="other"
                                id="other"
                                onChange={this.activateOther}
                                label={translate('details_bookother')}
                            />
                        </div>
                    </div>
                    {data}
                    <div className={$.button}>
                        <Button
                            text={translate('details_to_overview')}
                            type="submit"
                            isLoading={isValidating}
                        />
                    </div>
                </form>
            </Page>
        );
    }
}

export default withTranslation()(Details);
