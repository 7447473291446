import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import FormSelect from '../FormSelect';
import $ from './RoomLayout.scss';
import { Button, CloseButton } from '..';

interface Props extends WithTranslation {
    onClick: (rooms: number, adults: number, layout: number[][]) => void;
    rooms: number;
    adults: number;
    childCount: number;
}

interface State {
    areAllTravelersAllocated: boolean;
    rooms: number;
    adults: number;
    children: number;
    layout: number[][];
}

type TravelerType = 'adult' | 'child';

function getAllocationCountByTravelerType(travelerType: TravelerType, layout: number[][]) {
    const roomIndex = travelerType === 'adult' ? 0 : 1;
    return layout.reduce((accumulator, room) => accumulator + room[roomIndex], 0);
}

interface CreateRoomLayoutOptions {
    adults: number;
    childrenCount: number;
    rooms: number;
}

export function createLayoutFromCompany(options: CreateRoomLayoutOptions): number[][] {
    const layout: number[][] = [];
    const { adults, childrenCount, rooms } = options;
    const adultsPerRoom = Math.floor(adults / rooms);
    const childrenPerRoom = Math.floor(childrenCount / rooms);
    const startAdults = adults - adultsPerRoom * (rooms - 1);
    const startChildren = childrenCount - childrenPerRoom * (rooms - 1);

    for (let i = 0; i < rooms; i += 1) {
        if (i === 0) {
            layout.push([startAdults, startChildren]);
        } else {
            layout.push([adultsPerRoom, childrenPerRoom]);
        }
    }
    return layout;
}

class RoomLayout extends React.Component<Props, State> {
    public roomLayoutRef = React.createRef<HTMLDivElement>();

    public static defaultProps = {
        rooms: 1,
        adults: 1,
        children: 0,
    };

    public constructor(props) {
        super(props);
        const { rooms, adults, childCount } = props;

        this.state = {
            areAllTravelersAllocated: true,
            rooms,
            adults,
            children: childCount,
            layout: createLayoutFromCompany({ adults, childrenCount: childCount, rooms }),
        };
        this.handleRooms = this.handleRooms.bind(this);
        this.handleAdults = this.handleAdults.bind(this);
        this.handleChildren = this.handleChildren.bind(this);
        this.handleSetup = this.handleSetup.bind(this);
        this.handleTravelerCountChange = this.handleTravelerCountChange.bind(this);
    }

    public componentDidUpdate() {
        disableBodyScroll(this.roomLayoutRef.current);
    }

    public componentWillUnmount() {
        clearAllBodyScrollLocks();
    }


    public handleRooms(value: string) {
        const { adults, children } = this.state;

        const rooms = +value + 1;

        const adultsPerRoom = Math.floor(adults / rooms);
        const childrenPerRoom = Math.floor(children / rooms);
        const startAdults = adults - adultsPerRoom * (rooms - 1);
        const startChildren = children - childrenPerRoom * (rooms - 1);

        const layout: number[][] = [];
        for (let i = 0; i < rooms; i += 1) {
            if (i === 0) {
                layout.push([startAdults, startChildren]);
            } else {
                layout.push([adultsPerRoom, childrenPerRoom]);
            }
        }
        this.setState({ rooms, layout });
    }

    public handleTravelerCountChange() {
        const { layout } = this.state;
        const { adults, childCount } = this.props;
        const allocatedAdultsCount = getAllocationCountByTravelerType('adult', layout);
        const allocatedChildrenCount = getAllocationCountByTravelerType('child', layout);

        this.setState({
            areAllTravelersAllocated:
                adults - allocatedAdultsCount === 0 && childCount - allocatedChildrenCount === 0,
        });
    }

    public handleAdults(value: string, id: string) {
        const { layout, adults: oldAdults } = this.state;

        const adults = oldAdults - layout[id][0] + +value + 1;

        layout[id][0] = +value + 1;
        this.setState({ layout, adults }, this.handleTravelerCountChange);
    }

    public handleChildren(value: string, id: string) {
        const { layout, children: oldChildren } = this.state;

        const children = oldChildren - layout[id][1] + +value;

        layout[id][1] = +value;
        this.setState({ layout, children }, this.handleTravelerCountChange);
    }

    public createRooms() {
        const { rooms, layout } = this.state;
        const { t, adults, childCount } = this.props;

        // Calculate how many travellers have been allocated to a room.
        const allocatedAdultsCount = getAllocationCountByTravelerType('adult', layout);
        const allocatedChildrenCount = getAllocationCountByTravelerType('child', layout);

        const freeSpaceAdults = adults - allocatedAdultsCount;
        const freeSpaceChildren = childCount - allocatedChildrenCount;
        const roomsHtml: JSX.Element[] = [];
        const childrenTranslation = t('children');
        const childTranslation = t('child');
        const adultTranslation = t('adult');
        const adultsTranslation = t('adults');

        const baseOptions = [
            `1 ${adultTranslation}`,
            `2 ${adultsTranslation}`,
            `3 ${adultsTranslation}`,
            `4 ${adultsTranslation}`,
            `5 ${adultsTranslation}`,
            `6 ${adultsTranslation}`,
            `7 ${adultsTranslation}`,
            `8 ${adultsTranslation}`,
        ];

        const baseChildren = [
            `0 ${childrenTranslation}`,
            `1 ${childTranslation}`,
            `2 ${childrenTranslation}`,
            `3 ${childrenTranslation}`,
            `4 ${childrenTranslation}`,
            `5 ${childrenTranslation}`,
            `6 ${childrenTranslation}`,
            `7 ${childrenTranslation}`,
        ];

        for (let i = 0; i < rooms; i += 1) {
            const amount = layout[i][0] + freeSpaceAdults;
            const childrenAmount = layout[i][1] + freeSpaceChildren;
            const options = baseOptions.filter((_, index) => index < amount);
            const childrenOptions = baseChildren.filter((_, index) => index <= childrenAmount);

            roomsHtml.push(
                <div className={$.room} key={`room${i}`}>
                    <p className={$.text}>
                        {t('room').toUpperCase()} {i + 1}
                    </p>
                    <div className={$.firstSelector}>
                        <FormSelect
                            id={`${i}`}
                            options={options}
                            selected={layout[i][0] - 1}
                            onChange={this.handleAdults}
                        />
                    </div>
                    {childrenOptions.length > 1 && (
                        <div className={$.selector}>
                            <FormSelect
                                id={`${i}`}
                                options={childrenOptions}
                                selected={layout[i][1]}
                                onChange={this.handleChildren}
                            />
                        </div>
                    )}
                </div>
            );
        }
        return roomsHtml;
    }

    public handleSetup() {
        const { onClick } = this.props;
        const { rooms, adults, layout } = this.state;
        onClick(rooms, adults, layout);
    }

    public render() {
        const { t } = this.props;
        const { rooms, adults, areAllTravelersAllocated } = this.state;
        const baseRooms = ['1', '2', '3', '4', '5', '6', '7', '8'];
        const roomOptions: string[] = baseRooms.filter((_, index) => index < adults);

        return (
            <div className={$.wrap}>
                <button type="button" className={$.background} onClick={this.handleSetup} />
                <div className={$.roomLayout} ref={this.roomLayoutRef}>
                    <h1 className={$.header}>{t('ticket_defineroomlayout')}</h1>
                    <div>
                        <p className={$.text}>{t('ticket_totalrooms')}</p>
                        <FormSelect
                            options={roomOptions}
                            selected={rooms - 1}
                            onChange={this.handleRooms}
                        />
                    </div>
                    {this.createRooms()}
                    {!areAllTravelersAllocated && (
                        <div className={$.error}>{t('room_layout_occupancy_insufficient')}</div>
                    )}
                    <div className={$.confirmButton}>
                        <Button
                            onClick={this.handleSetup}
                            text={t('ticket_confirmroomlayout')}
                            disabled={!areAllTravelersAllocated}
                        />
                    </div>
                    <CloseButton onClick={this.handleSetup} />
                </div>
            </div>
        );
    }
}

export default withTranslation()(RoomLayout);
