import * as React from 'react';
import classnames from 'classnames';
import { Redirect, RouteProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Page,
    PreviousStepBanner,
    Map,
    IconLabel,
    HotelList,
    Button,
    HotelDetail,
    ToggleSlider,
    HotelQuickView,
    Checkbox,
    ErrorMessage,
} from '~source/view/components';
import $ from './Hotel.scss';
import { IconLabelType } from '~source/view/components/IconLabel/IconLabel';
import { EventDetail } from '~source/core/models/EventDetail';
import { setOrderAccommodation } from '~source/core/services/order';
import { Accommodation } from '~source/core/models/Accommodation';
import { Option } from '~source/view/components/FilterSelect/FilterSelect';
import FilterSelect from '~source/view/components/FilterSelect';
import * as Cache from '~source/core/services/cache';
import MobileContext from '~source/view/context/MobileContext';
import { getValuesFromObject } from '~source/utils/utils';
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {}

interface State {
    accommodations: Accommodation[];
    accommodation?: Accommodation;
    openAccommodation?: Accommodation;
    openQuickView?: Accommodation;
    breakfast: boolean;
    eventDetail?: EventDetail;
    loading: boolean;
    filter: FilterState;
    mapActive: boolean;
    generalErrors: string[];
}

enum FilterState {
    Price = 0,
    DistanceCenter,
    DistanceVenue,
    Stars,
    Recommended,
}

function sortAccommodations(filter, accommodations, breakfast) {
    let resultAccommodations = [...accommodations];

    // Recommended filter needs to be filtered differently.
    if (filter !== FilterState.Recommended) {
        resultAccommodations = resultAccommodations.sort((a, b) => {
            if (a.availability == null || b.availability == null) return 0;

            switch (filter) {
                // if the user has checked the breakfast box, sort by price with breakfast included. Otherwise sort by the cheapest without breakfast
                case FilterState.Price:
                    if (breakfast) {
                        return a.availability.cheapestBreakfast.supplementPP >
                            b.availability.cheapestBreakfast.supplementPP
                            ? 1
                            : -1;
                    }
                    return a.availability.cheapest.supplementPP >
                        b.availability.cheapest.supplementPP
                        ? 1
                        : -1;
                case FilterState.DistanceCenter:
                    return a.distanceToCityCenterRaw > b.distanceToCityCenterRaw ? 1 : -1;
                case FilterState.DistanceVenue:
                    return a.distanceToVenueRaw > b.distanceToVenueRaw ? 1 : -1;
                case FilterState.Stars:
                    return a.stars > b.stars ? -1 : 1;
                default:
                    return 0;
            }
        });
        // Accommodation has a prop recommended that's a boolean. Put the accommodations that are recommended first.
    } else {
        const recommended = resultAccommodations.filter(accomm => accomm.recommended === true);
        const notRecommended = resultAccommodations.filter(accomm => accomm.recommended === false);

        resultAccommodations = recommended.concat(notRecommended);
    }

    return resultAccommodations;
}

function filterAccommodationOnBreakfast(accommodations) {
    return accommodations.filter(
        accomodation =>
            accomodation.availability !== null &&
            accomodation.availability.cheapestBreakfast != null
    );
}

// eslint-disable-next-line react/prefer-stateless-function
class Hotel extends React.Component<Props & RouteProps, State> {
    private static filterDoubleAccommodations(accommodations: Accommodation[]) {
        const existingIds: string[] = [];
        return accommodations.filter(accommodation => {
            const includes = existingIds.includes(accommodation.id);
            if (!includes) {
                existingIds.push(accommodation.id);
            }
            return !includes;
        });
    }

    public hotelSelectorRef = React.createRef<HTMLDivElement>();

    public constructor(props: Props & RouteProps) {
        super(props);

        const eventDetail = Cache.getEventDetail();

        this.state = {
            accommodations: [],
            breakfast: true,
            loading: true,
            filter: FilterState.Price,
            accommodation: Cache.getAccommodation(),
            generalErrors: [],
            eventDetail,
            mapActive: true,
        };

        this.changeBreakfast = this.changeBreakfast.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changeAccommodation = this.changeAccommodation.bind(this);
        this.openDetail = this.openDetail.bind(this);
        this.closeDetail = this.closeDetail.bind(this);
        this.openHotelQuickView = this.openHotelQuickView.bind(this);
        this.closeHotelQuickView = this.closeHotelQuickView.bind(this);
    }

    public async componentDidMount() {
        const { history } = this.props;
        const eventDetail = Cache.getEventDetail();

        if (!eventDetail) {
            history.push('/404');
            return;
        }

        this.getAccommodations();
    }

    // eslint-disable-next-line class-methods-use-this
    private getAccommodations() {
        const order = Cache.getOrder();

        if (order == null) {
            this.setState({ loading: false });
            return;
        }

        const detail = Cache.getEventDetail();
        if (detail != null) {
            const accommodations = Hotel.filterDoubleAccommodations(Cache.getAccommodations());
            const availability = Cache.getAvailability();
            const ids = availability.accomodations.map(accomodation => accomodation.id);
            // the accommodations in availability don't contain all information about an accommodation but the accommodations in Cache.getAccommodations() do. filter the accommodations in Cache.getAccommodations() by availability
            const availableAccommodations = accommodations.filter(accomodation =>
                ids.includes(accomodation.id)
            );

            availableAccommodations.forEach(inputAccomodation => {
                const accommodation = inputAccomodation;
                accommodation.availability = availability.accomodations.find(
                    availabilityItem => availabilityItem.id === accommodation.id
                );
            });

            const sortedAccommodations = sortAccommodations(
                FilterState.Price,
                availableAccommodations,
                false
            );
            // the "include breakfast" checkbox is checked when the first accommodation of the sortedAccommodations array has breakfast included in the price
            const breakfast =
                (sortedAccommodations[0] &&
                    sortedAccommodations[0].availability.cheapest.breakfastIncluded) ||
                false;
            const getSelectedAccommodation = () => {
                const currentAccommodation = Cache.getAccommodation();

                if (currentAccommodation) {
                    return accommodations.filter(
                        accommodation => accommodation.id === currentAccommodation.id
                    )[0];
                }
                return sortedAccommodations[0];
            };
            const selectedAccommodation = getSelectedAccommodation();

            this.setState(
                {
                    breakfast,
                    eventDetail: detail,
                    accommodation: selectedAccommodation,
                    accommodations: sortedAccommodations,
                    loading: false,
                },
                () => {
                    Cache.setBreakfast(breakfast);
                    this.changeAccommodation(selectedAccommodation);
                }
            );
        }
    }

    public goToDetails = e => {
        e.preventDefault();
        const { history } = this.props;

        history.push('/details');
    };

    public setMapActive = (active: boolean) => {
        this.setState({ mapActive: active });
    };

    public changeFilter(value: string) {
        let filterState: FilterState;

        switch (+value) {
            case 1:
                filterState = FilterState.DistanceCenter;
                break;
            case 2:
                filterState = FilterState.DistanceVenue;
                break;
            case 3:
                filterState = FilterState.Stars;
                break;
            case 4:
                filterState = FilterState.Recommended;
                break;
            default:
                filterState = FilterState.Price;
        }
        this.setState({ filter: filterState });
    }

    public changeBreakfast() {
        const { breakfast, accommodation } = this.state;
        this.setState({ breakfast: !breakfast }, () => {
            if (accommodation) this.changeAccommodation(accommodation);
        });
        Cache.setBreakfast(!breakfast);
    }

    public async changeAccommodation(accommodation: Accommodation) {
        this.setState({ accommodation, generalErrors: [] });

        const order = Cache.getOrder();
        const { breakfast } = this.state;
        if (!order || !accommodation || !accommodation.availability) return;
        const { cheapestBreakfast, cheapest } = accommodation.availability;

        await setOrderAccommodation(order.id, {
            id: accommodation.id,
            partner: accommodation.availability.partner,
            // eslint-disable-next-line @typescript-eslint/camelcase
            partner_id: accommodation.availability.partnerId,
            rates: breakfast ? cheapestBreakfast.rates : cheapest.rates,
        }).then(() => {
            Cache.setAccommodation(accommodation);
        }).catch(err => {
            const { status, data } = err.response;

            if (status === 422) {
                const generalErrors = getValuesFromObject(data.errors);
                this.setState({ generalErrors });
                window.scrollTo(0, 0);
            }
        });
    }

    public openDetail(accommodation: Accommodation) {
        this.setState({ openAccommodation: accommodation });
    }

    public openHotelQuickView(accommodation: Accommodation) {
        const isMobile = this.context;
        let scrollTo = 0;

        if (isMobile) {
            const refPosition = this.hotelSelectorRef.current && this.hotelSelectorRef.current.getBoundingClientRect();

            if (refPosition) {
                // Get element position relative to the document
                const { body, documentElement } = document;
                const scrollTop = window.pageYOffset || documentElement.scrollTop || body.scrollTop;
                const clientTop = documentElement.clientTop || body.clientTop || 0;
                const headerHeight = 150;

                // Scroll to that element instead of the beginning of the page
                scrollTo = refPosition.top + scrollTop - clientTop - headerHeight;
            }
        }

        window.scrollTo(0, scrollTo);
        this.setState({ openQuickView: accommodation });
    }

    public closeHotelQuickView() {
        this.setState({ openQuickView: undefined });
    }

    public closeDetail() {
        this.setState({ openAccommodation: undefined });
    }

    public render() {
        const { t: translate } = this.props;
        const {
            accommodations,
            accommodation,
            breakfast,
            eventDetail,
            loading,
            filter,
            openAccommodation,
            openQuickView,
            mapActive,
            generalErrors,
        } = this.state;

        if (!eventDetail) {
            if (loading) {
                return <Page step={1}>Loading...</Page>;
            }
            return <Redirect to="/404" />;
        }

        let resultAccommodations = accommodations;

        if (breakfast) {
            resultAccommodations = filterAccommodationOnBreakfast(resultAccommodations);
        }

        resultAccommodations = sortAccommodations(filter, resultAccommodations, breakfast);

        const selectionOptions: Option[] = [
            {
                title: translate('hotel_optionprice'),
                subtitle: translate('hotel_lowestfirst'),
            },
            {
                title: translate('hotel_optioncenterdistance'),
            },
            {
                title: translate('hotel_optioneventdistance'),
            },
            {
                title: translate('hotel_optionstars'),
                subtitle: translate('hotel_mostfirst'),
            },
            {
                title: translate('hotel_optionrecommendations'),
            },
        ];

        return (
            <React.Fragment>
                <Page step={1} eventDetail={eventDetail}>
                    <div className={$.container}>
                        <div>
                            <PreviousStepBanner />
                        </div>
                        {generalErrors.length > 0 && generalErrors.map(error => (
                            <div key={error}>
                                <ErrorMessage text={error} />
                            </div>
                        ))}
                        <div>
                            <h1>{translate('ticket_pickhotel')}</h1>
                            <p className={$.sortHotels}>{translate('hotel_sort')}</p>
                            <FilterSelect
                                options={selectionOptions}
                                selected={selectionOptions[filter]}
                                onChange={this.changeFilter}
                                wide
                            />
                            <div className={$.extraOptions}>
                                <Checkbox
                                    label={
                                        <IconLabel type={IconLabelType.Breakfast}>
                                            {translate('hotel_includesbreakfast')}
                                        </IconLabel>
                                    }
                                    className={$.breakfastCheckboxContainer}
                                    classNameBox={$.breakfastCheckbox}
                                    id="breakfast"
                                    name="breakfast"
                                    onChange={this.changeBreakfast}
                                    defaultChecked={breakfast}
                                />
                                <div className={$.desktopOnly}>
                                    <ToggleSlider
                                        title={translate('map')}
                                        active={mapActive}
                                        setActive={this.setMapActive}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={$.hotelSelector}>
                            <div className={classnames([$.list, !mapActive && $.listFullWidth])} ref={this.hotelSelectorRef}>
                                {!openQuickView && (
                                    <HotelList
                                        accommodations={resultAccommodations}
                                        breakfast={breakfast}
                                        onChange={this.changeAccommodation}
                                        openDetail={this.openDetail}
                                        selectedAccommodation={accommodation}
                                        openQuickView={this.openHotelQuickView}
                                        wide={!mapActive}
                                    />
                                )}
                                {openQuickView && (
                                    <HotelQuickView
                                        accommodation={accommodation}
                                        breakfast={breakfast}
                                        onChange={this.changeAccommodation}
                                        openDetail={this.openDetail}
                                        selectedAccommodation={accommodation}
                                        closeQuickView={this.closeHotelQuickView}
                                        large
                                    />
                                )}
                                <div className={$.button}>
                                    <Button text={translate('ticket_nextstep')} onClick={this.goToDetails} />
                                </div>
                                {openQuickView && (
                                    <button
                                        type="button"
                                        className={$.buttonLink}
                                        onClick={this.closeHotelQuickView}
                                    >
                                        {translate('hotel_chooseotherhotel')}
                                    </button>
                                )}
                            </div>
                            <div className={classnames([$.map, mapActive && $.mapActive])}>
                                <Map
                                    accomodations={resultAccommodations}
                                    breakfast={breakfast}
                                    eventDetail={eventDetail}
                                    selectAccommodation={this.changeAccommodation}
                                    selectedAccommodation={accommodation}
                                />
                                <p className={$.legend}>
                                    <span className={$.legendIcon} /> {translate('city_centre')}
                                </p>
                            </div>
                        </div>
                    </div>
                </Page>
                <HotelDetail
                    accommodation={openAccommodation}
                    eventDetail={eventDetail}
                    onClick={this.closeDetail}
                />
            </React.Fragment>
        );
    }
}

Hotel.contextType = MobileContext;

export default withTranslation()(Hotel);
