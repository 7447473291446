import * as React from 'react';
import classnames from 'classnames';
import $ from './EventImageTitle.scss';

interface Props {
    image?: string;
    imageLabel?: string;
    mobileOnly?: boolean;
    roundedBorders?: boolean;
    title: string;
}

export default function EventImageTitle(props: Props) {
    const { title, image, imageLabel, mobileOnly, roundedBorders } = props;

    return (
        <div
            className={classnames([$.wrap, mobileOnly && $.wrapMobile, roundedBorders && $.wrapRounded])}
            style={{ backgroundImage: image || "url('/images/temp/coupon-placeholder.jpg')" }}
        >
            <div className={$.bg} />
            {/* Disable for now. Will be added when we have the correct data for the label */}
            {/* <span className={$.label}>{imageLabel}</span> */}
            <h1 className={$.eventName}>
                {title}
            </h1>
        </div>
    );
}
