import * as React from 'react';
import classnames from 'classnames';
import $ from './FormSelect.scss';

interface Props {
    options: string[];
    wide: boolean;
    onChange?: (value: string, id: string | undefined) => void;
    selected?: number;
    id?: string;
    smallHeight?: boolean;
}

// eslint-disable-next-line react/prefer-stateless-function
class FormSelect extends React.Component<Props> {
    public static defaultProps = {
        wide: false,
    };

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    public onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const { onChange, id } = this.props;
        if (onChange != null) {
            onChange(event.target.value, id);
        }
    }

    public render() {
        const { options, wide, selected, id, smallHeight } = this.props;
        const selectStyles = classnames([
            $.select,
            wide && $.wide,
            smallHeight && $.selectSmall,
        ]);

        return (
            <div className={selectStyles}>
                <select onChange={this.onChange} value={selected} id={id}>
                    {options.map((option, number) => (
                        // TODO: Refactor the options is not an array of strings but more like key, value object
                        // eslint-disable-next-line react/no-array-index-key
                        <option key={`name${number}`} value={number}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

export default FormSelect;
