import * as React from 'react';
import { Link, RouteProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Cache from '~source/core/services/cache';
import { EventDetail } from '~source/core/models/EventDetail';
import {
    Page,
    Button,
    TeaserBanner,
    FormCheckbox,
    IconLabel,
    Checkbox,
    ErrorMessage,
} from '~source/view/components';
import $ from './Payment.scss';
import { book, setOrderCoupon } from '~source/core/services/order';
import { parseLink, capitalize, getValuesFromObject, addValueToArray, removeValueFromArray, addMultipleValuesToArray } from '~source/utils/utils';
import { IconLabelType } from '~source/view/components/IconLabel/IconLabel';

interface State {
    eventDetail?: EventDetail | null;
    code?: string;
    loading: boolean;
    gateways: {
        displayName: string;
        name: string;
    }[];
    areGeneralConditionsChecked: boolean;
    errorMessages: string[];
    isInformationCorrectChecked: boolean;
    paymentMethod?: string;
}

interface Props extends WithTranslation {}

function mapGateways(payment) {
    const gateways = payment.gatewayIssuers.creditCard.map(paymentMethod => ({
        displayName: paymentMethod === 'amex' ? 'American Express' : capitalize(paymentMethod),
        name: paymentMethod,
    }));
    if (payment.gatewayIssuers.ideal) {
        gateways.push({
            displayName: 'iDeal',
            name: 'ideal',
        });
    }

    return gateways;
}

class Payment extends React.Component<Props & RouteProps, State> {
    public constructor(props: Props & RouteProps) {
        super(props);

        this.state = {
            eventDetail: null,
            gateways: [],
            paymentMethod: '',
            loading: false,
            areGeneralConditionsChecked: false,
            isInformationCorrectChecked: false,
            errorMessages: [],
        };
        this.book = this.book.bind(this);
        this.applyCode = this.applyCode.bind(this);
        this.setCode = this.setCode.bind(this);
    }

    public async componentDidMount() {
        const { history } = this.props;
        const eventDetail = Cache.getEventDetail();

        if (!eventDetail) {
            history.push('/404');
            return;
        }

        const { payment } = Cache.getConfig();
        const gateways = mapGateways(payment);

        this.setState({ eventDetail, gateways });
    }

    public setCode(e: React.FocusEvent<HTMLInputElement>) {
        this.setState({ code: e.target.value });
    }

    public validate = () => {
        const { t: translate } = this.props;
        const {
            paymentMethod,
            areGeneralConditionsChecked,
            isInformationCorrectChecked,
            errorMessages,
        } = this.state;

        if (!paymentMethod || !areGeneralConditionsChecked || !isInformationCorrectChecked) {
            let newErrorMessages = errorMessages;
            if (!paymentMethod) {
                newErrorMessages = addValueToArray(newErrorMessages, translate('payment_paymethodnotselected'));
            }
            if (!areGeneralConditionsChecked) {
                newErrorMessages = addValueToArray(newErrorMessages, translate('payment_generalconditionsnotchecked'));
            }
            if (!isInformationCorrectChecked) {
                newErrorMessages = addValueToArray(newErrorMessages, translate('payment_informationcorrectlyenterednotchecked'));
            }
            this.setState({ errorMessages: newErrorMessages });
            return;
        }
        this.book();
    };

    public removeError = (type: 'payment' | 'conditions' | 'informationCorrect') => {
        const { t: translate } = this.props;
        const { errorMessages } = this.state;
        const paymentErrorMessage = translate('payment_paymethodnotselected');
        const conditionsErrorMessage = translate('payment_generalconditionsnotchecked');
        const informationCorrectErrorMessage = translate('payment_informationcorrectlyenterednotchecked');

        // Remove all backend errors
        const updatedErrors = errorMessages.filter(error =>
            error === paymentErrorMessage
            || error === conditionsErrorMessage
            || error === informationCorrectErrorMessage
        );

        if (type === 'informationCorrect') return removeValueFromArray(informationCorrectErrorMessage, updatedErrors);
        if (type === 'conditions') return removeValueFromArray(conditionsErrorMessage, updatedErrors);
        return removeValueFromArray(paymentErrorMessage, updatedErrors);
    };

    public checkCheckbox = (type: 'payment' | 'conditions' | 'informationCorrect', newPaymentMethod: string | undefined = undefined) => {
        const { areGeneralConditionsChecked, isInformationCorrectChecked, paymentMethod } = this.state;

        this.setState({
            paymentMethod: !newPaymentMethod ? paymentMethod : newPaymentMethod,
            isInformationCorrectChecked: type === 'informationCorrect' ? !isInformationCorrectChecked : isInformationCorrectChecked,
            areGeneralConditionsChecked: type === 'conditions' ? !areGeneralConditionsChecked : areGeneralConditionsChecked,
            errorMessages: this.removeError(type),
        });
    }

    public async applyCode() {
        const order = Cache.getOrder();
        const { code } = this.state;

        if (code == null) {
            return;
        }

        this.setState({ loading: true });
        await setOrderCoupon(order.id, code);
        this.setState({ loading: false });
    }

    // eslint-disable-next-line class-methods-use-this
    public async book() {
        const { paymentMethod } = this.state;
        const order = Cache.getOrder();

        await book(order.id)
            .then(result => {
                Cache.setBooking(result.orderNumber);

                const options = () => {
                    if (paymentMethod === 'ideal') return 'gateway=IDEAL';
                    return `gateway=CREDITCARD&cc_type=${paymentMethod}`;
                };
                const thanksName = window.location.href.replace(window.location.pathname, '/completed');

                window.location.assign(`${result.paymentBaseUrl}?${options()}&callback=${thanksName}`);
            })
            .catch(err => {
                const { status, data } = err.response;

                if (status === 422) {
                    const { errorMessages } = this.state;
                    const backendErrors = getValuesFromObject(data.errors);

                    this.setState({ errorMessages: addMultipleValuesToArray(backendErrors, errorMessages) });
                }
            });
    }

    public render() {
        const { t: translate } = this.props;
        const {
            eventDetail,
            gateways,
            loading,
            paymentMethod,
            areGeneralConditionsChecked,
            errorMessages,
            isInformationCorrectChecked,
        } = this.state;

        return (
            <Page step={3} eventDetail={eventDetail}>
                <div className={$.back}>
                    <Link to="/details">
                        <IconLabel type={IconLabelType.Back}>{translate('details_change')}</IconLabel>
                    </Link>
                </div>
                <div className={$.container}>
                    <h1>{translate('payment_checkpay')}</h1>
                    <div className={$.row}>
                        <TeaserBanner title={translate('payment_booknow')} />
                    </div>
                    <div className={$.pickPayment}>
                        {gateways.length !== 0 &&
                            gateways.map(gateway => (
                                <FormCheckbox
                                    white
                                    icon={`/images/logos/${gateway.name}.png`}
                                    title={gateway.displayName}
                                    priceLabel={translate('payment_free')}
                                    name="paymentMethod"
                                    id={gateway.name}
                                    checked={paymentMethod === gateway.name}
                                    onChange={() => this.checkCheckbox('payment', gateway.name)}
                                    key={gateway.name}
                                    isPayment
                                />
                            ))}
                    </div>
                    {/* <div className={$.actionCode}>
                        <p className={$.text}>{t('payment_code')}</p>
                        <FormInput size={FormInputSize.Medium} placeholder={t('payment_code_placeholder')} onBlur={this.setCode} />
                        <button className={$.paymentAdd} type="button" onClick={() => this.applyCode()}>{t('payment_actioncode_add')}</button>
                    </div> */}
                    <div className={$.row}>
                        <Checkbox
                            id="payment_acceptgeneral"
                            name="payment_acceptgeneral"
                            className={$.checkboxAgreements}
                            classNameBox={$.checkboxAgreementsBox}
                            label={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: parseLink(translate('payment_acceptgeneral'), true),
                                    }}
                                />
                            }
                            defaultChecked={areGeneralConditionsChecked}
                            onChange={() => this.checkCheckbox('conditions')}
                        />
                    </div>
                    <div className={$.row}>
                        <Checkbox
                            id="payment_validated"
                            name="payment_validated"
                            className={$.checkboxAgreements}
                            classNameBox={$.checkboxAgreementsBox}
                            label={translate('payment_validated')}
                            defaultChecked={isInformationCorrectChecked}
                            onChange={() => this.checkCheckbox('informationCorrect')}
                        />
                    </div>
                    {/* <div className={$.row}>
                        <p className={$.newsletter}>{t('payment_newsletterheader')}</p>
                    </div>
                    <div className={$.smallRow}>
                        <div>
                            <input type="checkbox" />
                        </div>
                        <div>
                            {t('payment_newsletter')}
                        </div>
                    </div> */}
                    {errorMessages.length > 0 &&
                        errorMessages.map(error => (
                            <div className={$.error} key={error}>
                                <ErrorMessage text={error} />
                            </div>
                        ))}
                    <div className={$.button}>
                        <Button
                            text={translate('payment_confirm')}
                            icon="/images/icon-lock-white.svg"
                            onClick={this.validate}
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

export default withTranslation()(Payment);
