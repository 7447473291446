import * as React from 'react';
import $ from './FilterSelect.scss';
import { FormSelect, FormListOption } from '..';

export interface Option {
    title: string;
    subtitle?: string;
}

interface Props {
    options: Option[];
    wide: boolean;
    onChange: (value: string) => void;
    selected?: Option;
    id?: string;
}

// eslint-disable-next-line react/prefer-stateless-function
class FilterSelect extends React.Component<Props> {
    public static defaultProps = { wide: false };

    public constructor(props: Props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
    }

    public onChange(id: string) {
        const { onChange } = this.props;
        onChange(id);
    }

    public onChangeMobile(id: string) {
        const { onChange } = this.props;
        onChange(id);
    }

    public render() {
        const { options, wide, selected } = this.props;
        const selectOptions = options.map(option => option.title);
        return (
            <div className={$.select}>
                <div className={$.mobileFilter}>
                    <FormSelect
                        options={selectOptions}
                        onChange={this.onChangeMobile}
                        wide={wide}
                    />
                </div>
                <div className={$.filter}>
                    {options.map((option, index) => (
                            <FormListOption
                                key={index.toString()}
                                title={option.title}
                                subTitle={option.subtitle}
                                name={option.title}
                                id={index.toString()}
                                checked={selected === option}
                                onChange={this.onChange}
                            />
                        ))}
                </div>
            </div>
        );
    }
}

export default FilterSelect;
