import * as React from 'react';

import $ from './RadioInput.scss';

interface Props {
    defaultChecked: boolean;
    id: string;
    label: string | React.ReactNode;
    name: string;
    onChange: (value: boolean) => void;
}

const RadioInput: React.FunctionComponent<Props> = ({
    defaultChecked,
    label,
    id,
    name,
    onChange,
}) => (
    <div className={$.root}>
        <input
            className={$.input}
            type="radio"
            id={id}
            name={name}
            onChange={event => {
                onChange(event.target.checked);
            }}
            defaultChecked={defaultChecked}
        />
        <svg className={$.svg}>
            <circle className={$.inner} cx="9.5" cy="9.5" r="5" />
            <circle className={$.outer} cx="9.5" cy="9.5" r="9" fill="none" />
        </svg>
        <label htmlFor={id} className={$.label}>
            {label}
        </label>
    </div>
);

export default RadioInput;
