/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable lines-between-class-members */
import { getReversedDate } from '~source/utils/utils';

export class Traveler {
    public type: 'traveller' | 'booker';
    public readonly birthday?: string = '';
    public readonly gender?: string = 'M';
    public readonly firstName?: string = '';
    public readonly lastName?: string = '';
    public readonly nationality: string = 'IE';
    public readonly countryCode: string = 'IE';

    public constructor(traveller) {
        this.type = traveller.type;
        this.birthday = traveller.birthday || this.birthday;
        this.gender = traveller.gender || this.gender;
        this.firstName = traveller.firstName || this.firstName;
        this.lastName = traveller.lastName || this.lastName;
        this.nationality = traveller.nationality || this.nationality;
        this.countryCode = traveller.countryCode || this.countryCode;
    }

     // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    toBackendFormat(traveler: Traveler) {
        const {
            birthday,
            firstName,
            lastName,
            gender,
            countryCode,
        } = traveler;

        return {
            birth_date: getReversedDate(birthday),
            first_name: firstName,
            last_name: lastName,
            gender,
            country_code: countryCode,
            nationality: countryCode,
        };
    }
}

export class Booker extends Traveler {
    public readonly type = 'booker';
    public readonly addressLine1: string = '';
    public readonly addressLine2?: string;
    public readonly zipCode?: string;
    public readonly city: string = '';
    public readonly email: string = '';
    public readonly phoneNumber: string = '';

    public constructor(booker) {
        super(booker);
        this.addressLine1 = booker.addressLine1 || this.addressLine1;
        this.addressLine2 = booker.addressLine2;
        this.zipCode = booker.zipCode;
        this.city = booker.city || this.city;
        this.email = booker.email || this.email;
        this.phoneNumber = booker.phoneNumber || this.phoneNumber;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    toBackendFormat(booker: Booker) {
        const {
            addressLine1,
            birthday,
            city,
            addressLine2,
            countryCode,
            email,
            firstName,
            lastName,
            gender,
            phoneNumber,
            zipCode
        } = booker;

        return {
            address_line_1: addressLine1,
            birth_date: getReversedDate(birthday),
            city,
            county: addressLine2,
            country_code: countryCode,
            nationality: countryCode,
            email,
            first_name: firstName,
            last_name: lastName,
            gender,
            phone_number: phoneNumber,
            zip_code: zipCode,
        };
    }
}
