import * as React from 'react';
import classnames from 'classnames';
import { Spinner } from '../../components';
import $ from './Button.scss';

interface Props {
    disabled?: boolean;
    isLoading?: boolean;
    text: string;
    link?: string;
    type?: 'button' | 'submit' | 'reset';
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    white?: boolean;
    icon?: string;
    leftAligned?: boolean;
    className?: string;
}

const Button = (props: Props) => {
    const { link, text, onClick, white, icon, type, disabled, isLoading, leftAligned, className } = props;
    const buttonStyles = classnames([
        $.button,
        white && $.buttonWhite,
        disabled && $.buttonDisabled,
        leftAligned && $.buttonLeft,
        className && className,
    ]);

    if (link) {
        return (
            <a href={link} className={$.button} data-testid="link">
                {text}
            </a>
        );
    }

    return (
        // eslint is disabled because the type is returned with the props and it's default value will always be 'button'
        // eslint-disable-next-line react/button-has-type
        <button
            className={buttonStyles}
            onClick={onClick}
            type={type}
            data-testid="button"
            disabled={disabled || isLoading}
        >
            {isLoading && <Spinner className={$.spinner} />}
            {icon && <img src={icon} alt="Icon" className={$.icon} />}
            {text}
        </button>
    );
};

export default Button;
