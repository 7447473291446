export const nationalities = [
  { value: 'AF', description: 'Afghan' },
  { value: 'AL', description: 'Albanian' },
  { value: 'DZ', description: 'Algerian' },
  { value: 'US', description: 'American' },
  { value: 'MP', description: 'American (Overseas U.S.)' },
  { value: 'VI', description: 'American (Virgin Islands)' },
  { value: 'AD', description: 'Andorran' },
  { value: 'AO', description: 'Angolan' },
  { value: 'AQ', description: 'Antarctic' },
  { value: 'AG', description: 'Antiguan' },
  { value: 'AR', description: 'Argentine' },
  { value: 'AM', description: 'Armenian' },
  { value: 'AU', description: 'Australian' },
  { value: 'CX', description: 'Australian (Christmasisland)' },
  { value: 'CC', description: 'Australian (Cocos-islands)' },
  { value: 'NF', description: 'Australian (Extern territorium)' },
  { value: 'HM', description: 'Australian (Overseas Ind. Ocean)' },
  { value: 'AT', description: 'Austrian' },
  { value: 'AZ', description: 'Azerbaijani' },
  { value: 'BS', description: 'Bahamian' },
  { value: 'BH', description: 'Bahraini' },
  { value: 'BD', description: 'Bangladeshi' },
  { value: 'BB', description: 'Barbadian' },
  { value: 'BY', description: 'Belarusian' },
  { value: 'BE', description: 'Belgium' },
  { value: 'BZ', description: 'Belize' },
  { value: 'BJ', description: 'Beninese' },
  { value: 'BT', description: 'Bhutanese' },
  { value: 'BO', description: 'Bolivian' },
  { value: 'BA', description: 'Bosnian' },
  { value: 'BW', description: 'Botswanan' },
  { value: 'BR', description: 'Brazilian' },
  { value: 'GB', description: 'British' },
  { value: 'KY', description: 'British (British Overseas territorium)' },
  { value: 'AI', description: 'British (British Overseas)' },
  { value: 'FK', description: 'British (Falklandislands)' },
  { value: 'GI', description: 'British (Gibraltar)' },
  { value: 'BM', description: 'British (Overseas Atl. Ocean)' },
  { value: 'PN', description: 'British (Overseas)' },
  { value: 'MS', description: 'British (Overseas)' },
  { value: 'LC', description: 'British (Saint Lucia)' },
  { value: 'VG', description: 'British (Virgin Islands)' },
  { value: 'BN', description: 'Bruneian' },
  { value: 'BG', description: 'Bulgarian' },
  { value: 'BF', description: 'Burkinese' },
  { value: 'BU', description: 'Burmese' },
  { value: 'MM', description: 'Burmese' },
  { value: 'BI', description: 'Burundian' },
  { value: 'KH', description: 'Cambodian' },
  { value: 'CM', description: 'Cameroonian' },
  { value: 'CA', description: 'Canadian' },
  { value: 'CV', description: 'Cape Verdean' },
  { value: 'CF', description: 'Central-African' },
  { value: 'TD', description: 'Chadian' },
  { value: 'CL', description: 'Chilean' },
  { value: 'CN', description: 'Chinese' },
  { value: 'MO', description: 'Chinese (Macau)' },
  { value: 'CO', description: 'Colombian' },
  { value: 'KM', description: 'Comorian' },
  { value: 'CG', description: 'Congolese' },
  { value: 'CK', description: 'Cookisland' },
  { value: 'CR', description: 'Costa Rican' },
  { value: 'HR', description: 'Croatian' },
  { value: 'CU', description: 'Cuban' },
  { value: 'CY', description: 'Cypriot' },
  { value: 'CZ', description: 'Czech' },
  { value: 'DK', description: 'Danish' },
  { value: 'DJ', description: 'Djiboutian' },
  { value: 'DO', description: 'Dominican' },
  { value: 'NL', description: 'Dutch' },
  { value: 'AW', description: 'Dutch (Aruba)' },
  { value: 'AN', description: 'Dutch (Netherlands Antilles)' },
  { value: 'TP', description: 'East-Timorese' },
  { value: 'EC', description: 'Ecuadorean' },
  { value: 'EG', description: 'Egyptian' },
  { value: 'GQ', description: 'Equatorial Guinean' },
  { value: 'ER', description: 'Eritrean' },
  { value: 'EE', description: 'Estonian' },
  { value: 'ET', description: 'Ethiopian' },
  { value: 'FO', description: 'Faroese' },
  { value: 'FJ', description: 'Fijian' },
  { value: 'PH', description: 'Filipijnse' },
  { value: 'FI', description: 'Finnish' },
  { value: 'FR', description: 'French' },
  { value: 'GF', description: 'French (French-Guyana)' },
  { value: 'PF', description: 'French (French-Polynesia)' },
  { value: 'GP', description: 'French (Guadeloupe)' },
  { value: 'MQ', description: 'French (Martinique)' },
  { value: 'RE', description: 'French (Overseas)' },
  { value: 'NC', description: 'French (Overseas)' },
  { value: 'GA', description: 'Gabonese' },
  { value: 'GM', description: 'Gambian' },
  { value: 'GE', description: 'Georgian' },
  { value: 'DE', description: 'German' },
  { value: 'GH', description: 'Ghanaian' },
  { value: 'GR', description: 'Greek' },
  { value: 'GL', description: 'Greenlandic' },
  { value: 'GD', description: 'Grenadian' },
  { value: 'GU', description: 'Guamanian' },
  { value: 'GT', description: 'Guatemalan' },
  { value: 'GG', description: 'Guernseyse' },
  { value: 'GW', description: 'Guinea-Bissau' },
  { value: 'GN', description: 'Guinean' },
  { value: 'GY', description: 'Guyanese' },
  { value: 'HT', description: 'Haitian' },
  { value: 'HN', description: 'Honduran' },
  { value: 'HK', description: 'Hongkong' },
  { value: 'HU', description: 'Hungarian' },
  { value: 'IS', description: 'Icelandic' },
  { value: 'IN', description: 'Indian' },
  { value: 'ID', description: 'Indonesian' },
  { value: 'IR', description: 'Iranian' },
  { value: 'IQ', description: 'Iraqi' },
  { value: 'IE', description: 'Irish' },
  { value: 'IL', description: 'Israeli' },
  { value: 'IT', description: 'Italian' },
  { value: 'CI', description: 'Ivorian' },
  { value: 'JM', description: 'Jamaican' },
  { value: 'JP', description: 'Japanese' },
  { value: 'JE', description: 'Jerseyse' },
  { value: 'JO', description: 'Jordan' },
  { value: 'KZ', description: 'Kazakh' },
  { value: 'KE', description: 'Kenyan' },
  { value: 'KI', description: 'Kiribati' },
  { value: 'KO', description: 'Kosovo' },
  { value: 'KW', description: 'Kuwaiti' },
  { value: 'KG', description: 'Kyrgyz' },
  { value: 'LA', description: 'Laotian' },
  { value: 'LV', description: 'Latvian' },
  { value: 'LB', description: 'Lebanese' },
  { value: 'LS', description: 'Lesotho' },
  { value: 'LR', description: 'Liberian' },
  { value: 'LY', description: 'Libyan' },
  { value: 'LI', description: 'Liechtenstein' },
  { value: 'LT', description: 'Lithuanian' },
  { value: 'LU', description: 'Luxembourg' },
  { value: 'MK', description: 'Macedonian' },
  { value: 'MG', description: 'Madagascan' },
  { value: 'MW', description: 'Malawian' },
  { value: 'MY', description: 'Malaysian' },
  { value: 'MV', description: 'Maldivian' },
  { value: 'ML', description: 'Malian' },
  { value: 'MT', description: 'Maltese' },
  { value: 'MH', description: 'Marshall Islander' },
  { value: 'MR', description: 'Mauritanian' },
  { value: 'MU', description: 'Mauritian' },
  { value: 'MX', description: 'Mexican' },
  { value: 'FM', description: 'Micronesian' },
  { value: 'MD', description: 'Moldovan' },
  { value: 'MC', description: 'Monegasque' },
  { value: 'MN', description: 'Mongolian' },
  { value: 'ME', description: 'Montenegrin' },
  { value: 'MA', description: 'Moroccan' },
  { value: 'MZ', description: 'Mozambican' },
  { value: 'NA', description: 'Namibian' },
  { value: 'NR', description: 'Nauru Tibetan' },
  { value: 'NP', description: 'Nepalese' },
  { value: 'NZ', description: 'New Zealand' },
  { value: 'TK', description: 'New Zealand (Overseas)' },
  { value: 'NI', description: 'Nicaraguan' },
  { value: 'NG', description: 'Nigerian' },
  { value: 'NE', description: 'Nigerien' },
  { value: 'NU', description: 'Niuese' },
  { value: 'KP', description: 'North Korean' },
  { value: 'NO', description: 'Norwegian' },
  { value: 'BV', description: 'Norwegian (Bouvet-island)' },
  { value: 'SJ', description: 'Norwegian (Spitsbergen en Jan Mayen)' },
  { value: 'OM', description: 'Omani' },
  { value: 'PK', description: 'Pakistani' },
  { value: 'PW', description: 'Palau Tibetan' },
  { value: 'PS', description: 'Palestinian' },
  { value: 'PA', description: 'Panamanian' },
  { value: 'PG', description: 'Papua New Guinea' },
  { value: 'PY', description: 'Paraguayan' },
  { value: 'PE', description: 'Peruvian' },
  { value: 'PL', description: 'Polish' },
  { value: 'PT', description: 'Portuguese' },
  { value: 'PR', description: 'Puerto Rican' },
  { value: 'QA', description: 'Qatari' },
  { value: 'RO', description: 'Romanian' },
  { value: 'RU', description: 'Russian' },
  { value: 'RW', description: 'Rwandan' },
  { value: 'KN', description: 'Saint Kitts-Nevis' },
  { value: 'VC', description: 'Saint Vincent and Grenadine' },
  { value: 'SV', description: 'Salvadorean' },
  { value: 'WS', description: 'Samoan' },
  { value: 'SM', description: 'San Marinese' },
  { value: 'ST', description: 'Sao Tomean and Principean' },
  { value: 'SA', description: 'Saudi Arabian' },
  { value: 'SN', description: 'Senegalese' },
  { value: 'CS', description: 'Serbian' },
  { value: 'SC', description: 'Seychellois' },
  { value: 'SL', description: 'Sierra Leonian' },
  { value: 'SG', description: 'Singaporean' },
  { value: 'SK', description: 'Slovak' },
  { value: 'SI', description: 'Slovenian' },
  { value: 'SB', description: 'Solomon Islander' },
  { value: 'SO', description: 'Somali' },
  { value: 'ZA', description: 'South-African' },
  { value: 'KR', description: 'South-Korean' },
  { value: 'ES', description: 'Spanish' },
  { value: 'LK', description: 'Sri Lankan' },
  { value: 'SD', description: 'Sudanese' },
  { value: 'SR', description: 'Surinamese' },
  { value: 'SZ', description: 'Swazi' },
  { value: 'SE', description: 'Swedish' },
  { value: 'CH', description: 'Swiss' },
  { value: 'SY', description: 'Syrian' },
  { value: 'TJ', description: 'Tadjik' },
  { value: 'TW', description: 'Taiwanese' },
  { value: 'TZ', description: 'Tanzanian' },
  { value: 'TH', description: 'Thai' },
  { value: 'TG', description: 'Togolese' },
  { value: 'TO', description: 'Tongan' },
  { value: 'TT', description: 'Trinidadian and Tobagan' },
  { value: 'TN', description: 'Tunisian' },
  { value: 'TR', description: 'Turkish' },
  { value: 'TM', description: 'Turkmen' },
  { value: 'TV', description: 'Tuvaluan' },
  { value: 'UG', description: 'Ugandan' },
  { value: 'UA', description: 'Ukrainian' },
  { value: 'UY', description: 'Uruguayan' },
  { value: 'UZ', description: 'Uzbek' },
  { value: 'AE', description: 'VAE-nationality' },
  { value: 'VU', description: 'Vanuatuan' },
  { value: 'VA', description: 'Vatican' },
  { value: 'VE', description: 'Venezuelan' },
  { value: 'VN', description: 'Vietnamese' },
  { value: 'EH', description: 'Western Sahara' },
  { value: 'YE', description: 'Yemeni' },
  { value: 'YU', description: 'Yugoslav' },
  { value: 'ZR', description: 'Zaïrean' },
  { value: 'ZM', description: 'Zambian' },
  { value: 'ZN', description: 'Zanzibarese' },
  { value: 'ZW', description: 'Zimbabwean' }
];
