import { AvailabilityBaseAccommodationRateEntity, AvailabilityBaseAccommodationRate } from './AvailabilityBaseAccommodationRate';

/* eslint-disable lines-between-class-members */

export interface AvailabilityBaseAccommodationEntity {
    ticket_id: string;
    price_pp: string;
    accommodation: AvailabilityBaseAccommodationRateEntity;
}

export class AvailabilityBaseAccommodation {
    public readonly ticketId: string;
    public readonly price: number;
    public readonly accommodation: AvailabilityBaseAccommodationRate;

    public constructor(accommodation: AvailabilityBaseAccommodationEntity) {
        this.ticketId = accommodation.ticket_id;
        this.price = +accommodation.price_pp;
        if (accommodation.accommodation != null) {
            this.accommodation = new AvailabilityBaseAccommodationRate(accommodation.accommodation);
        }
    }
}
