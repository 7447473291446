import * as React from 'react';
import moment from 'moment-timezone';
import { withTranslation, WithTranslation } from 'react-i18next';
import classnames from 'classnames';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import $ from './HotelDetail.scss';
import { Button, CloseButton, Map, IconLabel, Stars } from '..';
import { EventDetail } from '~source/core/models/EventDetail';
import { Accommodation } from '~source/core/models/Accommodation';
import { fetchAccommodationDetail } from '~source/core/services/accommodations';
import { IconLabelType } from '../IconLabel/IconLabel';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    accommodation?: Accommodation;
    onClick: () => void;
}

interface State {
    accommodationDetail?: Accommodation;
}

function getFacilityValue(value) {
    const timeRegex = new RegExp(/(\d{1,2}:\d{1,2}:\d{1,2})/);
    const isTime = timeRegex.test(value);

    if (isTime) {
        return moment(value, 'HH:mm:ss').format('HH:mm');
    }
    return value;
}

function renderFacility(facility: {
    [id: string]: string | boolean | null;
}) {
    if (!facility.value) {
        return null;
    }
    return (
    <li key={`facility${facility.name}`}>
        <IconLabel type={IconLabelType.OrangeCheckmark}>
        <span className={$.blackText}>
            {facility.name}
        </span>
        {facility.value !== true && (
            <span className={$.lightText}>
                {getFacilityValue(facility.value)}
            </span>
        )}
        </IconLabel>
    </li>
    );
}

// eslint-disable-next-line react/prefer-stateless-function
class HotelDetail extends React.Component<Props, State> {
    private rootRef = React.createRef<HTMLDivElement>();

    public static defaultProps = {
        rooms: 1,
        adults: 1,
        children: 0,
    };

    public constructor(props) {
        super(props);

        this.state = {};
        this.handleSetup = this.handleSetup.bind(this);
        this.handleMorePhotosClick = this.handleMorePhotosClick.bind(this);
    }

    public componentDidUpdate() {
        const { accommodation } = this.props;
        if (!accommodation) {
            return;
        }

        const { accommodationDetail } = this.state;
        disableBodyScroll(this.rootRef.current);

        if (!accommodationDetail || accommodationDetail.id !== accommodation.id) {
            this.loadHotel();
        }
    }

    public componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    public handleSetup() {
        const { onClick } = this.props;

        clearAllBodyScrollLocks();
        onClick();
    }

    private async loadHotel() {
        const { accommodation } = this.props;
        if (!accommodation) {
            return;
        }

        const accommodationDetail = await fetchAccommodationDetail(accommodation.id);

        this.setState({ accommodationDetail });
    }

    private handleMorePhotosClick() {
        if (!this.rootRef || !this.rootRef.current) return;

        const anchorElement = this.rootRef.current.querySelector('[data-anchor="#morePhotos"]');

        if (!anchorElement) return;

        const { top } = anchorElement.getBoundingClientRect();

        this.rootRef.current.scrollTop = top;
    }

    public render() {
        const { t, accommodation, eventDetail } = this.props;
        const { accommodationDetail } = this.state;

        if (!accommodation || !accommodationDetail || !accommodationDetail.content || !accommodationDetail.content.facilities) {
            return <React.Fragment />;
        }

        const { images } = accommodationDetail;
        const topImages = images.slice(0, 2);
        const image = accommodation.image || '/images/no-hotel-image.jpg';

        return (
            <React.Fragment>
                <button type="button" className={$.background} onClick={this.handleSetup} />
                <article className={$.roomLayout} ref={this.rootRef}>
                    <h1 className={$.header}>{accommodationDetail.name}</h1>
                    {(accommodation.stars !== 0 && accommodation.stars !== '0') && (
                        <div className={$.stars}><Stars stars={accommodation.stars} big /></div>
                    )}
                    <div className={$.topRow}>
                        <div className={$.topImages}>
                            {images && (
                                <>
                                    {topImages.map(topImage => (
                                        <div className={$.topImageWrap}>
                                            <img className={$.topImage} src={topImage.small} alt={accommodation.name} />
                                            <div className={$.spacing} />
                                        </div>
                                    ))}
                                    {images.length >= 2 && (
                                        <button className={$.morePhotos} onClick={this.handleMorePhotosClick} type="button">{t('hotel_details_more_photos')}</button>
                                    )}
                                </>
                            )}
                            {!images && (
                                <>
                                    <img className={$.hotelImage} src={image} alt={accommodation.name} />
                                    <div className={$.spacing} />
                                </>
                            )}
                        </div>
                        <div className={$.spacing} />
                        <div className={$.map}>
                            <Map accomodations={[accommodation]} eventDetail={eventDetail} height="23.125rem" />
                            <p className={$.legend}>
                                <span className={$.legendIcon} /> {t('city_centre')}
                            </p>
                        </div>
                    </div>
                    <div className={$.detailText}>
                        <p className={$.text}>{accommodationDetail.content.description}</p>
                    </div>
                    <div className={$.features}>
                        <div>
                            <p className={$.optionTitle}>{t('hoteldetail_roomamenities')}</p>
                            <ul>
                                {accommodationDetail.content.facilities.general.map(name => renderFacility(name))}
                            </ul>
                        </div>
                        <div>
                            <p className={$.optionTitle}>{t('hoteldetail_facilities')}</p>
                            <ul>
                                {accommodationDetail.content.facilities.things.map(name => renderFacility(name))}
                            </ul>
                            <p className={$.optionTitle}>{t('hoteldetail_meals')}</p>
                            <ul>
                                {accommodationDetail.content.facilities.meals.map(name => renderFacility(name))}
                            </ul>
                            <ul>
                                {accommodationDetail.content.facilities.catering.map(name => renderFacility(name))}
                            </ul>
                        </div>
                    </div>
                    {images && images.length > 2 && (
                        <div className={classnames([$.topRow, $.bottomImages])} data-anchor="#morePhotos">
                            {images.map(hotelImage => (
                                <img className={$.bottomImage} src={hotelImage.small} alt={accommodation.name} />
                            ))}
                        </div>
                    )}
                    <div className={$.closeButtonWrap}>
                        <Button onClick={this.handleSetup} text={t('hotel_details_close')} />
                    </div>
                    <CloseButton onClick={this.handleSetup} />
                </article>
            </React.Fragment>
        );
    }
}

export default withTranslation()(HotelDetail);
