import * as React from 'react';
import $ from './WhyEquipo.scss';
import Popover from '~source/view/components/Popover';
import MobileContext from '~source/view/context/MobileContext';

export type Item = {
    title: string;
    popover: string;
};

interface Props {
    title: string;
    popoverText?: string;
    values: Item[];
    icon: { [key: string]: string };
}

const WhyEquipo = (props: Props) => {
    const { title, values, icon } = props;
    const isMobile = React.useContext(MobileContext);

    return (
        <section className={$.base} data-testid="WhyEquipo">
            <h3 className={$.title}>{title}</h3>
            <ul className={$.list}>
                {values.map(item => (
                    <Popover key={item.title} text={item.popover} placement="bottomLeft" arrow={isMobile}>
                        <li className={$.listItem}>
                            <img src={icon.url} alt="" title="" className={$.icon} />
                            <span>{item.title}</span>
                        </li>
                    </Popover>
                ))}
            </ul>
        </section>
    );
};

export default WhyEquipo;
