import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './Social.scss';
import * as Cache from '~source/core/services/cache';

interface Props extends WithTranslation {
}

interface State {
    message: string;
}

class Social extends React.Component<Props, State> {
    public constructor(props) {
        super(props);

        const eventDetail = Cache.getEventDetail();
        this.state = { message: `Reis geboekt! Ik ga naar ${eventDetail.name}! #equipovoetbalreizen` };
        this.mail = this.mail.bind(this);
        this.tweet = this.tweet.bind(this);
        this.facebook = this.facebook.bind(this);
    }

    public mail() {
        const { message } = this.state;
        window.open(`mailto:?body=${encodeURIComponent(message)}`);
    }

    public tweet() {
        const { message } = this.state;
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`, '_blank');
    }

    public facebook() {
        const { message } = this.state;
    }

    public render() {
        const { message } = this.state;

        return (
            <div className={$.social}>
                <div className={$.content}>
                    <div className={$.image}><img src="images/temp/match.png" alt="Event" /></div>
                    <div className={$.text}>{message}</div>
                </div>
                <div className={$.buttons}>
                    <button className={$.facebook} type="button" onClick={this.facebook}>DEEL OP FACEBOOK</button>
                    <button className={$.twitter} type="button" onClick={this.tweet}>DEEL OP TWITTER</button>
                    <button className={$.mail} type="button" onClick={this.mail}>DEEL VIA MAIL</button>
                </div>
            </div>
        );
    }
}

export default withTranslation()(Social);
