import { fetchUrl } from './fetchUrl';
import { Config, ConfigEntity } from '../models/Config';

export async function fetchConfig() {
    return fetchUrl<ConfigEntity>({
        endpoint: '/v1/config',
        method: 'GET',
        getLocale: true,
    }).then(config => new Config(config));
}
