import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HotelItem, IconLabel } from '..';
import { IconLabelType } from '../IconLabel/IconLabel';
import { Accommodation } from '~source/core/models/Accommodation';
import $ from './HotelQuickView.scss';

interface Props {
    accommodation?: Accommodation;
    selectedAccommodation?: Accommodation;
    breakfast: boolean;
    openDetail: (accommodation: Accommodation) => void;
    onChange: (accomodation: Accommodation) => void;
    closeQuickView: () => void;
    large?: boolean;
}

export default function HotelQuickView(props: Props) {
    const { accommodation, selectedAccommodation, breakfast, openDetail, onChange, large, closeQuickView } = props;
    const { t } = useTranslation();

    if (!accommodation) {
        return null;
    }

    return (
        <section className={$.wrap}>
            <button type="button" className={$.backButton} onClick={closeQuickView}>
                <IconLabel type={IconLabelType.Back}>
                    {t('hotel_pick_other_hotel')}
                </IconLabel>
            </button>
            <HotelItem
                accommodation={accommodation}
                selected={selectedAccommodation && accommodation.id === selectedAccommodation.id}
                breakfast={breakfast}
                key={accommodation.id}
                onChange={() => onChange(accommodation)}
                openDetail={openDetail}
                large={large}
            />
        </section>
    );
}
