/* eslint-disable lines-between-class-members */

export interface BaseAccommodationEntity {
    accommodation_id: string;
    ticket_id: string;
    price_pp: string;
}

export class BaseAccommodation {
    public readonly accommodationId: string;
    public readonly ticketId: string;
    public readonly price: number;

    public constructor(accommodation: BaseAccommodationEntity) {
        this.accommodationId = accommodation.accommodation_id;
        this.ticketId = accommodation.ticket_id;
        this.price = +accommodation.price_pp;
    }
}
