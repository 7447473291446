/* eslint-disable lines-between-class-members */
import moment from 'moment-timezone';
import { AvailabilityBaseAccommodation, AvailabilityBaseAccommodationEntity } from './AvailabilityBaseAccommodation';
import {
    AvailabilityAccommodationEntity,
    AvailabilityAccommodation,
} from './AvailabilityAccommodation';
import { Ticket, TicketEntity } from './Ticket';

export interface AvailabilityEntity {
    base_accommodation_package: AvailabilityBaseAccommodationEntity;
    accommodations: AvailabilityAccommodationEntity[];
    tickets: TicketEntity[];
    expiry: string;
}

export class Availability {
    public readonly baseAccomodationPackage: AvailabilityBaseAccommodation;
    public readonly accomodations: AvailabilityAccommodation[];
    public readonly tickets: Ticket[];
    public readonly expiry: number;

    public constructor(availability: AvailabilityEntity) {
        this.baseAccomodationPackage = new AvailabilityBaseAccommodation(
            availability.base_accommodation_package
        );
        this.accomodations = availability.accommodations.map(
            accomodation => new AvailabilityAccommodation(accomodation)
        );
        this.tickets = availability.tickets.map(
            ticket => new Ticket(ticket)
        );
        // Availability is about 60 minutes. To prevent the availability expiring
        // while finalizing an order, manually expire the availability after 55 minutes
        this.expiry = moment(availability.expiry).subtract(5, 'minutes').local().unix();
    }
}
