import * as EventDetail from '../models/EventDetail';
import { fetchUrl } from './fetchUrl';
import { Accommodation, AccommodationEntity } from '../models/Accommodation';

export async function fetchEventDetail(eventId: string) {
    return fetchUrl<EventDetail.EventDetailEntity>({
        endpoint: `/v1/events/${eventId}`,
        method: 'GET',
    }).then(eventDetail => new EventDetail.EventDetail(eventDetail));
}

export async function fetchEventAccomodations(eventId: string) {
    return fetchUrl<AccommodationEntity[]>({
        endpoint: `/v1/events/${eventId}/accommodations`,
        method: 'GET',
    }).then(accommodations => accommodations.map(accommodation => new Accommodation(accommodation)));
}
