import * as React from 'react';
import classnames from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import $ from './FormInput.scss';

export enum FormInputSize {
    Small,
    ActionCode,
    Medium,
    Large,
    PostCode,
}

interface Props extends WithTranslation {
    name?: string;
    size: FormInputSize;
    // options: string[];
    // wide: boolean;
    onBlur?: React.EventHandler<React.FocusEvent<HTMLInputElement>>;
    onChange?: React.EventHandler<React.ChangeEvent<HTMLInputElement>>;
    pattern?: string;
    defaultValue?: string;
    placeholder?: string;
    showErrorMessage?: boolean;
    // optional?: boolean;
    required?: boolean;
    requiredErrorMessage?: string;
    // selected?: string;
    // id?: string;
}

// eslint-disable-next-line react/prefer-stateless-function
const FormInput: React.FunctionComponent<Props> = ({
    name,
    size,
    onBlur,
    onChange = () => {},
    pattern,
    defaultValue,
    placeholder,
    required,
    t,
    requiredErrorMessage,
    showErrorMessage = false,
}) => {
    const [value, setValue] = React.useState(defaultValue);
    const [isPristine, setIsPristine] = React.useState(true);
    const [showError, setShowError] = React.useState<boolean>(showErrorMessage);
    let className = '';

    const handleOnBlur = event => {
        setIsPristine(false);
        if (onBlur && typeof onBlur === 'function') {
            onBlur(event);
        }
    };

    // eslint-disable-next-line default-case
    switch (size) {
        case FormInputSize.Small:
            className = $.small || '';
            break;
        case FormInputSize.ActionCode:
            className = $.actionCode || '';
            break;
        case FormInputSize.Medium:
            className = $.medium || '';
            break;
        case FormInputSize.Large:
            className = $.large || '';
            break;
        case FormInputSize.PostCode:
            className = $.postcode || '';
            break;
    }

    React.useEffect(() => {
        if (value) {
            setShowError(false);
        }
    }, [value]);

    React.useEffect(() => {
        setShowError(showErrorMessage);
    }, [showErrorMessage]);

    return (
        <React.Fragment>
            {name && <p className={$.fieldLabel}>{name} {!required && <span className={$.optional}>({t('optional')})</span>}</p>}
            <input
                className={classnames($.field, className, isPristine && $.isPristine, showError && $.invalid)}
                type="text"
                onBlur={handleOnBlur}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e);
                }}
                pattern={pattern}
                defaultValue={defaultValue}
                placeholder={placeholder}
                required={required}
            />
            {requiredErrorMessage && ((!isPristine && !value) || showError) && (
                <div className={$.errorMessage}>{requiredErrorMessage}</div>
            )}
        </React.Fragment>
    );
};

export default withTranslation()(FormInput);
