import * as Cache from '~source/core/services/cache';

/* eslint-disable lines-between-class-members */
export interface OrderEntity {
    id: string;
    secret: string;
}

export class Order {
    public readonly id: string;
    public readonly secret: string;

    public constructor(order: OrderEntity) {
        this.id = order.id;
        this.secret = order.secret || Cache.getOrder().secret;
    }
}
