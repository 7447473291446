/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import classnames from 'classnames';
import MobileContext from '~source/view/context/MobileContext';
import $ from './Popover.scss';

interface Props {
    text: string;
    placement?: 'bottom' | 'right' | 'bottomLeft';
    arrow?: boolean;
    children: React.ReactNode;
    closable?: boolean;
}

const Tooltip: React.FunctionComponent<Props> = ({
    text,
    children,
    arrow,
    placement = 'bottom',
}) => {
    const [show, setShow] = React.useState(false);
    const [adjustedPlacement, setAdjustedPlacement] = React.useState<string | undefined>();
    const hasMouseEnterBeenFired = React.useRef(false);
    const ref = React.useRef<HTMLDivElement | null>(null);
    let current: any;

    const close = () => {
        setShow(false);
    };

    const handleClickOutside = (e: MouseEvent) => {
        if (current && current.contains(e.target)) {
            return;
        }
        document.removeEventListener('mousemove', handleClickOutside);
        close();
    };

    const open = (e) => {
        // Prevent window click, needed to trigger outside action
        e.stopPropagation();

        current = ref && ref.current;
        document.addEventListener('mousemove', handleClickOutside);

        if (ref.current) {
            setShow(true);
            // Prevent tooltip from leaving the window
            const isTablet = window.innerWidth < 1360;

            if (isTablet) {
                const refPosition = ref.current.getBoundingClientRect();

                // Logic to determine where to place the popover to prevent it from appearing outside of the screen
                if (refPosition.left > 180) {
                    setAdjustedPlacement('bottomRight');
                    return;
                }
            }
            setAdjustedPlacement(undefined);
        }
    };

    const handleClick = (e) => {
        // Prevent that touch + mouse devices fire onMouseEnter and onClick at the same time (causes popup not to open)
        if (hasMouseEnterBeenFired.current) {
            hasMouseEnterBeenFired.current = false;
            return;
        }
        if (show) {
            setShow(false);
            return;
        }
        open(e);
    };

    const handleMouseEnter = (e) => {
        if (show) {
            setShow(false);
            return;
        }
        hasMouseEnterBeenFired.current = true;

        open(e);
    };

    const events = {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: close,
        onClick: handleClick,
    };
    const position = adjustedPlacement || placement;

    React.useEffect(() => {
        if (show) {
            window.addEventListener('resize', close);
            window.addEventListener('scroll', close);
        }
        return () => {
            window.removeEventListener('resize', close);
            window.removeEventListener('scroll', close);
            document.removeEventListener('mousemove', handleClickOutside);
        };
    }, [show]);

    return (
        <div ref={ref} className={$.wrap}>
            <button
                className={$.root}
                type="button"
                {...events}
            >
                {children}
            </button>

            {show && text && (
                <div className={classnames($.tooltip, $[position])}>
                    <button className={$.close} type="button" onClick={close}>
                        <img src="/images/icon-close.svg" alt="x" />
                    </button>
                    {arrow && <span className={classnames($.tooltipArrow, $[position])} />}
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
