import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Page, TakeOverMessage, Thanks } from '~source/view/components';
import { queryStringToObject } from '~source/utils/utils';
import * as Cache from '~source/core/services/cache';

interface Props extends WithTranslation {}

const Completed: React.FunctionComponent<Props & RouteComponentProps> = ({ t, history }) => {
    const eventDetail = Cache.getEventDetail();
    const { location } = history;

    // When user routes to this page and no event is found or there are no order parameters set in the url, route to 404
    if (!eventDetail || !location.search) {
        history.push('/404');
        return null;
    }

    const { status } = queryStringToObject(location.search);
    const isStatusPaid = status === 'success';

    if (!isStatusPaid) {
        Cache.resetOrderButKeepCustomerData();
    }

    const handleOnCallToActionClick = () => {
        history.push(`/ticket/${eventDetail.id}`);
    };

    return (
        <Page step={4} eventDetail={eventDetail} mustHideTravelDetails={!isStatusPaid}>
            {!isStatusPaid && (
                <TakeOverMessage
                    callToActionLabel={t('payment_failed_call_to_action_label')}
                    imageSrc="/images/failed.jpg"
                    imageAlt={t('payment_failed_image_alt_text')}
                    onCallToActionClick={handleOnCallToActionClick}
                    description={t('payment_failed_description')}
                    subTitle={t('payment_failed_sub_title')}
                    title={t('payment_failed_title')}
                />
            )}
            {isStatusPaid && <Thanks />}
        </Page>
    );
};

export default withRouter(withTranslation()(Completed));
