import * as React from 'react';
import classnames from 'classnames';
import $ from './ToggleSlider.scss';

interface Props {
    title?: string;
    active: boolean;
    setActive: (active: boolean) => void;
}

export default function ToggleSlider(props: Props) {
    const { title, active, setActive } = props;

    return (
        <div className={$.wrap}>
            {title && <span className={$.title}>{title}</span>}
            <button className={$.slider} type="button" onClick={() => setActive(!active)}>
                <div className={classnames([$.sliderThumb, active && $.sliderThumbActive])} />
            </button>
        </div>
    );
}
