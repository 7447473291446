import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import $ from './DetailsTraveler.scss';
import { nationalities } from '../../../constants/nationalities';
import { FormInput, FormSelect, FormDateSelect, RadioInput } from '~source/view/components';
import { FormInputSize } from '../FormInput/FormInput';
import * as Cache from '../../../core/services/cache';
import { checkIfValidDate, seperateDate } from '~source/utils/utils';

interface Props {
    booker: boolean;
    callback: (travelerDetails: { [id: string]: string | undefined }) => void;
    cache: {
        firstName?: string;
        lastName?: string;
        birthday?: string;
        gender?: string;
        addressLine1?: string;
        zipCode?: string;
        addressLine2?: string;
        city?: string;
        email?: string;
        phoneNumber?: string;
        nationality?: string;
        countryCode?: string;
    }
    eventDate: Date;
    extended: boolean;
    missingFields: string[];
    number: number;
}

const nationalityOptions = nationalities.reduce(
    (values, list) => values.concat(list.description),
    []
);

function numberToLocaleString(num: number): string | null {
    if (!num || Number.isNaN(Number(num))) return null;

    const config = Cache.getConfig();

    return num.toLocaleString(config.locale.replace('_', '-'), { minimumIntegerDigits: 2, useGrouping: false });
}

// eslint-disable-next-line react/prefer-stateless-function
const DetailsTraveler: React.FunctionComponent<Props> = ({
    booker = false,
    cache,
    callback = () => {},
    eventDate,
    extended = false,
    missingFields,
    number,
}) => {
    const { t } = useTranslation();
    const getNationality = id => {
        const foundNationality = nationalities.find(n => n.value === id);

        return (foundNationality && foundNationality.description) || undefined;
    };

    const defaultState = {
        countryCode: cache && cache.countryCode ? cache.countryCode : 'IE',
        gender: cache && cache.gender ? cache.gender : 'M',
        addressLine1: cache && cache.addressLine1,
        addressLine2: cache && cache.addressLine2,
        birthday: cache && cache.birthday,
        city: cache && cache.city,
        email: cache && cache.email,
        firstName: cache && cache.firstName,
        lastName: cache && cache.lastName,
        phoneNumber: cache && cache.phoneNumber,
        zipCode: cache && cache.zipCode,
    };

    const isInitialMount = React.useRef(true);
    const [addressLine1, setAddressLine1] = React.useState<string | undefined>(defaultState.addressLine1);
    const [addressLine2, setAddressLine2] = React.useState<string | undefined>(defaultState.addressLine2);
    const [countryCode, setCountryCode] = React.useState<string | undefined>(defaultState.countryCode);
    const [birthday, setBirthday] = React.useState<string | undefined>(defaultState.birthday);
    const [city, setCity] = React.useState<string | undefined>(defaultState.city);
    const [email, setEmail] = React.useState<string | undefined>(defaultState.email);
    const [firstName, setFirstName] = React.useState<string | undefined>(defaultState.firstName);
    const [gender, setGender] = React.useState<string | undefined>(defaultState.gender);
    const [lastName, setLastName] = React.useState<string | undefined>(defaultState.lastName);
    const [phoneNumber, setphoneNumber] = React.useState<string | undefined>(defaultState.phoneNumber);
    const [zipCode, setZipCode] = React.useState<string | undefined>(defaultState.zipCode);

    const handleDateOfBirthChange = (id: number, day: number, month: number, year: number) => {
        if (!checkIfValidDate(day, month, year)) {
            setBirthday(undefined);
        } else {
            setBirthday([numberToLocaleString(day), numberToLocaleString(month), year].join('-'));
        }
    };

    const setValueForName = (name: string, value: string | undefined) => {
        switch (name) {
            case 'address_line_1':
                setAddressLine1(value);
                break;
            case 'birth_date':
                setBirthday(value);
                break;
            case 'city':
                setCity(value);
                break;
            case 'county':
                setAddressLine2(value);
                break;
            case 'country_code':
                setCountryCode(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'first_name':
                setFirstName(value);
                break;
            case 'last_name':
                setLastName(value);
                break;
            case 'gender':
                setGender(value);
                break;
            case 'phone_number':
                setphoneNumber(value);
                break;
            case 'zip_code':
                setZipCode(value);
                break;
            default:
                break;
        }
    };

    const handleFormFieldChange = ({ name = '', value = '' }) => {
        setValueForName(name, value);
    };

    const isFieldMissing = (fieldName: string) => missingFields.indexOf(fieldName) >= 0;

    // When value of form changes
    React.useEffect(() => {
        // Don't call the callback on mount because it will overwrite the cache
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
         }
        callback({
            addressLine1,
            addressLine2,
            countryCode,
            birthday,
            city,
            email,
            firstName,
            gender,
            lastName,
            phoneNumber,
            zipCode,
        });
        /* eslint-disable @typescript-eslint/camelcase */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        addressLine1,
        addressLine2,
        countryCode,
        birthday,
        city,
        email,
        firstName,
        gender,
        lastName,
        phoneNumber,
        zipCode,
    ]);

    const { day, month, year } = seperateDate(birthday);

    return (
        <section>
            <h3 className={$.header}>
                {booker
                    ? t('details_customerdetails')
                    : t('details_traveler', { traveler: number + 1 })}
            </h3>
            <div className={$.whiteBlock}>
                <section className={$.formSection}>
                    {!booker && <h4 className={$.title}>{t('details_passport')}</h4>}
                    <div className={$.sex}>
                        <RadioInput
                            label={t('details_male')}
                            id={`male${number}`}
                            name={`sex${number}`}
                            defaultChecked={gender === 'M'}
                            onChange={() => {
                                handleFormFieldChange({
                                    name: 'gender',
                                    value: 'M',
                                });
                            }}
                        />
                        <RadioInput
                            label={t('details_female')}
                            id={`female${number}`}
                            name={`sex${number}`}
                            defaultChecked={gender === 'F'}
                            onChange={() => {
                                handleFormFieldChange({
                                    name: 'gender',
                                    value: 'F',
                                });
                            }}
                        />
                    </div>
                    <div className={$.multi}>
                        <div className={$.field}>
                            <FormInput
                                name={t('details_firstname')}
                                size={FormInputSize.Medium}
                                onChange={e => {
                                    handleFormFieldChange({
                                        name: 'first_name',
                                        value: e.target.value,
                                    });
                                }}
                                showErrorMessage={isFieldMissing('firstName')}
                                defaultValue={firstName}
                                requiredErrorMessage={t('traveldetail_error_required_first_name')}
                                required
                            />
                        </div>
                        <div className={$.field}>
                            <FormInput
                                name={t('details_lastname')}
                                size={FormInputSize.Medium}
                                onChange={e => {
                                    handleFormFieldChange({
                                        name: 'last_name',
                                        value: e.target.value,
                                    });
                                }}
                                showErrorMessage={isFieldMissing('lastName')}
                                defaultValue={lastName}
                                requiredErrorMessage={t('traveldetail_error_required_last_name')}
                                required
                            />
                        </div>
                    </div>
                    <div className={$.field}>
                        <p className={$.fieldLabel}>{t('details_nationality')}</p>
                        <FormSelect
                            options={nationalityOptions}
                            onChange={value => {
                                handleFormFieldChange({
                                    name: 'country_code',
                                    value: nationalities[value].value,
                                });
                            }}
                            smallHeight
                            selected={nationalityOptions.findIndex(
                                option => option === getNationality(countryCode)
                            )}
                        />
                    </div>
                    <div className={$.field}>
                        <p className={classnames($.fieldLabel, $.fieldLabelDate)}>
                            {t('details_birthdate')}
                        </p>
                        <FormDateSelect
                            onChange={handleDateOfBirthChange}
                            id={number}
                            day={day}
                            eventDate={eventDate}
                            month={month}
                            year={year}
                            showErrorMessage={isFieldMissing('birthday')}
                            required
                            isAdult={extended || number === 0}
                        />
                    </div>
                </section>
                {extended && (
                    <React.Fragment>
                        <hr className={$.line} />
                        <section className={$.formSection}>
                            <h4 className={$.title}>{t('details_address')}</h4>
                            <div className={$.field}>
                                <FormInput
                                    name={t('details_address-1')}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'address_line_1',
                                            value: e.target.value,
                                        });
                                    }}
                                    showErrorMessage={isFieldMissing('addressLine1')}
                                    defaultValue={addressLine1}
                                    requiredErrorMessage={t(
                                        'traveldetail_error_required_address_line_1'
                                    )}
                                    required
                                />
                            </div>
                            <div className={$.field}>
                                <FormInput
                                    name={`${t('details_county')}/${t('details_additional-info')}`}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'county',
                                            value: e.target.value,
                                        });
                                    }}
                                    defaultValue={addressLine2}
                                />
                            </div>
                            <div className={$.field}>
                                <FormInput
                                    name={t('details_eircode')}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'zip_code',
                                            value: e.target.value,
                                        });
                                    }}
                                    defaultValue={zipCode}
                                />
                            </div>
                            <div className={$.field}>
                                <FormInput
                                    name={t('details_city')}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'city',
                                            value: e.target.value,
                                        });
                                    }}
                                    showErrorMessage={isFieldMissing('city')}
                                    defaultValue={city}
                                    requiredErrorMessage={t('traveldetail_error_required_city')}
                                    required
                                />
                            </div>
                        </section>
                        <hr className={$.line} />
                        <section className={$.formSection}>
                            <h4 className={$.title}>{t('details_contact')}</h4>
                            <div className={$.field}>
                                <FormInput
                                    name={t('details_email')}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'email',
                                            value: e.target.value,
                                        });
                                    }}
                                    showErrorMessage={isFieldMissing('email')}
                                    // This pattern comes from the library 'yup' that Events Travel uses for email validation
                                    // Changed all [a-z] to [A-Za-z] so it's case insensitive
                                    pattern="^((([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$"
                                    defaultValue={email}
                                    requiredErrorMessage={t('traveldetail_error_required_email')}
                                    required
                                />
                            </div>
                            <div className={$.field}>
                                <FormInput
                                    name={t('details_phone')}
                                    size={FormInputSize.Large}
                                    onChange={e => {
                                        handleFormFieldChange({
                                            name: 'phone_number',
                                            value: e.target.value,
                                        });
                                    }}
                                    showErrorMessage={isFieldMissing('phoneNumber')}
                                    pattern="[\+]{0,1}[\d\-]{10,15}"
                                    defaultValue={phoneNumber}
                                    requiredErrorMessage={t(
                                        'traveldetail_error_required_phone_number'
                                    )}
                                    required
                                />
                            </div>
                        </section>
                    </React.Fragment>
                )}
            </div>
        </section>
    );
};

export default DetailsTraveler;
