import {
    AvailabilityAccommodationRate,
    AvailabilityAccommodationRateEntity,
} from './AvailabilityAccommodationRate';

/* eslint-disable lines-between-class-members */

export interface AvailabilityAccommodationEntity {
    id: string;
    partner: string;
    partner_id: string;
    breakfast_possible: boolean;
    cheapest: AvailabilityAccommodationRateEntity;
    cheapest_breakfast: AvailabilityAccommodationRateEntity;
}

export class AvailabilityAccommodation {
    public readonly id: string;
    public readonly partner: string;
    public readonly partnerId: string;
    public readonly cheapest: AvailabilityAccommodationRate;
    public readonly cheapestBreakfast: AvailabilityAccommodationRate;

    public constructor(availability: AvailabilityAccommodationEntity) {
        this.id = availability.id;
        this.partner = availability.partner;
        this.partnerId = availability.partner_id;
        this.cheapest = new AvailabilityAccommodationRate(availability.cheapest);
        if (availability.cheapest_breakfast != null) {
            this.cheapestBreakfast = new AvailabilityAccommodationRate(
                availability.cheapest_breakfast
            );
        }
    }
}
