import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Faq, WhyEquipo, Button, CloseButton } from '..';
import { Item } from '../WhyEquipo/WhyEquipo';
import $ from './Help.scss';

interface Props {
    title: string;
    text: string;
    phoneNumber: string;
    image: {
        url: string;
        title: string;
    };
    icon: {
        url: string;
        title: string;
    };
    ctaLabel?: string;
    values: string[];
    faq?: {[key: string]: string | {[key: string]: string}[]};
    whyEquipo?: {[key: string]: string | {[key: string]: string} | Item[]};
    closeModal: () => void;
}

const Help = (props: Props) => {
    const { title, phoneNumber, text, image, icon, faq, whyEquipo, ctaLabel, closeModal } = props;
    const { t } = useTranslation();

    return (
        <section className={$.base}>
            <h2 className={$.title}>{title}</h2>
            <CloseButton onClick={closeModal} />
            <div className={$.content}>
                <div className={$.block}>
                    <div className={$.intro}>
                        <div className={$.employee}>
                            <div className={$.imgWrap}>
                                <img
                                    src={image.url}
                                    alt={image.title}
                                    title={image.title}
                                    className={$.img}
                                />
                            </div>
                            <div className={$.status} />
                        </div>
                        <p className={$.text}>{text}</p>
                    </div>
                    <a href={`tel:${phoneNumber}`} className={$.contactLink}>
                        {t('general_call')} <span className={$.phoneNumber}> {phoneNumber}</span>
                    </a>
                    <a href={`https://wa.me/${phoneNumber}`} className={$.contactLink}>
                        <img src={icon.url} alt="" title="" className={$.whatsappIcon} />
                        <span className={$.whatsapp}>WhatsApp</span> <span>({t('supportblock_online')})</span>
                    </a>
                </div>
                <div className={$.block}>
                    {whyEquipo && <WhyEquipo title={whyEquipo.title as string} values={whyEquipo.values as Item[]} icon={whyEquipo.icon as {[key: string]: string}} />}
                    {faq && <Faq title={faq.title as string} faqItems={faq.faqItems as {[key: string]: string}[]} />}
                </div>
            </div>
            {ctaLabel && <Button text={ctaLabel} onClick={closeModal} />}
        </section>
    );
};

export default Help;
