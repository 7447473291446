/* eslint-disable lines-between-class-members */
export interface TicketEntity {
    id: string;
    category_id: string;
    name: string;
    seatplan_image: string;
    supplement_pp: number;
    images?: {
        url: string;
        title: string;
    }[];
}

export class Ticket {
    public readonly id: string;
    public readonly categoryId: string;
    public readonly name: string;
    public seatplanImage: string;
    public supplementPP: number;
    public images: { url: string; title: string }[];

    public constructor(ticket: TicketEntity) {
        this.id = ticket.id;
        this.categoryId = ticket.category_id;
        this.name = ticket.name;
        this.seatplanImage = ticket.seatplan_image;
        this.supplementPP = +ticket.supplement_pp;
        this.images = ticket.images && ticket.images.length > 0 ? ticket.images : [];
    }
}
