import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Page } from '~source/view/components';
import $ from './NotFound.scss';

const NotFound: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Page step={0} mustHideTravelDetails>
            <section className={$.root}>
                <h1 className={$.title}>{t('pagenotfound_title')}</h1>
                <p className={$.text}>{t('pagenotfound_text')}</p>
                {/* Disable for now, link of this button needs to come from the api but has not been integrated in it yet */}
                {/* <Button text={t('pagenotfound_buttontext')} /> */}
            </section>
        </Page>
    );
};

export default NotFound;
