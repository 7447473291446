import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Cache from '../../../core/services/cache';
import { FormInput, IconLabel } from '..';
import { FormInputSize } from '../FormInput/FormInput';
import { setOrderCoupon } from '~source/core/services/order';
import { IconLabelType } from '../IconLabel/IconLabel';
import $ from './ActionCode.scss';

interface Props extends WithTranslation {
    small: boolean;
}

interface State {
    code?: string;
    loading: boolean;
    open: boolean;
    couponApplied: boolean;
    wrongCode: boolean;
    tryAgain: boolean;
}

class ActionCode extends React.Component<Props, State> {
    public constructor(props) {
        super(props);
        this.state = {
            open: false,
            loading: false,
            couponApplied: false,
            wrongCode: false,
            tryAgain: false,
        };
        this.toggle = this.toggle.bind(this);
        this.applyCode = this.applyCode.bind(this);
        this.setCode = this.setCode.bind(this);
    }

    public setCode(e: React.FocusEvent<HTMLInputElement>) {
        this.setState({ code: e.target.value });
    }

    public async applyCode() {
        const order = Cache.getOrder();
        const { code } = this.state;

        if (code == null) { return; }

        this.setState({ loading: true });
        const couponSet = await setOrderCoupon(order.id, code);

        if (!couponSet) { // change when api is finished
            this.setState({
                open: false,
                loading: false,
                wrongCode: true,
                tryAgain: true,
                couponApplied: false,
            });

            setTimeout(() => {
                this.setState({
                    wrongCode: false,
                });
            }, 7000);

            return;
        }

        this.setState({ loading: false, couponApplied: true });

        setTimeout(() => {
            this.setState({ open: false });
        }, 7000);
    }

    public async toggle() {
        this.setState({ open: true, tryAgain: false });
    }

    public render() {
        const { t, small } = this.props;
        const { open, couponApplied, loading, wrongCode, tryAgain } = this.state;

        return (
        <div className={$.actionCode}>
            <button className={classnames([small && $.code, $.text, (open || couponApplied || tryAgain) && $.hidden])} onClick={this.toggle} type="button">{t('payment_code')}</button>
            {!couponApplied && (
                <div className={classnames($.codeBlock, !open && $.inputHidden)}>
                    <FormInput size={FormInputSize.ActionCode} placeholder={t('payment_actioncode')} onBlur={this.setCode} />
                    <button className={$.paymentAdd} type="button" onClick={() => this.applyCode()}>{t('payment_actioncode_add')}</button>
                </div>
            )}
            {open && couponApplied && (
                <div className={$.payment}>
                    <IconLabel type={IconLabelType.Checkmark}>{t('payment_actioncode_added')}</IconLabel>
                </div>
            )}
            {loading && <p>Loading...</p>}
            {wrongCode && (
                <div className={$.wrongCode}>
                    {t('payment_actioncode_wrong')}
                </div>
            )}
            <button className={classnames([small && $.code, $.text, !tryAgain && $.hidden])} onClick={this.toggle} type="button">{t('payment_actioncode_retry')}</button>
        </div>
        );
    }
}

export default withTranslation()(ActionCode);
