import * as React from 'react';
import $ from './NextStepBanner.scss';

interface Props {
    text: string;
}

const NextStepBanner = (props: Props) => {
    const { text } = props;
    return (
        <div className={$.container} data-testid="nextStepBanner">
            {text}
        </div>
    );
};

export default NextStepBanner;
