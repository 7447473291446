import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './SupportBlock.scss';

interface Props extends WithTranslation {
    title: string;
    text: string;
    image: {
        url: string;
        title: string;
    };
    phoneNumber: string;
    whatsappNumber: string;
    online: boolean;
}

const SupportBlock = (props: Props) => {
    const { t, title, text, image, phoneNumber, online, whatsappNumber } = props;

    return (
        <div className={$.base}>
            <h3 className={$.title}>{title}</h3>
            <p className={$.text}>{text}</p>

            <div className={$.support}>
                <div className={classnames([$.wrapper, online && $.online])}>
                    <div className={$.imgContainer}>
                        <img src={image.url} alt={image.title} className={$.img} />
                    </div>
                </div>
                <p className={$.contact}>
                    <span className={$.phone}>{t('general_call')} <a href={`tel:${phoneNumber}`}>{phoneNumber}</a></span>
                    <br />
                    <span className={$.whatsapp}>
                        <a href={`https://wa.me/${whatsappNumber}`} className={$.whatsappLink}>{t('supportblock_whatsapp')}</a>
                        {online && <span className={$.online}> ({t('supportblock_online')})</span>}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default withTranslation()(SupportBlock);
