import * as React from 'react';

import { Button, Html } from '~source/view/components';
import $ from './TakeOverMessage.scss';

interface Props {
    callToActionLabel: string;
    description: string;
    eventName?: string;
    imageAlt: string;
    imageSrc: string;
    onCallToActionClick: () => void;
    subTitle: string;
    title: string;
}

const TakeOverMessage: React.FunctionComponent<Props> = ({
    callToActionLabel,
    description,
    eventName = null,
    imageAlt,
    imageSrc,
    onCallToActionClick,
    subTitle,
    title,
}) => (
    <section className={$.wrap}>
        <div className={$.imgWrap}>
            <img alt={imageAlt} src={imageSrc} className={$.img} />
        </div>
        <h1 className={$.title}>{title}</h1>
        <div className={$.content}>
            <h2 className={$.subTitle}>{subTitle}</h2>
            <Html className={$.description}>{description}</Html>
            {eventName && <p className={$.eventName}>{eventName}</p>}
            <Button text={callToActionLabel} onClick={onCallToActionClick} />
        </div>
    </section>
);

export default TakeOverMessage;
