import * as React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { checkIfChild, checkIfAdult, checkIfValidDate } from '~source/utils/utils';
import $ from './FormDateSelect.scss';

interface Props {
    day?: number;
    eventDate: Date;
    id: number;
    isChild?: boolean;
    isAdult?: boolean;
    month?: number;
    onChange?: (id: number, day: number, month: number, year: number) => void;
    required?: boolean;
    showErrorMessage?: boolean;
    year?: number;
}

interface FormDateSelectInputProps {
    isPristine: boolean;
    min: number;
    max: number;
    name: string;
    onFieldChange: (value: string, name: string) => void;
    required?: boolean;
    showError: boolean;
    value: React.ReactText;
}

const fNames = {
    d: 'day',
    m: 'month',
    y: 'year',
};

const FormDateSelectInput: React.FunctionComponent<FormDateSelectInputProps> = ({
    onFieldChange, name, value, min, max, required, isPristine, showError,
}) => {
    const [isValid, setValid] = React.useState(false);
    const { t } = useTranslation();
    const inputStyle = classnames($.input, isPristine && $.isPristine, (showError || (!isPristine && !isValid)) && $.invalid);

    return (
        <>
            <p className={$.name}>{t(name)}</p>
            <input
                className={inputStyle}
                onChange={(e) => {
                    const inputValue = Number(e.target.value);
                    onFieldChange(e.target.value, name);
                    setValid(inputValue >= min && inputValue <= max);
                }}
                name={name}
                type="number"
                placeholder={t(`${name}-format`)}
                value={value}
                required={required}
            />
        </>
    );
};

const FormDateSelect: React.FunctionComponent<Props> = ({
    day: dayFromProps = '',
    eventDate,
    isChild,
    isAdult,
    id,
    month: monthFromProps = '',
    onChange = () => {},
    required,
    showErrorMessage = false,
    year: yearFromProps = '',
}) => {
    const { t: translate } = useTranslation();
    const invalidDateMessage = translate('traveldetail_error_required_date_of_birth');
    const notAChildMessage = translate('traveldetail_error_required_date_of_birth_not_child');
    const notAnAdultMessage = translate('traveldetail_error_required_date_of_birth_not_adult');

    const rootRef = React.useRef<HTMLDivElement>(null);
    const isInitialMount = React.useRef(true);
    const [day, setDay] = React.useState<number | string>(dayFromProps);
    const [month, setMonth] = React.useState<number | string>(monthFromProps);
    const [year, setYear] = React.useState<number | string>(yearFromProps);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const [isPristineFields, setPristineFields] = React.useState(['day', 'month', 'year']);
    const [isFirstInteraction, setIsFirstInteraction] = React.useState(true);
    const [minYear, maxYear] = React.useMemo(() => {
        const currentYear = new Date().getFullYear();
        const min = isChild ? currentYear - 18 : currentYear - 120;
        const max = isAdult ? currentYear - 18 : currentYear;
        return [min, max];
    }, [isChild, isAdult]);

    const isPristine = name => isPristineFields.includes(name);

    const handleFirstInteraction = () => {
        setIsFirstInteraction(false);
        setErrorMessage('');
    };

    const makeFieldDirty = name => {
        const indexOfName = isPristineFields.indexOf(name);

        if (indexOfName === -1) return;

        setPristineFields([
            ...isPristineFields.slice(0, indexOfName),
            ...isPristineFields.slice(indexOfName + 1, isPristineFields.length),
        ]);
    };

    const validate = () => {
        const isValidDate = checkIfValidDate(day, month, year);
        if (!isValidDate) {
            setErrorMessage(invalidDateMessage);
            return;
        }

        if (isChild) {
            const validChild = checkIfChild(day, month, year, eventDate);

            if (validChild) setErrorMessage('');
            else setErrorMessage(notAChildMessage);

            return;
        }
        if (isAdult) {
            const validAdult = checkIfAdult(day, month, year, eventDate);

            if (validAdult) setErrorMessage('');
            else setErrorMessage(notAnAdultMessage);

            return;
        }

        setErrorMessage('');
    };

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        if (isPristineFields.length < 1) validate();

        if (rootRef.current) {
            if (onChange) {
                onChange(id, Number(day), Number(month), Number(year));
            }
        }
    }, [day, month, year]);

    React.useEffect(() => {
        if (showErrorMessage) {
            setIsFirstInteraction(true);
            setErrorMessage(invalidDateMessage);
        }
    }, [showErrorMessage]);

    return (
        <>
            <div className={$.multi} ref={rootRef}>
                <div>
                    <FormDateSelectInput
                        onFieldChange={(value, name) => {
                            if (isFirstInteraction) handleFirstInteraction();
                            setDay(value);
                            makeFieldDirty(name);
                        }}
                        name={fNames.d}
                        value={day}
                        min={1}
                        max={31}
                        required={required}
                        isPristine={isPristine(fNames.d)}
                        showError={!!errorMessage}
                    />
                </div>
                <div>
                    <FormDateSelectInput
                        onFieldChange={(value, name) => {
                            if (isFirstInteraction) handleFirstInteraction();
                            setMonth(value);
                            makeFieldDirty(name);
                        }}
                        name={fNames.m}
                        value={month}
                        min={1}
                        max={12}
                        required={required}
                        isPristine={isPristine(fNames.m)}
                        showError={!!errorMessage}
                    />
                </div>
                <div>
                    <FormDateSelectInput
                        onFieldChange={(value, name) => {
                            if (isFirstInteraction) handleFirstInteraction();
                            setYear(value);
                            makeFieldDirty(name);
                        }}
                        name={fNames.y}
                        value={year}
                        min={minYear}
                        max={maxYear}
                        required={required}
                        isPristine={isPristine(fNames.y)}
                        showError={!!errorMessage}
                    />
                </div>
            </div>
            {errorMessage && <div className={$.errorMessage}>{errorMessage}</div>}
        </>
    );
};

export default FormDateSelect;
