import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FormCheckbox } from '..';
import { Ticket } from '../../../core/models/Ticket';
import * as Cache from '../../../core/services/cache';
import { Popover } from '~source/view/components';
import { Venue } from '~source/core/models/Venue';
import MobileContext from '~source/view/context/MobileContext';
import $ from './SeatPicker.scss';

const ACTION_NEXT = 'next';
const ACTION_PREV = 'prev';

interface Props extends WithTranslation {
    tickets: Ticket[];
    occupancy: number;
    venue: Venue;
    onSelection: (ticketId: string) => void;
}

interface State {
    activeImageIndex: number;
}

function sortByPriceThenName(arr) {
    return [...arr].sort((a, b) => a.supplementPP - b.supplementPP || a.name - b.name);
}

class SeatPicker extends React.Component<Props, State> {
    public constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            activeImageIndex: 0,
        };
    }

    public setActiveImage = (direction) => {
        const ticket = Cache.getTicket();
        if (ticket !== null) {
            const { activeImageIndex } = this.state;

            const calc = direction === ACTION_NEXT ? 1 : -1;
            let newKey = activeImageIndex + calc;

            if (!ticket.images[newKey]) {
                newKey = direction === ACTION_NEXT ? 0 : ticket.images.length - 1;
            }

            this.setState({ activeImageIndex: newKey });
        }
    };

    public handleChange(event: React.ChangeEvent) {
        const { tickets, onSelection } = this.props;

        const ticket = tickets.find(possibleTicket => possibleTicket.id === event.target.id);
        this.setState({ activeImageIndex: 0 });

        if (ticket !== undefined && onSelection !== undefined) {
            onSelection(ticket.id);
        }
    }

    public render() {
        const { t, tickets, occupancy, venue } = this.props;
        const { activeImageIndex } = this.state;
        const isMobile = this.context;
        const ticket = Cache.getTicket();
        const sortedTickets = sortByPriceThenName(tickets);

        let url = '/images/temp/stadium.png';
        if (
            ticket
            && ticket.seatplanImage
            && typeof ticket.seatplanImage === 'string'
        ) {
            url = ticket.seatplanImage;
        }

        if (occupancy === 0) {
            return <React.Fragment />;
        }

        const hasImages = ticket !== null && ticket.images && ticket.images.length > 0;

        return (
            <div className={$.seatPicker}>
                <h2 className={$.seatPickerTitle}>
                    {t('seatpicker_title')} {venue.name}
                </h2>
                <ul>
                    {sortedTickets.map(possibleTicket => (
                        <li key={`tickets${possibleTicket.id}`}>
                            <FormCheckbox
                                id={possibleTicket.id}
                                name="tickets"
                                title={possibleTicket.name}
                                price={possibleTicket.supplementPP}
                                checked={ticket !== null && ticket.id === possibleTicket.id}
                                onChange={this.handleChange}
                                fullWidth
                            />
                        </li>
                    ))}
                </ul>
                <div className={$.stadium}>
                    <img src={url} alt="Stadium" />
                </div>
                {ticket !== null && (
                    <div className={$.details}>
                        <div className={classnames([$.content, !hasImages && $.noImages])}>
                            <h2 className={$.seatName}>{ticket.name}</h2>
                            {/* description will eventually come from the API but since it isn't right now, disable it */}
                            {/* <p>{t('ticket_seatdetail')}</p> */}
                        </div>
                        {hasImages && (
                            <div className={$.images}>
                                <button
                                    type="button"
                                    className={$.left}
                                    onClick={() => this.setActiveImage(ACTION_PREV)}
                                >
                                    <img src="/images/icon-arrow-left-white.svg" alt="<" />
                                </button>
                                <button
                                    type="button"
                                    className={$.right}
                                    onClick={() => this.setActiveImage(ACTION_NEXT)}
                                >
                                    <img src="/images/icon-arrow-right-white.svg" alt="<" />
                                </button>
                                <div className={$.imagesContainer}>
                                    {ticket.images.map((image, index) => (
                                        <React.Fragment key={image.url}>
                                            <img
                                                src={image.url}
                                                alt={image.title}
                                                className={classnames([
                                                    $.image,
                                                    activeImageIndex === index && $.active,
                                                ])}
                                            />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className={$.highlights}>
                            <Popover
                                text={t('seatpicker_seatsconnected_popover')}
                                placement="bottomLeft"
                                arrow={isMobile}
                            >
                                <p className={$.highlightConnected}>{t('seatpicker_seatsconnected')}</p>
                            </Popover>
                            <Popover
                                text={t('seatpicker_matchprotection_popover')}
                                placement="bottomLeft"
                                arrow={isMobile}
                            >
                                <p className={$.highlightProtection}>{t('seatpicker_matchprotection')}</p>
                            </Popover>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

SeatPicker.contextType = MobileContext;

export default withTranslation()(SeatPicker);
