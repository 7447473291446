/* eslint-disable lines-between-class-members */
export interface AvailabilityBaseAccommodationRateEntity {
    id: string;
    breakfast_included: boolean;
    rates: { [string: string]: string };
}

export class AvailabilityBaseAccommodationRate {
    public readonly id: string;
    public readonly breakfastIncluded: boolean;
    public readonly rates: { [string: string]: string };

    public constructor(availability: AvailabilityBaseAccommodationRateEntity) {
        this.id = availability.id;
        this.breakfastIncluded = availability.breakfast_included;
        this.rates = availability.rates;
    }
}
