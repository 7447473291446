import * as React from 'react';
import classnames from 'classnames';

import $ from './Checkbox.scss';

interface Props {
    className?: string;
    classNameBox?: string;
    defaultChecked?: boolean;
    id: string;
    label: string | React.ReactNode;
    name: string;
    onChange: (value: boolean) => void;
}

const Checkbox: React.FunctionComponent<Props> = ({
    className = null,
    classNameBox = null,
    defaultChecked = false,
    label,
    id,
    name,
    onChange,
}) => (
    <div className={classnames($.root, className)}>
        <input
            className={$.input}
            type="checkbox"
            id={id}
            name={name}
            onChange={event => {
                onChange(event.target.checked);
            }}
            defaultChecked={defaultChecked}
        />
        <svg className={classnames($.svg, classNameBox)}>
            <rect className={$.outer} width="18" height="18" rx="2" x="0.5" y="0.5" />
            <g className={$.checkMark} fillRule="evenodd">
                <path d="M.462 4.17a1.29 1.29 0 011.823 0l4.806 4.805a1.29 1.29 0 01-1.824 1.823L.462 5.993a1.29 1.29 0 010-1.824z" />
                <path d="M5.225 10.755l-.08-.08a1.35 1.35 0 010-1.908L12.962.95a1.35 1.35 0 011.909 0l.08.08a1.35 1.35 0 010 1.909l-7.817 7.816a1.35 1.35 0 01-1.91 0z" />
            </g>
        </svg>
        <label htmlFor={id} className={$.label}>
            {label}
        </label>
    </div>
);

export default Checkbox;
