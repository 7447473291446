import * as React from 'react';
import $ from './PayIcons.scss';

export default function PayIcons() {
    return (
        <div className={$.wrap}>
            <img src="/images/logos/mastercard.png" className={$.icon} alt="Mastercard" />
            {/* <img src="/images/logos/maestro.png" className={$.icon} alt="Maestro" /> */}
            <img src="/images/logos/visa.png" className={$.icon} alt="Visa" />
            {/* <img src="/images/logos/visaelectron.png" className={$.icon} alt="Visa Electron" /> */}
            <img src="/images/logos/amex.png" className={$.icon} alt="American Express" />
            {/* <img src="/images/logos/vpay.png" className={$.icon} alt="V Pay" /> */}
            {/* <img src="/images/logos/paypal.png" className={$.icon} alt="Pay Pal" /> */}
        </div>
    );
}
