import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Popover } from '~source/view/components';
import $ from './ConfirmLabel.scss';

interface Props extends WithTranslation {
    className?: string;
    isConfirmed: boolean;
    withTooltip?: boolean;
}

const ConfirmLabel: React.FunctionComponent<Props> = ({
    className = null,
    isConfirmed = true,
    t,
    withTooltip = false,
}) => {
    const label = t(isConfirmed ? 'event_confirmed' : 'event_not_confirmed');
    const description = t(isConfirmed ? 'event_confirmed_text' : 'event_not_confirmed_text');
    return (
        <span className={classnames($.root, className)}>
            <span className={classnames($.label, isConfirmed && $.isConfirmed)}>{label}</span>
            {withTooltip && (
                <Popover text={description} arrow placement="bottom">
                    <img className={$.popoverImg} src="/images/icon-info.svg" alt="info" />
                </Popover>
            )}
        </span>
    );
};

export default withTranslation()(ConfirmLabel);
