import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { Ticket, Hotel, Flight, Details, Payment, Completed, SessionEnd, NotFound } from './pages';
import MobileContext from './context/MobileContext';
import { createDebounce, isMobile as checkIfMobile } from '~source/utils/utils';

const routes = [
    {
        path: '/hotel',
        component: Hotel,
        exact: false,
    },
    // {
    //     path: '/flight',
    //     component: Flight,
    //     exact: false,
    // },
    {
        path: '/details',
        component: Details,
        exact: false,
    },
    {
        path: '/payment',
        component: Payment,
        exact: false,
    },
    {
        path: '/completed',
        component: Completed,
        exact: false,
    },
    {
        path: '/session-end',
        component: SessionEnd,
        exact: false,
    },
    {
        path: '/ticket/:eventId',
        component: Ticket,
        exact: true,
    },
    {
        path: '/404',
        component: NotFound,
        exact: false,
    },
];
const mobileDebounce = createDebounce(200);

function App() {
    const [isMobile, setIsMobile] = React.useState(checkIfMobile());
    const handleResize = () => {
        mobileDebounce(() => {
            setIsMobile(checkIfMobile());
        });
    };

    React.useEffect(() => {
        setIsMobile(checkIfMobile());
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Suspense fallback="Loading...">
            <MobileContext.Provider value={isMobile}>
                <Router>
                    <Switch>
                        {routes.map(route => (
                            <Route key={route.path} {...route} />
                        ))}
                        <Redirect to="/404" />
                    </Switch>
                </Router>
            </MobileContext.Provider>
        </React.Suspense>
    );
}

export default hot(module)(App);
