/* eslint-disable @typescript-eslint/camelcase */
import moment from 'moment-timezone';
import matchAll from 'string.prototype.matchall';

export function generateId(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random()
        .toString(36)
        .substr(2, 9);
}

export function isMobile() {
    return window.innerWidth < 700;
}

export function parseLink(string, external = false): string {
    let newString = string;
    // get all links from a string (both name and src)
    const matches = [...matchAll(string, /(?:<a href=")(.*?)">(.*?)<\/a>/gm)];
    const target = external ? '_blank' : '_self';

    if (matches.length > 0) {
        matches.forEach(item => {
            newString = newString.replace(
                item[0],
                `<a href="${item[1]}" title="${item[2]}" target="${target}">${item[2]}</a>`
            );
        });
    }

    return newString;
}

export function checkIfValidDate(day, month, year) {
    if (!day || !month || !year) return false;
    if (year.toString().length !== 4) return false;

    const minimumBirthDate = new Date();
    // Infants are allowed to travel by plane when they're 8 days old
    minimumBirthDate.setDate(minimumBirthDate.getDate() - 8);

    if (day > 31 || month > 12 || year > minimumBirthDate.getFullYear()) return false;

    // month needs to be subtracted by 1 since January === 0 and December === 11
    const birthDate = new Date(year, month - 1, day);

    if (birthDate < minimumBirthDate) {
        return true;
    }
    return false;
}

export function getAgeOnDate(day, month, year, compareDate) {
    if (!checkIfValidDate(day, month, year)) return false;

    const birthday = moment(`${day}-${month}-${year}`, 'DD-MM-YYYY');
    return moment(compareDate).diff(birthday, 'years');
}

export function checkIfChild(day, month, year, compareDate) {
    const age = getAgeOnDate(day, month, year, compareDate);
    return (age >= 0 && age < 18);
}

export function checkIfAdult(day, month, year, compareDate) {
    const age = getAgeOnDate(day, month, year, compareDate);
    return age >= 18;
}

export function capitalize(text) {
    return text
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

// dd-mm-yyyy
const dateStringRegex = new RegExp(/\d{1,2}-\d{1,2}-\d{4}/);
const reverseStringRegex = new RegExp(/\d{4}-\d{1,2}-\d{1,2}/);

function isDateString(str) {
    return dateStringRegex.test(str);
}

function isReverseDateString(str) {
    return reverseStringRegex.test(str);
}

export function seperateDate(date) {
    if (!date || typeof date !== 'string' || (!isDateString(date) && !isReverseDateString(date))) {
        return { day: undefined, month: undefined, year: undefined };
    }
    if (isReverseDateString(date)) {
        const [year, month, day] = date.split('-').map(d => Number(d));
        return { day, month, year };
    }
    const [day, month, year] = date.split('-').map(d => Number(d));
    return { day, month, year };
}

export function getReversedDate(birthday) {
    if (!birthday || typeof birthday !== 'string' || (!isDateString(birthday) && !isReverseDateString(birthday))) {
        return '';
    }

    return moment(birthday, 'D-M-YYYY').format(
        'YYYY-MM-DD'
    );
}

export function queryStringToObject(queryString) {
    const search = queryString.substring(1);
    return JSON.parse(
        `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => (key === '' && value) || decodeURIComponent(value)
    );
} // source: https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object

export function getTripDate(startDate, endDate) {
    const isSameMonth = endDate.month() === startDate.month();
    const startDateFormat = isSameMonth ? 'D' : 'D MMM';

    return `${startDate.format(startDateFormat)} - ${endDate.format('D MMM YYYY')}`;
}

export function createDebounce(amount: number) {
    let timeoutId: any = null;

    /**
     * Calls the passed function only when this function is not called again
     * within a predetermined amount of time.
     * @param {Function} cb
     */
    const debounce = (cb: any) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            timeoutId = null;
            cb();
        }, amount);
    };

    return debounce;
}

export function getElementCoordinates(el) {
    let xPos = 0;
    let yPos = 0;

    if (el.tagName === 'BODY') {
        // deal with browser quirks with body/window/document and page scroll
        const xScroll = el.scrollLeft || document.documentElement.scrollLeft;
        const yScroll = el.scrollTop || document.documentElement.scrollTop;

        xPos += (el.offsetLeft - xScroll + el.clientLeft);
        yPos += (el.offsetTop - yScroll + el.clientTop);
    } else {
        // for all other non-BODY elements
        xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
        yPos += (el.offsetTop - el.scrollTop + el.clientTop);
    }

    return {
        x: xPos,
        y: yPos
    };
}

export function getValuesFromObject(obj): string[] {
    const objKeys = Object.keys(obj);
    const arr: string[] = [];
    objKeys.map(key => obj[key].map((value: string) => arr.push(value)));

    return arr;
}

export function addValueToArray(arr: string[], value: string): string[] {
    const isValuePresent = arr.some(arrValue => arrValue === value);

    if (!isValuePresent) {
        return [
            ...arr,
            value,
        ];
    }
    return arr;
}

export function addMultipleValuesToArray(values: string[], arr: string[]): string[] {
    let newArr = arr;

    values.forEach(value => {
        newArr = addValueToArray(newArr, value);
    });

    return newArr;
}

export function removeValueFromArray(value: string, arr: string[]) {
    const valueIndex = arr.findIndex(e => e === value);

    if (valueIndex !== -1) {
        return [
                ...arr.slice(0, valueIndex),
                ...arr.slice(valueIndex + 1, arr.length),
        ];
    }
    return arr;
}

export function flat(nestedArr: any[][]): any[] {
    if (nestedArr.length === 0) return [];
    const mutableNestedArr = [...nestedArr];

    return mutableNestedArr.reduce((prevArr, arrEl) => {
        const unnestedArr = prevArr;

        if (arrEl.constructor === Array) arrEl.forEach(value => unnestedArr.push(value));
        else unnestedArr.push(arrEl);

        return unnestedArr;
    }, []);
}
