import { Accommodation } from '../models/Accommodation';
import { Availability } from '../models/Availability';
import { Ticket } from '../models/Ticket';
import * as Cache from './cache';

export function getPrice(accommodation: Accommodation, availability: Availability, ticket: Ticket | null, adults: number, childrenCount: number) {
    let accommodationPrice = 0;

    if (accommodation != null && accommodation.availability != null) {
        const cheapest = Cache.getBreakfast() === true
            ? accommodation.availability.cheapestBreakfast
            : accommodation.availability.cheapest;

        accommodationPrice = cheapest == null ? 0 : cheapest.supplementPP;
    }

    let price = 0;
    if (availability != null) {
        const { price: newPrice } = availability.baseAccomodationPackage;
        price = newPrice;
    }

    let totalPrice = price + accommodationPrice;

    if (ticket != null) {
        totalPrice += ticket.supplementPP;
    }

    return { accommodationPrice, price, totalPrice };
}
