import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './TravelDetailList.scss';
import { EventDetail } from '~source/core/models/EventDetail';
import { Availability } from '~source/core/models/Availability';
import { ActionCode, PayIcons } from '..';
import * as Cache from '~source/core/services/cache';
import * as Calculations from '~source/core/services/calculations';

interface Props extends WithTranslation {
    eventDetail: EventDetail;
    availability?: Availability;
    extraIndent?: boolean;
    showPricing: boolean;
    step?: number;
}

interface State {

}

// eslint-disable-next-line react/prefer-stateless-function
class TravelDetailList extends React.Component<Props, State> {
    // eslint-disable-next-line no-useless-constructor
    public constructor(props) {
        super(props);
    }

    public render() {
        const { t, showPricing, step } = this.props;
        const { adults, children } = Cache.getOccupancy();
        const availability = Cache.getAvailability();
        const ticket = Cache.getTicket();
        const accommodation = Cache.getAccommodation();

        const occupancy = adults.length + children.length;

        const { price, accommodationPrice, totalPrice } = Calculations.getPrice(accommodation, availability, ticket, adults.length, children.length);

        if (occupancy === 0 || price === 0) {
            return <React.Fragment />;
        }

        const ticketName = ticket.supplementPP > 0 ? `${t('coupon_upgrade')} (${ticket.name})` : ticket.name;

        return (
            <React.Fragment>
                {!showPricing && (
                    <p className={$.noPrices}>{t('ticket_confirmpreferencestoseeprices')}</p>
                )}
                {showPricing && (
                <ul className={$.list}>
                    <li className={$.listItem}>
                        <span className={$.travelPart}>{t('ticket_hoteltickets')}</span>
                        <span className={$.cost}>{price}</span>
                    </li>
                    {ticket && (
                        <li className={$.listItem}>
                            <span className={$.travelPart}>{ticketName}</span>
                            <span className={$.cost}>{ticket.supplementPP}</span>
                        </li>
                    )}
                    {accommodation && (
                        <li className={$.listItem}>
                            <span className={$.travelPart}>{accommodation.name}</span>
                            <span className={$.cost}>{accommodationPrice}</span>
                        </li>
                    )}
                    <li className={$.listItem}>
                        <span className={$.travelPart}>{t('traveldetail_totalperperson')}</span>
                        <span className={$.cost}>{totalPrice}</span>
                    </li>
                </ul>
            )}
            {showPricing && (
                <p className={$.important}>
                    {t('traveldetail_totalfor', { occupancy })}
                    <span className={$.totalCosts}>{totalPrice * occupancy},-</span>
                </p>
            )}
            {/* {step !== 4 && (
                <ActionCode small />
            )} */}
                <p className={$.secure}><img src="/images/icon-lock.svg" alt="" />{t('progresscard_booksafe')}</p>
                <div className={$.payments}>
                    <PayIcons />
                </div>
            </React.Fragment>
        );
        /* <p className={$.coupon}>{t('progresscard_couponcode')}</p> */
    }
}

export default withTranslation()(TravelDetailList);
