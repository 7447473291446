import * as React from 'react';
import { FaqItem } from '..';
import $ from './Faq.scss';

interface Props {
    title: string;
    faqItems: {[key: string]: string}[];
}

const Faq = (props: Props) => {
    const { title, faqItems } = props;
    const [itemOpen, setItemOpen] = React.useState('');

    const changeActiveItem = (question) => {
        setItemOpen(question === itemOpen ? '' : question);
    };

    return (
        <section className={$.base}>
            <h3 className={$.title}>{title}</h3>
            <ul className={$.list}>
                {faqItems.map(faqItem => (
                    <FaqItem
                        question={faqItem.question}
                        active={itemOpen}
                        setActive={changeActiveItem}
                        answer={faqItem.answer}
                        key={faqItem.question}
                    />
                ))}
            </ul>
        </section>
    );
};

export default Faq;
