import axios from 'axios';
import { Traveler } from '../models/Traveler';
import * as Cache from '../services/cache';

export interface Body {
    [key: string]:
        | Body
        | Body[]
        | string
        | string[]
        | boolean
        | number
        | number[]
        | Traveler[]
        | undefined;
}

// function toUrlEncoded(body: Body) {
//     return Object.keys(body)
//         .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(body[k].toString())}`)
//         .join('&');
// }

interface FetchUrlParams {
    endpoint: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
    body?: Body;
    userToken?: string;
    getSecret?: boolean;
    secret?: string;
    getLocale?: boolean;
}

export async function fetchUrl<Entity>({
    endpoint,
    method = 'GET',
    body,
    getSecret,
    secret,
    getLocale,
}: FetchUrlParams): Promise<Entity> {
    const params = {
        method,
        data: body,
        headers: (() => {
            const headers: {[key: string]: string} = {};
            headers.Authorization = `Bearer ${window.API_KEY}`;
            if (!getLocale) headers['Accept-Language'] = Cache.getConfig().locale;
            if (secret) headers['X-TW-Order-Secret'] = secret;
            return headers;
        })(),
    };
    const url = `${window.API_URL}${endpoint}`;
    const response = await axios(url, params);

    if (getSecret) {
        return {
            ...response.data.data,
            secret: response.headers['x-tw-order-secret'],
        };
    }
    return response.data.data as Entity;
}
