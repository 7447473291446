import * as React from 'react';
import classnames from 'classnames';
import { withTranslation, WithTranslation } from 'react-i18next';
import santize from 'sanitize-html';
import $ from './HotelItem.scss';
import { IconLabelType } from '../IconLabel/IconLabel';
import { Accommodation } from '../../../core/models/Accommodation';
import { Stars, IconLabel, Price } from '..';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends WithTranslation {
    accommodation: Accommodation;
    selected: boolean;
    openDetail: (accommodation: Accommodation) => void;
    breakfast: boolean;
    onChange: (accommodation: Accommodation) => void;
    onClickFunction?: (accommodation: Accommodation) => void;
    large?: boolean;
    wide?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class HotelItem extends React.Component<Props> {
    public constructor(props) {
        super(props);
        this.handleDetails = this.handleDetails.bind(this);
    }

    public handleDetails(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const { accommodation, openDetail } = this.props;
        event.stopPropagation();
        openDetail(accommodation);
    }

    public render() {
        const { accommodation, breakfast, selected, t, onChange, onClickFunction, large, wide } = this.props;
        const onClick = () => {
            onChange(accommodation);
            if (onClickFunction) onClickFunction(accommodation);
        };

        const image = accommodation.image || '/images/no-hotel-image.jpg';
        let price = '';
        const containerStyles = classnames([
            $.container,
            selected && $.selected,
            large && $.containerLarge,
        ]);
        const imgStyles = classnames([
            $.image,
            large && $.imageLarge,
            wide && $.imageWide,
        ]);
        const imgCropStyles = classnames([
            $.imageCrop,
            wide && $.imageCropWide,
        ]);
        const detailsStyles = classnames([
            $.details,
            large && $.detailsLarge,
            wide && $.detailsWide,
        ]);
        const titleWideStyles = classnames([
            $.title,
            !wide && $.titleHide,
        ]);
        const titleNotWideStyles = classnames([
            $.title,
            wide && $.titleHide,
        ]);
        const detailButtonStyles = classnames([
            $.detailButton,
            (wide || large) && $.detailButtonWide,
            large && $.detailButtonLarge,
        ]);
        const contentStyles = classnames([
            $.content,
            large && $.contentLarge,
        ]);

        if (accommodation.availability != null) {
            price = breakfast
                ? `${accommodation.availability.cheapestBreakfast.supplementPP}`
                : `${accommodation.availability.cheapest.supplementPP}`;
        }

        return (
            <div className={containerStyles} onClick={onClick} onKeyUp={() => onChange(accommodation)} role="button" tabIndex={0}>
                <h3 className={titleNotWideStyles}>{accommodation.name}</h3>
                <Price price={price} green={price === '0'} />
                {(accommodation.stars !== '0' && accommodation.stars !== 0) && (
                    <div className={classnames([$.stars, wide && $.starsHide])}>
                        <Stars stars={accommodation.stars} />
                    </div>
                )}
                <div className={contentStyles}>
                    <div className={imgCropStyles}>
                        <img src={image} alt={accommodation.name} className={imgStyles} />
                    </div>
                    <div className={detailsStyles}>
                        <h3 className={titleWideStyles}>{accommodation.name}</h3>
                        {(accommodation.stars !== '0' && accommodation.stars !== 0) && (
                            <div className={classnames([wide && $.starsBlock, !wide && $.starsHide])}>
                                <Stars stars={accommodation.stars} />
                            </div>
                        )}
                        <div className={classnames((wide || large) && $.distanceWrap)}>
                            <p className={$.distance}>{accommodation.distanceToCityCenter} {t('hotel_tocentre')}</p>
                            <span className={classnames((wide || large) && $.comma, (!wide && !large) && $.commaHide)}>,</span>
                            <p className={$.distance}>{accommodation.distanceToVenue} {t('hotel_tostadium')}</p>
                        </div>
                        <button
                            // it must render an html entity, so set inner html. Santize just to be sure it wont break anything
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: santize(t('hotel_details')) }}
                            className={detailButtonStyles}
                            type="button"
                            onClick={this.handleDetails}
                            onMouseDown={e => e.stopPropagation()}
                        />
                    </div>
                </div>
                <ul className={classnames([$.list, wide && $.listWide])}>
                    {breakfast && (
                        <li>
                            <IconLabel type={IconLabelType.Breakfast}>{t('hotel_includesbreakfast')}</IconLabel>
                        </li>
                    )}
                    {accommodation.recommended && (
                        <li>
                            <IconLabel type={IconLabelType.OrangeCheckmark}>
                                {t('hotel_recommended')}
                            </IconLabel>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default withTranslation()(HotelItem);
