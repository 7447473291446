/* eslint-disable max-len */
import * as React from 'react';
import moment from 'moment-timezone';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import $ from './Page.scss';
import {
    StepIndicator,
    ProgressCard,
    WhyEquipo,
    Faq,
    Footer,
    ProgressCardSmall,
    TravelDetail,
    SupportBlock,
    HotelDetail,
    EventImageTitle,
} from '~source/view/components';
import { EventDetail } from '~source/core/models/EventDetail';
import { Availability } from '~source/core/models/Availability';
import { Accommodation } from '~source/core/models/Accommodation';
import * as Cache from '~source/core/services/cache';

interface Props extends WithTranslation {
    availability?: Availability;
    children: React.ReactNode;
    eventDetail?: EventDetail;
    mustHideTravelDetails?: boolean;
    step: number;
    showEventImageAndTitle?: boolean;
    showPricing?: boolean;
}

interface State {
    travelDetailOpen: boolean;
    openAccommodation?: Accommodation;
}

class Page extends React.Component<Props & RouteComponentProps, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            travelDetailOpen: false,
            openAccommodation: undefined,
        };
        this.toggleDetails = this.toggleDetails.bind(this);
        this.toggleHotelDetail = this.toggleHotelDetail.bind(this);
    }

    public componentDidMount() {
        if (Cache.getAvailability()) {
            this.setSessionEnd();
        }

        window.addEventListener('focus', this.setSessionEnd);
        window.scrollTo(0, 0);
    }

    public componentWillUnmount() {
        window.removeEventListener('focus', this.setSessionEnd);
    }

    private setSessionEnd = () => {
        const availability = Cache.getAvailability();

        if (!availability) return;

        const { expiry } = availability;
        const { history } = this.props;

        if (moment().unix() >= expiry) {
            history.push('/session-end');
        }
    };

    public toggleHotelDetail = (accommodation?: Accommodation) => {
        this.setState({
            openAccommodation: accommodation,
            travelDetailOpen: false,
        });
    };

    public toggleDetails() {
        const { travelDetailOpen } = this.state;
        this.setState({ travelDetailOpen: !travelDetailOpen });
    }

    public render() {
        const { t, children, step, eventDetail, mustHideTravelDetails, showEventImageAndTitle, showPricing } = this.props;
        const { travelDetailOpen, openAccommodation } = this.state;

        const pageData = {
            stepIndicator: {
                logo: {
                    url: '/images/logo.png',
                    title: 'Equipo logo',
                },
                phoneNumber: t('support_phone_number'),
                whatsappNumber: t('support_whatsapp_number'),
                active: 1,
            },
            help: {
                title: t('support_help_title'),
                text: t('support_help_text'),
                image: {
                    url: '/images/support-petra.jpg',
                    title: t('support_help_image_title'),
                },
                icon: {
                    url: '/images/icon-whatsapp.svg',
                },
                phoneNumber: t('support_phone_number'),
                whatsappNumber: t('support_whatsapp_number'),
                ctaLabel: t('support_help_cta_label'),
                online: true,
            },
            whyEquipo: {
                title: t('why_equipo_title'),
                values: [
                    { title: t('why_equipo_1_title'), popover: t('why_equipo_1_popover') },
                    { title: t('why_equipo_2_title'), popover: t('why_equipo_2_popover') },
                    { title: t('why_equipo_3_title'), popover: t('why_equipo_3_popover') },
                ],
                icon: {
                    url: '/images/temp/icon-checkmark.svg',
                },
            },
            faq: {
                title: t('faq_title'),
                faqItems: [
                    {
                        question: t('faq_1_question'),
                        answer: t('faq_1_answer'),
                    },
                    {
                        question: t('faq_2_question'),
                        answer: t('faq_2_answer'),
                    },
                    {
                        question: t('faq_3_question'),
                        answer: t('faq_3_answer'),
                    },
                ],
            },
        };

        pageData.stepIndicator.active = step;

        return (
            <>
                <div className={$.main}>
                    <StepIndicator
                        {...pageData.stepIndicator}
                        help={pageData.help}
                        faq={pageData.faq}
                        whyEquipo={pageData.whyEquipo}
                        eventId={eventDetail && eventDetail.id}
                    />
                    <div className={$.orderProgress}>
                        <ProgressCardSmall {...this.props} toggleDetail={this.toggleDetails} showPricing={showPricing} />
                    </div>
                    <div className={$.content}>
                        {showEventImageAndTitle && eventDetail && (
                            <EventImageTitle title={eventDetail.title} mobileOnly />
                        )}
                        <div className={$.left}>{children}</div>
                        {!mustHideTravelDetails && (
                            <div className={$.right}>
                                <ProgressCard
                                    {...this.props}
                                    travelDetailOpen={travelDetailOpen}
                                    toggleDetail={this.toggleDetails}
                                    eventDetail={eventDetail}
                                    openHotelDetail={this.toggleHotelDetail}
                                    showPricing={showPricing === undefined ? true : showPricing}
                                    step={step}
                                />
                                <div className={$.dividerLeft}>
                                    <WhyEquipo
                                        title={pageData.whyEquipo.title}
                                        values={pageData.whyEquipo.values}
                                        icon={pageData.whyEquipo.icon}
                                    />
                                    {pageData.faq && <Faq {...pageData.faq} />}
                                    {pageData.help && <SupportBlock {...pageData.help} />}
                                </div>
                            </div>
                        )}
                    </div>
                    <Footer />
                </div>
                {travelDetailOpen && eventDetail && (
                    <TravelDetail
                        eventDetail={eventDetail}
                        onClick={this.toggleDetails}
                        openHotelDetail={this.toggleHotelDetail}
                        step={step}
                        showPricing={showPricing === undefined ? true : showPricing}
                    />
                )}
                <HotelDetail
                    accommodation={openAccommodation}
                    eventDetail={eventDetail}
                    onClick={this.toggleHotelDetail}
                />
            </>
        );
    }
}

export default withRouter(withTranslation()(Page));
