import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import $ from './ProgressCardSmall.scss';
import { EventDetail } from '~source/core/models/EventDetail';
import * as Cache from '../../../core/services/cache';
import * as Calculations from '~source/core/services/calculations';

interface Props extends WithTranslation {
    eventDetail?: EventDetail;
    toggleDetail: () => void;
    showPricing: boolean;
}

const ProgressCardSmall = (props: Props) => {
    const { t, eventDetail, toggleDetail, showPricing } = props;
    const { startDate, endDate } = Cache.getInOutDateFormats();

    if (eventDetail == null) {
        return <React.Fragment />;
    }

    const { adults, children } = Cache.getOccupancy();
    const availability = Cache.getAvailability();
    const ticket = Cache.getTicket();
    const accommodation = Cache.getAccommodation();

    const occupancy = adults.length + children.length;

    const { totalPrice } = Calculations.getPrice(accommodation, availability, ticket, adults.length, children.length);

    return (
        <section className={$.progressCard}>
            <div
                className={$.headerImage}
                style={{ backgroundImage: "url('/images/temp/coupon-placeholder.jpg')" }}
            />
            <div className={$.eventInfo}>
                <p>
                    {eventDetail.name}
                    {/* Disable for now, data does not exist in the api yet */}
                    {/* <span className={$.sellPoint}>{t('progresscard_label')}</span> */}
                </p>
                {startDate && endDate && (
                    <p>
                        {startDate.format('D MMM')} - {endDate.format('D MMM YYYY')}
                    </p>
                )}
            </div>
            <div className={$.content}>
                {showPricing && (
                    <p className={$.totalCosts}>{totalPrice * occupancy},-</p>
                )}
                <button className={$.button} type="button" onClick={toggleDetail}>
                    <span className={$.blue}>{t('progresscard_traveldetails')}</span>
                    <img src="/images/icon-dropdown.svg" alt="dropdown" className={$.dropdownIcon} />
                </button>
            </div>
        </section>
    );
};

export default withTranslation()(ProgressCardSmall);
