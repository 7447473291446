/* eslint-disable lines-between-class-members */
export interface ConfigEntity {
    currency: string;
    load_time_days: number;
    load_time_percentage: number;
    locale: string;
    payment: {
        gateways: string[],
        gateway_issuers: {
            CREDITCARD: string[],
            IDEAL: string[],
        }
    }
}

export class Config {
    public readonly currency: string;
    public readonly loadTimeDays: number;
    public readonly locale: string;
    public readonly payment: {
        gateways: string[],
        gatewayIssuers: {
            creditCard: string[],
            ideal: string[],
        }
    }

    public constructor(config: ConfigEntity) {
        this.currency = config.currency;
        this.loadTimeDays = config.load_time_days;
        this.locale = config.locale;
        this.payment = {
            gateways: config.payment.gateways,
            gatewayIssuers: {
                creditCard: config.payment.gateway_issuers.CREDITCARD,
                ideal: config.payment.gateway_issuers.IDEAL,
            }
        };
    }
}
